<template>
  <div class="h-full flex flex-col bg-white overflow-hidden rounded-lg shadow-sm border border-gray-200">
    <!-- Yükleme Durumu -->
    <div v-if="loading" class="flex-grow flex items-center justify-center py-12 min-h-[calc(100vh-200px)]">
      <div class="flex flex-col items-center justify-center">
        <VLoading show-loading-text :loading-text="t('common.loading')"/>
      </div>
    </div>

    <!-- İçerik Bulunamadı Durumu -->
    <div v-else-if="!content" class="flex-grow flex items-center justify-center py-12">
      <EmptyState icon="error_outline" :message="t('socialMedia.components.postInspector.noContent')"/>
    </div>

    <template v-else>
      <!-- Platform ve Durum Bilgisi -->
      <PostInfo :platformName="platformName" :date="content.publishedDate || content.addDate" :isPublished="content.isPublished"/>

      <!-- Ana İçerik Alanı -->
      <div class="flex-grow overflow-y-auto">
        <!-- İçerik Bölümü -->
        <PostContent :message="content.message" :hashtags="parsedHashtags" :link="content.link" :linkPreview="linkPreview" :isImageLink="isImageLink" @visit-link="openExternalLink"/>

        <!-- İstatistikler -->
        <PostStats :likes="content.postLikes" :comments="commentCount" :shares="content.shares || 0"/>

        <!-- Yorumlar Bölümü -->
        <PostComments :postId="postId" @comments-loaded="updateCommentCount"/>
      </div>

      <!-- Alt Çubuk -->
      <PostFooter :date="content.publishedDate || content.addDate" :isPublished="content.isPublished" :isPublishing="isContentPublishing" @publish="publishPost" @analytics="navigateToAnalytics"/>
    </template>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'

// Bileşenler
import PostInfo from './components/PostInfo.vue'
import PostContent from './components/PostContent.vue'
import PostStats from './components/PostStats.vue'
import PostComments from './components/PostComments.vue'
import PostFooter from './components/PostFooter.vue'
import VLoading from '@/components/loading/VLoading.vue'
import EmptyState from './components/EmptyState.vue'

const { t } = useI18n()
const router = useRouter()
const assetStore = useAssetStore()

const props = defineProps({
  postId: {
    type: [String, Number],
    required: true,
  },
})

const emit = defineEmits(['update:isPublished'])

// Durum değişkenleri
const content = ref(null)
const loading = ref(true)
const linkPreview = ref({
  title: '',
  description: '',
  image: '',
})
const isContentPublishing = ref(false)
const commentCount = ref(0)

// Platform adı
const platformName = computed(() => {
  if (content.value && content.value.platforms && content.value.platforms.length > 0 && content.value.platforms[0].platformName) {
    return content.value.platforms[0].platformName
  }
  return t('socialMedia.components.postInspector.socialPlatform')
})

// Hashtag'leri ayrıştır
const parsedHashtags = computed(() => {
  if (!content.value?.keywords) return []
  try {
    if (Array.isArray(content.value.hashtags)) {
      return content.value.hashtags
    }
    const keywords = JSON.parse(content.value.keywords)
    return keywords.map(keyword => keyword.startsWith('#') ? keyword : `#${keyword}`)
  } catch (e) {
    console.error('Error parsing hashtags', e)
    return []
  }
})

// Görsel türü bağlantı mı?
const isImageLink = computed(() => {
  if (!content.value?.link) return false
  const link = content.value.link.toLowerCase()
  const extension = link.split('.').pop()
  return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension) ||
      link.includes('/image/') || link.includes('/images/')
})

// Yorum sayacını güncelle
function updateCommentCount(count) {
  commentCount.value = count
}

// Gönderi yayınla
async function publishPost() {
  isContentPublishing.value = true
  try {
    await axios.post(socialMedia.publishPost, {
      rowId: props.postId,
      assetId: assetStore.assetId,
      platforms: content.value.platforms,
    })
    content.value.isPublished = true
    emit('update:isPublished', true)
  } catch (error) {
    console.error(t('socialMedia.components.postInspector.publishError'), error)
  } finally {
    isContentPublishing.value = false
  }
}

// Analitiğe git
function navigateToAnalytics() {
  if (!content.value?.id) return
  router.push({
    name: 'social-media-analytics',
    params: { id: content.value.id },
  })
}

// Dış bağlantıyı aç
function openExternalLink(url) {
  window.open(url, '_blank')
}

// İçeriği getir
async function fetchContent() {
  try {
    const response = await axios.get(socialMedia.getPost, { params: { postId: props.postId } })
    content.value = response.data.data[0]

    // Platform bilgisini işle
    if (content.value && typeof content.value.platforms === 'string') {
      try {
        content.value.platforms = JSON.parse(content.value.platforms)
      } catch (e) {
        console.error('Error parsing platforms', e)
        content.value.platforms = []
      }
    }

    content.value.isPublished = Boolean(content.value.isPublished || content.value.isPublished === 1)
    emit('update:isPublished', content.value.isPublished)

    if (content.value?.link && !isImageLink.value) {
      await fetchLinkPreview(content.value.link)
    }
  } catch (error) {
    console.error(t('socialMedia.components.postInspector.loadError'), error)
  } finally {
    loading.value = false
  }
}

// Bağlantı önizlemesi getir
async function fetchLinkPreview(url) {
  if (isImageLink.value) return

  try {
    const response = await axios.get(`${socialMedia.getLinkPreview}?url=${encodeURIComponent(url)}`)
    if (response.data) {
      linkPreview.value = {
        title: response.data.title || extractDomain(url),
        description: response.data.description || '',
        image: response.data.image || '',
      }
    }
  } catch (error) {
    console.error('Error fetching link preview', error)
    linkPreview.value = {
      title: extractDomain(url),
      description: '',
      image: '',
    }
  }
}

function extractDomain(url) {
  if (!url) return ''
  try {
    return new URL(url).hostname.replace('www.', '')
  } catch {
    return url
  }
}

// Bileşen bağlandığında içeriği getir
onMounted(fetchContent)
</script>