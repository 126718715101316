export function validateAndNormalizePriority(priority) {
  const originalValue = priority

  // 0 veya boş değer kontrolü
  if (priority === 0 || priority === undefined || priority === null || priority === '') {
    return {
      normalized: null,
      original: originalValue,
      wasNormalized: false,
      reason: 'empty_value',
    }
  }

  let numPriority = Number(priority)

  // Sayı değil veya negatif kontrolü
  if (isNaN(numPriority) || numPriority < 0) {
    return {
      normalized: null,
      original: originalValue,
      wasNormalized: true,
      reason: isNaN(numPriority) ? 'not_a_number' : 'negative_value',
    }
  }

  // Ondalıklı sayı kontrolü
  const roundedPriority = Math.round(numPriority)
  if (roundedPriority !== numPriority) {
    return {
      normalized: roundedPriority,
      original: originalValue,
      wasNormalized: true,
      reason: 'decimal_rounded',
    }
  }

  // Maksimum değer kontrolü
  if (numPriority > 10) {
    return {
      normalized: 10,
      original: originalValue,
      wasNormalized: true,
      reason: 'exceeds_max',
    }
  }

  // Değer değişmediyse
  return {
    normalized: numPriority,
    original: originalValue,
    wasNormalized: false,
  }
}