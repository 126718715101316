<template>
  <div class="space-y-5">
    <!-- Üst Gezinme Çubuğu -->
    <div class="flex items-center justify-between mb-4">
      <button @click="$emit('select-mode', 'welcome')" class="flex items-center gap-1 text-sm text-primary hover:text-primary-700 transition-colors">
        <span class="material-symbols-outlined !text-[18px]">arrow_back</span> {{ t('entity.components.newContent.components.newContentModal.backToWelcome') }}
      </button>
    </div>

    <div class="mb-6">
      <h3 class="text-xl font-semibold text-gray-800 mb-2">{{ t('entity.components.newContent.components.newContentModal.trendListSection.title') }}</h3>
      <p class="text-sm text-gray-600">{{ t('entity.components.newContent.components.newContentModal.trendListSection.description') }}</p>
    </div>

    <!-- Yenilenmiş Filter Buttons - Daha kullanıcı dostu -->
    <div class="bg-white rounded-lg shadow-sm mb-5">
      <div class="flex border-b border-gray-100">
        <button v-for="item in trendFilterItems" :key="item.id" @click="handleFilterItemClick(item.id)" class="flex-1 py-3 px-4 text-center font-medium transition-colors relative" :class="selectedTrendType === item.id ?
                 'text-primary' :
                 'text-gray-500 hover:text-gray-800'">
          {{ item.name }} <span v-if="selectedTrendType === item.id" class="absolute bottom-0 left-0 w-full h-0.5 bg-primary"></span>
        </button>
      </div>
    </div>

    <!-- Trend Listesi - Yenilenmiş kart tasarımı -->
    <div class="relative">
      <!-- Loading State -->
      <div v-if="isLoading" class="min-h-[400px] flex items-center justify-center">
        <VLoading size="lg"/>
      </div>

      <!-- Empty State -->
      <div v-else-if="trends.length === 0" class="min-h-[400px] flex flex-col items-center justify-center py-12 bg-gray-50 rounded-lg">
        <span class="material-symbols-outlined !text-[48px] text-gray-300 mb-4">trending_flat</span>
        <p class="text-gray-500 text-center">{{ t('entity.components.newContent.components.newContentModal.trendListSection.noTrends') }}</p>
      </div>

      <!-- Trends List -->
      <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[450px] min-h-[400px] overflow-y-auto pr-2 py-2 thin-scrollbar">
        <div v-for="trend in trends" :key="trend.name" class="bg-white border border-gray-100 rounded-lg p-5 hover:shadow-md hover:border-primary/30 transition-all cursor-pointer transform hover:-translate-y-1" @click="$emit('select-trend', trend)">
          <div class="flex flex-col h-full">
            <!-- Başlık ve Hacim -->
            <div class="flex justify-between items-start mb-4">
              <h4 class="font-semibold text-gray-800 flex items-center">
                <span class="inline-block w-2 h-2 bg-green-500 rounded-full mr-2"></span> {{ trend.name }} </h4>
              <span class="bg-primary/10 text-primary px-3 py-1 rounded-full text-sm font-medium">{{ formatVolumeDisplay(trend.volume) }}</span>
            </div>

            <!-- Bilgiler -->
            <div class="flex items-center gap-4 mt-auto pt-3 border-t border-gray-100">
              <div class="flex items-center gap-1">
                <span class="material-symbols-outlined text-green-600 !text-[16px]">trending_up</span> <span class="text-sm text-green-700 font-medium">+{{ trend.increasePercentage }}%</span>
              </div>

              <div class="flex items-center gap-1 text-gray-500 text-sm">
                <span class="material-symbols-outlined !text-[16px]">schedule</span> {{ formatTimeDisplay(trend.startTimestamp) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { dateHelper } from '@/utils/dateHelper'
import VLoading from '@/components/loading/VLoading.vue'

const props = defineProps({
  trends: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  trendFilterItems: {
    type: Array,
    default: () => [],
  },
  selectedTrendType: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['select-trend', 'trend-filter-change', 'update:selectedTrendType', 'select-mode'])

const { t } = useI18n()

const formatTimeDisplay = (timestamp) => {
  if (!timestamp) return '-'

  const start = dateHelper.parse(timestamp * 1000)
  const now = dateHelper.now()
  const diffInHours = dateHelper.diff(now, start, 'hour')

  if (diffInHours < 1) {
    const diffInMinutes = dateHelper.diff(now, start, 'minute')
    return t('dashboard.components.trendKeywords.minutesAgo', {
      minutes: diffInMinutes,
    })
  }
  if (diffInHours < 24) {
    return t('dashboard.components.trendKeywords.hoursAgo', {
      hours: diffInHours,
    })
  }

  const diffInDays = Math.floor(diffInHours / 24)
  return t('dashboard.components.trendKeywords.daysAgo', { days: diffInDays })
}

const formatVolumeDisplay = (volume) => {
  if (!volume) return '-'
  if (volume >= 1000000) return `${(volume / 1000000).toFixed(1)}M+`
  if (volume >= 1000) return `${(volume / 1000).toFixed(1)}K+`
  if (volume >= 500) return `500+`
  return `${volume}+`
}

const handleFilterItemClick = (filterType) => {
  emit('update:selectedTrendType', filterType)
  emit('trend-filter-change')
}
</script>