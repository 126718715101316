<template>
  <div class="flex-grow flex flex-col overflow-hidden p-6 relative">
    <!-- Üst Kısım - Ekleme ve Dışa Aktarma Butonları -->
    <div class="flex items-center justify-between mb-6">
      <h3 class="text-lg font-medium text-gray-700">
        {{ t('settings.components.configuration.components.categoryModal.list.customCategories') }}
      </h3>
      <div class="flex space-x-2">
        <!-- Ekleme Butonu -->
        <VButton 
          @click="$emit('add-new')" 
          :button-text="t('settings.components.configuration.components.categoryModal.list.addNewButton')" 
          icon="add_circle" 
          :show-icon="true" 
          class="hover:shadow-md transition-all duration-300 mr-2" 
          :is-disabled="isLoading"
        />
        <!-- Prompt Yönetim Butonu -->
        <VButton 
          @click="$emit('open-prompt-manager')" 
          button-text="Prompt Yöneticisi" 
          icon="smart_toy" 
          :show-icon="true" 
          class="hover:shadow-md transition-all duration-300" 
          :is-disabled="isLoading"
          variant="secondary"
        />
      </div>
    </div>

    <!-- Kategori Listesi -->
    <div class="flex-grow overflow-auto custom-scrollbar relative">
      <!-- Loading State -->
      <div v-if="isLoading" class="absolute inset-0 bg-white/80 backdrop-blur-sm flex justify-center items-center">
        <div class="flex flex-col items-center gap-2">
          <span class="animate-spin material-symbols-outlined text-3xl text-primary">progress_activity</span>
          <span class="text-sm text-gray-600">{{ t('settings.components.configuration.components.categoryModal.common.loading') }}</span>
        </div>
      </div>

      <!-- Boş Durum -->
      <div v-else-if="!categories.length" class="h-full flex flex-col items-center justify-center text-center p-8">
        <span class="material-symbols-outlined text-4xl text-gray-400 mb-2">category</span>
        <p class="text-gray-600 mb-1">{{ t('settings.components.configuration.components.categoryModal.list.emptyState.title') }}</p>
        <p class="text-sm text-gray-500">{{ t('settings.components.configuration.components.categoryModal.list.emptyState.description') }}</p>
      </div>

      <!-- Kategoriler Listesi -->
      <div v-else class="space-y-2">
        <CategoryItem v-for="category in categories" :key="category.id" :category="category" :sub-categories="subCategories[category.id]" :is-expanded="expandedCategories.includes(category.id)" :is-loading-sub="loadingSubCategories === category.id" :is-deleting="isDeleting && selectedCategory?.id === category.id" :expanded-categories="expandedCategories" :loading-sub-categories="loadingSubCategories" :sub-categories-map="subCategories" @toggle="handleToggle" @delete="handleDelete" class="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300"/>
      </div>
    </div>

    <!-- Silme Onay Modal -->
    <div v-if="showDeleteConfirmation" class="absolute inset-0 bg-gray-900/20 backdrop-blur-[2px] flex items-center justify-center z-10">
      <div class="bg-white rounded-xl shadow-xl p-6 max-w-md w-full mx-4">
        <div class="flex items-center gap-3 mb-4">
          <span class="material-symbols-outlined text-2xl text-red-500">warning</span>
          <h3 class="text-lg font-semibold text-gray-800">
            {{ t('settings.components.configuration.components.categoryModal.list.deleteModal.title') }}
          </h3>
        </div>

        <p class="text-gray-600 mb-2">
          {{ t('settings.components.configuration.components.categoryModal.list.deleteModal.message', { name: selectedCategory?.name }) }}
        </p>
        <p class="text-sm text-gray-500 mb-6">
          {{ t('settings.components.configuration.components.categoryModal.list.deleteModal.warning') }}
        </p>

        <div class="flex items-center justify-end gap-3">
          <button @click="cancelDelete" class="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors">
            {{ t('settings.components.configuration.components.categoryModal.list.deleteModal.cancel') }}
          </button>
          <button @click="confirmDelete" :disabled="isDeleting" class="px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 disabled:opacity-50 transition-all duration-300 flex items-center gap-2">
            <span class="material-symbols-outlined !text-base animate-spin" v-if="isDeleting">progress_activity</span>
            {{ isDeleting ? t('settings.components.configuration.components.categoryModal.list.deleteModal.deleting') : t('settings.components.configuration.components.categoryModal.list.deleteModal.confirm') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CategoryItem from './categoryItem.vue'
import VButton from '@/components/base/buttons/VButton.vue'
// Modal taşındı: promptManagement klasörüne

const { t } = useI18n()

const props = defineProps({
  categories: {
    type: Array,
    required: true,
  },
  subCategories: {
    type: Object,
    required: true,
  },
  isLoading: Boolean,
  expandedCategories: {
    type: Array,
    required: true,
  },
  loadingSubCategories: [String, Number, null],
})

const emit = defineEmits(['add-new', 'toggle-category', 'category-deleted', 'open-prompt-manager'])

const assetStore = useAssetStore()

// Delete related states
const showDeleteConfirmation = ref(false)
const selectedCategory = ref(null)
const isDeleting = ref(false)

// Prompt Manager
const showPromptModal = ref(false)

const handleToggle = (data) => {
  emit('toggle-category', data)
}

// Delete handlers
const handleDelete = (category) => {
  selectedCategory.value = category
  showDeleteConfirmation.value = true
}

const cancelDelete = () => {
  showDeleteConfirmation.value = false
  selectedCategory.value = null
}

const confirmDelete = async () => {
  if (!selectedCategory.value?.id) {
    responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.deleteCategory'))
    return
  }

  isDeleting.value = true
  try {
    await axios.put(settings.deleteCategory, {
      id: selectedCategory.value.id,
      assetId: assetStore.assetId,
    })

    responseHandlerService.handleSuccess(t('settings.components.configuration.components.categoryModal.common.success.deleted'))
    emit('category-deleted', {
      id: selectedCategory.value.id,
      parentId: selectedCategory.value.custom_parent_id,
      level: selectedCategory.value.level,
    })

    showDeleteConfirmation.value = false
    selectedCategory.value = null
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isDeleting.value = false
  }
}

// Excel export function
// const exportToExcel = async () => {
//   if (!props.categories.length) {
//     responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.noCategoriesToExport'))
//     return
//   }

//   isExporting.value = true
  
//   try {
//     // Prepare data for Excel export
//     const flatData = prepareCategoryDataForExport(props.categories, props.subCategories)
    
//     // API endpoint for Excel export
//     const response = await axios.post(settings.exportCategoriesToExcel, {
//       assetId: assetStore.assetId,
//       categories: flatData
//     }, {
//       responseType: 'blob' // Important for file download
//     })
    
//     // Create a download link for the Excel file
//     const url = window.URL.createObjectURL(new Blob([response.data]))
//     const link = document.createElement('a')
//     link.href = url
//     link.setAttribute('download', `categories-${new Date().toISOString().split('T')[0]}.xlsx`)
//     document.body.appendChild(link)
//     link.click()
//     document.body.removeChild(link)
    
//     responseHandlerService.handleSuccess(t('settings.components.configuration.components.categoryModal.common.success.exported'))
//   } catch (error) {
//     responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.exportFailed'))
//     console.error('Excel export error:', error)
//   } finally {
//     isExporting.value = false
//   }
// }

// // Helper function to flatten category hierarchy for export
// const prepareCategoryDataForExport = (categories, subCategoriesMap) => {
//   const flatData = []
  
//   // Process main categories
//   categories.forEach(category => {
//     flatData.push({
//       id: category.id,
//       name: category.name,
//       level: category.level || 1,
//       parentId: category.custom_parent_id || null,
//       parentName: null
//     })
    
//     // Process subcategories if any
//     const subCategories = subCategoriesMap[category.id] || []
//     subCategories.forEach(subCategory => {
//       flatData.push({
//         id: subCategory.id,
//         name: subCategory.name,
//         level: subCategory.level || 2,
//         parentId: subCategory.custom_parent_id,
//         parentName: category.name
//       })
//     })
//   })
  
//   return flatData
// }
</script>

<style scoped>
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #E2E8F0 #F8FAFC;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #F8FAFC;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #E2E8F0;
  border-radius: 20px;
}
</style>