import { ref } from 'vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

// Temel kopyalama işlemi fonksiyonu
function useCopyBase(timeout = 2000) {
  const { t } = useI18n()
  const copied = ref(false)

  /**
   * Metni panoya kopyala ve toast göster
   * @param {string} text - Panoya kopyalanacak metin
   * @returns {Promise<boolean>} - Kopyalama işleminin başarılı olup olmadığı
   */
  const copy = async (text) => {
    if (!text) return false

    try {
      await navigator.clipboard.writeText(text)
      copied.value = true

      // Toast mesajı göster
      responseHandlerService.handleSuccess(t('socialMedia.components.createPost.general.copied'))

      setTimeout(() => {
        copied.value = false
      }, timeout)

      return true
    } catch (error) {
      console.error('Metin kopyalanırken hata oluştu:', error)
      responseHandlerService.handleError(t('socialMedia.components.createPost.errors.clipboardCopy'))
      return false
    }
  }

  return {
    copied,
    copy,
  }
}

// Format işlemleri için yardımcı fonksiyonlar
function useFormatHelpers() {
  /**
   * Hashtag'leri formatlayıp tek bir metin olarak döndür
   * @param {Array} hashtags - Hashtag dizisi
   * @returns {string} - Formatlanmış hashtag metni
   */
  const formatHashtags = (hashtags) => {
    if (!hashtags || !Array.isArray(hashtags)) return ''
    return hashtags.join(' ')
  }

  /**
   * Tüm içeriği (metin + hashtag + bağlantı) tek bir metin olarak döndür
   * @param {Object} content - İçerik nesnesi
   * @returns {string} - Formatlanmış tam içerik
   */
  const formatAllContent = (content) => {
    if (!content) return ''

    let result = content.message || ''

    // Hashtag'leri ekle
    if (content.hashtags && content.hashtags.length > 0) {
      result += '\n\n' + formatHashtags(content.hashtags)
    }

    // Bağlantıyı ekle
    if (content.link) {
      result += '\n\n' + content.link
    }

    return result
  }

  return {
    formatHashtags,
    formatAllContent,
  }
}

// Kopyalama işlemleri için özel fonksiyonlar
function useCopyOperations(copy, formatHelpers) {
  const { formatHashtags, formatAllContent } = formatHelpers

  /**
   * İçerik metnini kopyala
   * @param {Object} content - İçerik nesnesi
   * @returns {Promise<boolean>} - Kopyalama işleminin başarılı olup olmadığı
   */
  const copyText = async (content) => {
    if (!content || !content.message) return false
    return await copy(content.message)
  }

  /**
   * Hashtag'leri kopyala
   * @param {Object} content - İçerik nesnesi
   * @returns {Promise<boolean>} - Kopyalama işleminin başarılı olup olmadığı
   */
  const copyHashtags = async (content) => {
    if (!content || !content.hashtags) return false
    return await copy(formatHashtags(content.hashtags))
  }

  /**
   * Bağlantıyı kopyala
   * @param {Object} content - İçerik nesnesi
   * @returns {Promise<boolean>} - Kopyalama işleminin başarılı olup olmadığı
   */
  const copyLink = async (content) => {
    if (!content || !content.link) return false
    return await copy(content.link)
  }

  /**
   * Tüm içeriği kopyala
   * @param {Object} content - İçerik nesnesi
   * @returns {Promise<boolean>} - Kopyalama işleminin başarılı olup olmadığı
   */
  const copyAllContent = async (content) => {
    if (!content) return false
    return await copy(formatAllContent(content))
  }

  return {
    copyText,
    copyHashtags,
    copyLink,
    copyAllContent,
  }
}

// Ana useClipboard composable'ını dışa aktarıyoruz
export function useClipboard(timeout = 2000) {
  const { copied, copy } = useCopyBase(timeout)
  const formatHelpers = useFormatHelpers()
  const copyOperations = useCopyOperations(copy, formatHelpers)

  return {
    copied,
    copy,
    ...copyOperations,
    formatHashtags: formatHelpers.formatHashtags,
    formatAllContent: formatHelpers.formatAllContent,
  }
}