import { responseHandlerService } from '@/plugins/responseHandlerService'
import { findKeywordToRemove, updateKeywordsWithRemoved } from '../../utils/keywords/keywordRemoveUtils'

export function useKeywordRemove(props, emit, t) {
  /**
   * Keyword silme işlemini gerçekleştirir
   * @param {Object|string} keyword - Silinecek keyword
   */
  const removeKeyword = (keyword) => {
    try {
      // Keyword adını al
      const keywordName = typeof keyword === 'string' ? keyword : keyword.name

      // Keyword'ü listede bul
      const keywordIndex = findKeywordToRemove(props.keywords, keyword)

      // Keyword bulunamadıysa hata fırlat
      if (keywordIndex === -1) {
        throw new Error(`Keyword not found: ${keywordName}`)
      }

      // Keyword'ü sil ve listeyi güncelle
      const updatedKeywords = updateKeywordsWithRemoved(props.keywords, keywordIndex)

      // Değişiklikleri emit et
      emit('update:keywords', updatedKeywords)

      // Başarı mesajı göster
      showSuccessMessage(keywordName)
    } catch (error) {
      // Hata mesajı göster
      handleRemoveError(error, keyword)
    }
  }

  /**
   * Başarı mesajı gösterir
   * @param {string} keywordName - Silinen keyword adı
   */
  const showSuccessMessage = (keywordName) => {
    responseHandlerService.successHandler(
        t('settings.components.configuration.components.keywordsTable.keywordDeleted',
            { keyword: keywordName },
        ),
    )
  }

  /**
   * Silme hatalarını işler
   * @param {Error} error - Oluşan hata
   * @param {Object|string} keyword - Silinecek keyword
   */
  const handleRemoveError = (error, keyword) => {
    responseHandlerService.errorHandler(
        t(
            'settings.components.configuration.components.keywordsTable.deleteError',
            {
              keyword: typeof keyword === 'string' ? keyword : keyword.name,
            },
        ),
    )
  }

  return {
    removeKeyword,
  }
}