<template>
  <div class="category-tree-item">
    <div :class="[
        'flex items-center p-2 rounded-lg cursor-pointer transition-colors hover:bg-gray-100',
        selectedId === category.id ? 'bg-primary/10 border-l-4 border-primary pl-1' : '',
        hasChildren ? 'font-medium' : ''
      ]" @click="handleSelectCategory(category)">
      <button v-if="hasChildren" class="w-6 h-6 flex items-center justify-center mr-1" @click.stop="toggleExpand">
        <span class="material-symbols-outlined text-gray-500 transition-transform" :class="{ 'rotate-90': isExpanded }">
          chevron_right
        </span>
      </button>
      <span v-else class="w-6 mr-1"></span>

      <span class="material-symbols-outlined text-gray-500 mr-2">
        {{ hasChildren ? (isExpanded ? 'folder_open' : 'folder') : 'description' }}
      </span>

      <div class="flex-grow truncate">{{ category.name }}</div>

      <span v-if="category.is_custom" class="px-2 py-0.5 ml-2 text-xs rounded-full bg-primary/10 text-primary">
        Özel
      </span>
    </div>

    <div v-if="isExpanded && hasChildren" class="pl-4 mt-1 ml-2">
      <CategoryTreeItem v-for="child in category.children" :key="child.id" :category="child" :selected-id="selectedId" @select="handleChildSelect"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

// Props
const props = defineProps({
  category: {
    type: Object,
    required: true,
  },
  selectedId: {
    type: [Number, String],
    default: null,
  },
})

// Emits
const emit = defineEmits(['select'])

// State
const isExpanded = ref(false)

// Computed
const hasChildren = computed(() => {
  return props.category.children && props.category.children.length > 0
})

// Methods
const toggleExpand = () => {
  isExpanded.value = !isExpanded.value
}

const handleSelectCategory = (category) => {
  emit('select', category)
}

const handleChildSelect = (category) => {
  emit('select', category)
}
</script>

<style scoped>
.category-tree-item {
  margin-bottom: 2px;
}
</style>