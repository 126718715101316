<template>
  <div class="bg-white rounded-lg p-6 shadow-sm">
    <div class="text-center mb-8">
      <div class="inline-block bg-primary/10 p-3 rounded-full mb-4 ">
        <span class="material-symbols-outlined text-primary !text-[28px] align-middle">auto_awesome</span>
      </div>
      <h2 class="text-2xl font-bold text-gray-800 mb-2">{{ $t('socialMedia.components.createPost.welcome.title') }}</h2>
      <p class="text-gray-600 max-w-2xl mx-auto">{{ $t('socialMedia.components.createPost.welcome.description') }}</p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-3xl mx-auto">
      <!-- Manual Content Creation Card -->
      <div class="group relative overflow-hidden bg-gradient-to-br from-gray-50 to-white rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md cursor-pointer flex flex-col" @click="$emit('select-mode', 'manual')">
        <div class="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-r from-primary/5 to-transparent transition-opacity"></div>

        <div class="z-10 p-6 flex flex-col h-full">
          <div class="flex items-center mb-4">
            <div class="w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center mr-4 group-hover:scale-110 transition-transform">
              <span class="material-symbols-outlined text-primary !text-xl">edit</span>
            </div>
            <h3 class="text-lg font-bold text-gray-800">{{ $t('socialMedia.components.createPost.welcome.manualCard.title') }}</h3>
          </div>

          <p class="text-gray-600 mb-4 flex-grow">{{ $t('socialMedia.components.createPost.welcome.manualCard.description') }}</p>

          <div class="flex items-center text-primary font-medium group-hover:font-semibold">
            <span>{{ $t('socialMedia.components.createPost.welcome.manualCard.action') }}</span> <span class="material-symbols-outlined ml-2 transition-all duration-300 group-hover:translate-x-1">arrow_forward</span>
          </div>
        </div>
      </div>

      <!-- Trend Content Creation Card -->
      <div class="group relative overflow-hidden bg-gradient-to-br from-gray-50 to-white rounded-xl border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-md cursor-pointer flex flex-col" @click="$emit('select-mode', 'trend')">
        <div class="absolute inset-0 opacity-0 group-hover:opacity-100 bg-gradient-to-r from-accent/5 to-transparent transition-opacity"></div>

        <div class="z-10 p-6 flex flex-col h-full">
          <div class="flex items-center mb-4">
            <div class="w-12 h-12 rounded-full bg-accent/10 flex items-center justify-center mr-4 group-hover:scale-110 transition-transform">
              <span class="material-symbols-outlined text-accent !text-xl">trending_up</span>
            </div>
            <h3 class="text-lg font-bold text-gray-800">{{ $t('socialMedia.components.createPost.welcome.trendCard.title') }}</h3>
          </div>

          <p class="text-gray-600 mb-4 flex-grow">{{ $t('socialMedia.components.createPost.welcome.trendCard.description') }}</p>

          <div class="flex items-center text-accent font-medium group-hover:font-semibold">
            <span>{{ $t('socialMedia.components.createPost.welcome.trendCard.action') }}</span> <span class="material-symbols-outlined ml-2 transition-all duration-300 group-hover:translate-x-1">arrow_forward</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Benefits -->
    <div class="mt-10 grid grid-cols-1 md:grid-cols-3 gap-4 max-w-4xl mx-auto text-center">
      <div class="p-4">
        <div class="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-3">
          <span class="material-symbols-outlined text-green-600">rocket_launch</span>
        </div>
        <h4 class="font-medium text-gray-800 mb-1">{{ $t('socialMedia.components.createPost.welcome.benefits.fast.title') }}</h4>
        <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.welcome.benefits.fast.description') }}</p>
      </div>

      <div class="p-4">
        <div class="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-3">
          <span class="material-symbols-outlined text-blue-600">auto_awesome</span>
        </div>
        <h4 class="font-medium text-gray-800 mb-1">{{ $t('socialMedia.components.createPost.welcome.benefits.ai.title') }}</h4>
        <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.welcome.benefits.ai.description') }}</p>
      </div>

      <div class="p-4">
        <div class="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-3">
          <span class="material-symbols-outlined text-purple-600">trending_up</span>
        </div>
        <h4 class="font-medium text-gray-800 mb-1">{{ $t('socialMedia.components.createPost.welcome.benefits.trends.title') }}</h4>
        <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.welcome.benefits.trends.description') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
defineEmits(['select-mode'])
</script>