<template>
  <div class="flex items-center bg-gray-50 p-3 rounded-lg">
    <div class="flex -space-x-2 mr-3">
      <div v-for="platform in platforms" :key="platform" class="w-8 h-8 rounded-full bg-white border border-gray-200 flex items-center justify-center">
        <img :src="getPlatformIcon(platform)" :alt="getPlatformName(platform)" class="w-5 h-5"/>
      </div>
    </div>
    <div>
      <p class="font-medium text-gray-800">{{ platformsText }}</p>
      <p class="text-xs text-gray-500">{{ formattedDate }}</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(localizedFormat)

const props = defineProps({
  platforms: {
    type: Array,
    required: true,
    default: () => [],
  },
})

const platformsText = computed(() => {
  if (!props.platforms || props.platforms.length === 0) {
    return 'Platforms to be shared'
  }

  const platformNames = props.platforms.map(id => getPlatformName(id))

  if (platformNames.length === 1) {
    return `${platformNames[0]}`
  } else if (platformNames.length === 2) {
    return `${platformNames[0]} & ${platformNames[1]}`
  } else {
    return `${platformNames.length} platforms`
  }
})

const formattedDate = computed(() => {
  const userLocale = navigator.language || 'tr-TR'
  return dayjs().locale(userLocale).format('LL')
})

const getPlatformIcon = (platformId) => {
  const platformIcons = {
    1: require('@/assets/icons/instagram.svg'),
    2: require('@/assets/icons/facebook.svg'),
    3: require('@/assets/icons/twitterx.svg'),
  }
  return platformIcons[platformId] || ''
}

const getPlatformName = (platformId) => {
  const platformNames = {
    1: 'Instagram',
    2: 'Facebook',
    3: 'TwitterX',
  }
  return platformNames[platformId] || ''
}
</script>