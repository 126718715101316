<template>
  <div class="relative">
    <div class="p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
      <!-- Copy Options -->
      <div class="absolute top-2 right-2 z-10 flex gap-1">
        <button @click="copyText" class="p-1.5 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors flex items-center justify-center text-gray-600" :title="t('socialMedia.components.createPost.contentPreview.copy.text')">
          <span class="material-symbols-outlined !text-[16px]">content_copy</span>
        </button>
      </div>

      <p class="whitespace-pre-line text-gray-800 mb-3">{{ message }}</p>

      <!-- Hashtags with Copy Button -->
      <div v-if="hashtags && hashtags.length > 0" class="flex flex-wrap gap-1 mt-3 relative">
        <!-- Copy Hashtags Button -->
        <div class="absolute top-0 right-0">
          <button @click="copyHashtags" class="p-1.5 bg-gray-100 hover:bg-gray-200 rounded-full transition-colors flex items-center justify-center text-gray-600" :title="t('socialMedia.components.createPost.contentPreview.copy.hashtags')">
            <span class="material-symbols-outlined !text-[16px]">content_copy</span>
          </button>
        </div>

        <span v-for="(hashtag, index) in hashtags" :key="index" class="inline-block text-primary text-sm hover:underline cursor-pointer">
          {{ hashtag }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  hashtags: {
    type: Array,
    default: () => [],
  },
  content: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['copy-text', 'copy-hashtags'])

const copyText = () => {
  emit('copy-text')
}

const copyHashtags = () => {
  emit('copy-hashtags')
}
</script>