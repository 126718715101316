<template>
  <div class="space-y-6">
    <!-- Tek bir gezinme çubuğu -->
    <div class="flex items-center justify-between bg-gray-50 p-3 rounded-lg mb-6">
      <button @click="$emit('back-to-trends')" class="flex items-center gap-1 text-sm text-primary">
        <span class="material-symbols-outlined !text-[18px]">arrow_back</span> {{ t('entity.components.newContent.components.newContentModal.trendContentForm.backToTrends') }}
      </button>

      <button @click="$emit('select-mode', 'welcome')" class="flex items-center gap-1 text-sm text-gray-500 hover:text-primary transition-colors">
        <span class="material-symbols-outlined !text-[18px]">home</span> <span class="hidden sm:inline">{{ t('entity.components.newContent.components.newContentModal.backToWelcome') }}</span>
      </button>
    </div>

    <!-- Trend başlığı ve temel bilgiler -->
    <div class="bg-white rounded-lg shadow-sm border border-gray-100 p-5">
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div class="flex-grow">
          <h2 class="text-xl font-bold text-gray-800">{{ trend?.name }}</h2>
          <div class="flex items-center gap-2 mt-2">
            <span class="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
              +{{ trend.increasePercentage }}%
            </span> <span class="text-gray-500 text-sm">
              {{ formatTimeDisplay(trend.startTimestamp) }}
            </span>
          </div>
        </div>

        <div class="shrink-0">
          <div class="flex items-center justify-center bg-primary/10 px-4 py-2 rounded-lg">
            <span class="mr-2 text-primary font-semibold">{{ formatVolumeDisplay(trend.volume) }}</span>
            <span class="text-xs text-primary/70">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.searchVolume') }}</span>
          </div>
        </div>
      </div>

      <!-- News URL bağlantısı - eğer varsa -->
      <div v-if="trend?.newsUrl" class="mt-4 p-3 bg-gray-50 rounded-lg border border-gray-100">
        <div class="flex items-center gap-2">
          <span class="material-symbols-outlined text-gray-500">link</span>
          <a :href="trend.newsUrl" target="_blank" rel="noopener noreferrer" class="text-primary hover:text-primary/80 hover:underline line-clamp-1"> {{ extractDomain(trend.newsUrl) }} </a>
        </div>
      </div>
    </div>

    <!-- Ana içerik alanı - Grid düzeni -->
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <!-- Sol Taraf - Trend Bilgileri ve Görsel -->
      <div class="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
        <div v-if="trend?.newsThumbnail" class="relative w-full aspect-video">
          <img :src="trend.newsThumbnail" class="w-full h-full object-cover" alt="News Thumbnail"/>
          <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
            <p class="text-white font-medium text-lg">{{ trend.newsTitle || t('entity.components.newContent.components.newContentModal.trendContentForm.relatedNews') }}</p>
            <span class="text-white/80 text-xs">{{ formatNewsDate(trend.newsDate) }}</span>
          </div>
        </div>

        <div v-else class="flex items-center justify-center bg-gray-50 aspect-video">
          <div class="text-center p-6">
            <span class="material-symbols-outlined !text-[48px] text-gray-300 mb-3">image_not_supported</span>
            <p class="text-gray-500">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.noImageAvailable') }}</p>
          </div>
        </div>

        <div class="p-4">
          <h3 class="font-semibold text-gray-800 mb-3">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.trendInsights') }}</h3>

          <div class="grid grid-cols-3 gap-2">
            <div class="bg-gray-50 p-3 rounded-lg">
              <p class="text-xs text-gray-500 mb-1">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.searchVolume') }}</p>
              <p class="font-semibold text-gray-900">{{ formatVolumeDisplay(trend.volume) }}</p>
            </div>

            <div class="bg-gray-50 p-3 rounded-lg">
              <p class="text-xs text-gray-500 mb-1">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.growthRate') }}</p>
              <p class="font-semibold text-green-600">+{{ trend.increasePercentage }}%</p>
            </div>

            <div class="bg-gray-50 p-3 rounded-lg">
              <p class="text-xs text-gray-500 mb-1">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.trendStarted') }}</p>
              <p class="font-semibold text-gray-900">{{ formatTimeDisplay(trend.startTimestamp) }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Sağ Taraf - Pazarlama Mesajı (Çevirili) -->
      <div class="bg-white rounded-lg shadow-sm border border-gray-100 p-5 flex flex-col h-full">
        <div class="flex items-center gap-2 mb-4">
          <span class="material-symbols-outlined text-primary">auto_awesome</span>
          <h3 class="font-semibold text-gray-800">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.aiPoweredContent') }}</h3>
        </div>

        <div class="flex-grow flex flex-col items-center justify-center text-center p-4">
          <h4 class="text-lg font-bold text-gray-800 mb-4">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.marketingTitle') }}</h4>
          <p class="text-gray-600 mb-6">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.marketingDescription') }}</p>

          <div class="space-y-4 w-full">
            <div class="flex items-center gap-3">
              <div class="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center shrink-0">
                <span class="material-symbols-outlined text-green-600 !text-[18px]">published_with_changes</span>
              </div>
              <p class="text-sm text-left text-gray-700">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.benefit1') }}</p>
            </div>

            <div class="flex items-center gap-3">
              <div class="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center shrink-0">
                <span class="material-symbols-outlined text-blue-600 !text-[18px]">speed</span>
              </div>
              <p class="text-sm text-left text-gray-700">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.benefit2') }}</p>
            </div>

            <div class="flex items-center gap-3">
              <div class="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center shrink-0">
                <span class="material-symbols-outlined text-purple-600 !text-[18px]">trending_up</span>
              </div>
              <p class="text-sm text-left text-gray-700">{{ t('entity.components.newContent.components.newContentModal.trendContentForm.benefit3') }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { dateHelper } from '@/utils/dateHelper'

const props = defineProps({
  trend: {
    type: Object,
    required: true,
  },
  formData: {
    type: Object,
    required: true,
  },
  validationErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['update:form-data', 'back-to-trends', 'select-mode'])

const { t } = useI18n()

const formatTimeDisplay = (timestamp) => {
  if (!timestamp) return '-'

  const start = dateHelper.parse(timestamp * 1000)
  const now = dateHelper.now()
  const diffInHours = dateHelper.diff(now, start, 'hour')

  if (diffInHours < 1) {
    const diffInMinutes = dateHelper.diff(now, start, 'minute')
    return t('dashboard.components.trendKeywords.minutesAgo', {
      minutes: diffInMinutes,
    })
  }
  if (diffInHours < 24) {
    return t('dashboard.components.trendKeywords.hoursAgo', {
      hours: diffInHours,
    })
  }

  const diffInDays = Math.floor(diffInHours / 24)
  return t('dashboard.components.trendKeywords.daysAgo', { days: diffInDays })
}

const formatVolumeDisplay = (volume) => {
  if (!volume) return '-'
  if (volume >= 1000000) return `${(volume / 1000000).toFixed(1)}M+`
  if (volume >= 1000) return `${(volume / 1000).toFixed(1)}K+`
  if (volume >= 500) return `500+`
  return `${volume}+`
}

const formatNewsDate = (dateString) => {
  if (!dateString) return ''

  try {
    const date = new Date(dateString)
    return date.toLocaleDateString()
  } catch (e) {
    return dateString
  }
}

const extractDomain = (url) => {
  try {
    const domain = new URL(url).hostname
    return domain.replace('www.', '')
  } catch (e) {
    return url
  }
}
</script>