<template>
  <div class="bg-white rounded-lg p-6 shadow-sm">
    <!-- Navigation -->
    <div class="flex items-center justify-between mb-6">
      <button @click="$emit('select-mode', 'welcome')" class="flex items-center gap-1 text-sm text-primary hover:text-primary-700 transition-colors">
        <span class="material-symbols-outlined !text-[18px]">arrow_back</span> {{ $t('socialMedia.components.createPost.general.backToHome') }}
      </button>
    </div>

    <h3 class="text-xl font-bold text-gray-800 mb-2">{{ $t('socialMedia.components.createPost.manualForm.title') }}</h3>
    <p class="text-sm text-gray-600 mb-6">{{ $t('socialMedia.components.createPost.manualForm.description') }}</p>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <!-- Sol Kolon -->
      <div class="space-y-6">
        <!-- Platform Selection -->
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2"> {{ $t('socialMedia.components.createPost.manualForm.platforms') }} </label>
          <div class="flex flex-wrap gap-3">
            <button v-for="platform in platforms" :key="platform.id" @click="togglePlatform(platform.id)" type="button" :class="[
                'flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium transition-all',
                selectedPlatforms.includes(platform.id)
                  ? 'bg-primary text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
              ]">
              <img :src="platform.icon" :alt="platform.name" class="w-4 h-4" :class="selectedPlatforms.includes(platform.id) ? 'brightness-0 invert' : ''"/> {{ platform.name }}
            </button>
          </div>
        </div>

        <!-- Language Selection -->
        <div>
          <LanguageSelector v-model="languageId" :label="$t('socialMedia.components.createPost.manualForm.language')" :additionalInputClass="'bg-white rounded-md shadow-sm'"/>
        </div>

        <!-- Keywords -->
        <div>
          <TagInput v-model="keywords" :label="$t('socialMedia.components.createPost.manualForm.keywords')" :description="$t('socialMedia.components.createPost.manualForm.keywordsDescription')" :maxCount="20" :error="!canGenerate && keywords.length < 3 ? $t('socialMedia.components.createPost.manualForm.keywordsError') : ''" :showSearchandFileUpload="false" color="primary" containerClass="bg-white rounded-md shadow-sm"/>
        </div>
      </div>

      <!-- Sağ Kolon -->
      <div class="space-y-6">
        <!-- Content Format Selection -->
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2"> {{ $t('socialMedia.components.createPost.manualForm.contentFormat') }} </label>
          <div class="flex flex-wrap gap-3">
            <button type="button" v-for="ratio in aspectRatios" :key="ratio.id" @click="selectAspectRatio(ratio.id)" :class="[
                'relative flex items-center gap-2 px-6 py-3 rounded-md text-sm font-medium transition-all',
                selectedAspectRatio === ratio.id
                  ? 'bg-primary text-white'
                  : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
              ]">
              <div class="aspect-ratio-box" :class="ratio.boxClass">
                <div class="flex items-center justify-center p-1 w-full h-full rounded" :class="selectedAspectRatio === ratio.id ? 'bg-white/20' : 'bg-gray-200'">
                  {{ ratio.label }}
                </div>
              </div>
            </button>
          </div>
        </div>

        <!-- Video Content Options -->
        <div class="bg-gray-50 p-4 rounded-lg border border-gray-200">
          <div class="flex items-center mb-3">
            <VSwitch v-model="hasVideo" color="primary">
              <template #label-right>
                <span class="ml-2 block text-sm font-medium text-gray-700">
                  {{ $t('socialMedia.components.createPost.manualForm.includeVideo') || 'Video içeriği ekle' }}
                </span>
              </template>
            </VSwitch>
          </div>

          <div  class="mt-3">
            <VInput
                type="textarea"
                v-model="videoDescription"
                :label="$t('socialMedia.components.createPost.manualForm.videoDescription') || 'Video Açıklaması'"
                :description="$t('socialMedia.components.createPost.manualForm.videoDescriptionHelp') || 'Videoda ne olacağını detaylı açıklayın'"
                :placeholder="$t('socialMedia.components.createPost.manualForm.videoDescriptionPlaceholder') || 'Videoda gösterilecek içeriği anlatın'"
                :error="videoDescriptionError"
                textAreaHeight="h-28"
                size="md"
                :disabled="!hasVideo"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Action button -->
    <div class="mt-8">
      <VButton :button-text="$t('socialMedia.components.createPost.manualForm.generateButton')" icon="auto_awesome" :show-icon="true" :is-loading="isGenerating" :is-disabled="!canGenerate || isGenerating" size="lg" color="primary" variation="solid" :full-width="true" @click="generateContent"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import LanguageSelector from '@/components/base/inputs/VLanguageSelector.vue'
import TagInput from '@/components/base/inputs/VTagInput.vue'
import VButton from '@/components/base/buttons/VButton.vue'
import VSwitch from '@/components/base/inputs/VSwitch.vue'
import VInput from '@/components/base/inputs/VInput.vue'

const { t } = useI18n()

const props = defineProps({
  isGenerating: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits([
  'select-mode',
  'generate-content',
])

const assetStore = useAssetStore()

// Form data
const languageId = ref(assetStore.assetLanguage)
const selectedPlatforms = ref([])
const selectedAspectRatio = ref(3) // Default 16:9
const keywords = ref([])
const hasVideo = ref(false)
const videoDescription = ref('')
const videoDescriptionError = ref('')

// Videoya bağlı videoDescription validasyonu
watch(hasVideo, (newValue) => {
  if (newValue && !videoDescription.value) {
    videoDescriptionError.value = t('socialMedia.components.createPost.manualForm.videoDescriptionRequired') || 'Video açıklaması zorunludur'
  } else {
    videoDescriptionError.value = ''
  }
})

watch(videoDescription, (newValue) => {
  if (hasVideo.value && !newValue) {
    videoDescriptionError.value = t('socialMedia.components.createPost.manualForm.videoDescriptionRequired') || 'Video açıklaması zorunludur'
  } else {
    videoDescriptionError.value = ''
  }
})

// Options
const platforms = [
  { id: 1, name: 'Instagram', icon: require('@/assets/icons/instagram.svg') },
  { id: 2, name: 'Facebook', icon: require('@/assets/icons/facebook.svg') },
  { id: 3, name: 'TwitterX', icon: require('@/assets/icons/twitterx.svg') },
]

const aspectRatios = [
  { id: 1, label: '1:1', boxClass: 'w-6 h-6' },
  { id: 2, label: '9:16', boxClass: 'w-5 h-8' },
  { id: 3, label: '16:9', boxClass: 'w-8 h-5' },
]

// Computed properties
const canGenerate = computed(() => {
  return selectedPlatforms.value.length > 0 &&
      languageId.value &&
      keywords.value.length >= 3 &&
      (!hasVideo.value || (hasVideo.value && videoDescription.value.trim().length > 0))
})

// Methods
const togglePlatform = (platformId) => {
  const index = selectedPlatforms.value.indexOf(platformId)
  if (index === -1) {
    selectedPlatforms.value.push(platformId)
  } else {
    selectedPlatforms.value.splice(index, 1)
  }
}

const selectAspectRatio = (ratioId) => {
  selectedAspectRatio.value = ratioId
}

const generateContent = () => {
  if (!canGenerate.value) return

  if (hasVideo.value && !videoDescription.value.trim()) {
    videoDescriptionError.value = t('socialMedia.components.createPost.manualForm.videoDescriptionRequired') || 'Video açıklaması zorunludur'
    return
  }

  const formData = {
    assetId: assetStore.assetId,
    platforms: selectedPlatforms.value,
    aspectRatio: selectedAspectRatio.value,
    languageId: languageId.value,
    keywords: keywords.value.map(k => k.name || k),
    hasVideo: hasVideo.value,
    videoDescription: hasVideo.value ? videoDescription.value : null,
  }

  emit('generate-content', formData)
}
</script>