import * as yup from 'yup'
import _ from 'lodash'

export const MAX_KEYWORDS = 1000

// Category validation schema
const categorySchema = yup.object().shape({
  id: yup.number().nullable(),
  categoryId: yup.number().nullable(),
  state: yup.number().oneOf([1, 2, 3, 4]).default(1),
})

// Keyword validation schema
const keywordSchema = (t) =>
    yup.object().shape({
      id: yup.number().nullable(),
      keywordId: yup.number().nullable(),
      name: yup
          .string()
          .required(t('settings.components.configuration.validation.keyword.nameRequired'))
          .min(2, t('settings.components.configuration.validation.keyword.minLength'))
          .max(100, t('settings.components.configuration.validation.keyword.maxLength')),
      priority: yup
          .number()
          .transform((value) => (isNaN(value) ? null : value))
          .min(0, t('settings.components.configuration.validation.keyword.priorityRange'))
          .max(10, t('settings.components.configuration.validation.keyword.priorityRange'))
          .nullable(),
      state: yup
          .number()
          .oneOf([1, 2, 3, 4], t('settings.components.configuration.validation.keyword.invalidState'))
          .required(t('settings.components.configuration.validation.keyword.stateRequired')),
      categoryIds: yup.array().of(categorySchema).nullable(),
    })

// Configuration schema creator
export const createConfigurationSchema = (t) => {
  return yup.object().shape({
    countryId: yup.number().required(t('settings.components.configuration.validation.keyword.countryIdRequired')),
    timezoneId: yup.number().required(t('settings.components.configuration.validation.keyword.timezoneIdRequired')),
    keywords: yup
        .array()
        .of(keywordSchema(t))
        .test('keywords-not-empty', t('settings.components.configuration.validation.keyword.stateRequired'),
            value => value && value.length > 0)
        .test('max-keywords',
            t('settings.components.configuration.validation.keyword.maxKeywordsExceeded', { max: MAX_KEYWORDS }),
            value => (value?.filter(k => k.state !== 3) || []).length <= MAX_KEYWORDS)
        .test('duplicate-keywords',
            t('settings.components.configuration.validation.keyword.duplicateKeywords'),
            value => checkDuplicates(value, t)),
  })
}

// Helper function to check duplicates with reduce
const checkDuplicates = (keywords, t) => {
  if (!keywords) return true

  const duplicates = keywords.reduce((acc, keyword) => {
    if (keyword.state === 3) return acc

    const key = keyword.name.toLowerCase()
    const existing = acc.get(key)

    if (existing && existing.priority === keyword.priority) {
      return acc.set('error', {
        first: existing.name,
        second: keyword.name,
      })
    }

    return acc.set(key, { name: keyword.name, priority: keyword.priority })
  }, new Map())

  const error = duplicates.get('error')
  if (error) {
    throw new yup.ValidationError(
        t('settings.components.configuration.errors.duplicateDetail', error),
    )
  }

  return true
}

// Normalize locations
const normalizeLocations = locations => {
  if (!locations) return {}
  return _.mapValues(locations, locationArray =>
      _.sortBy(locationArray, 'locationId').map(loc => ({
        ...loc,
        state: loc.state,
      })),
  )
}

// Compare category arrays
const compareCategoryArrays = (oldCategories = [], newCategories = []) => {
  const normalizeCategory = cat => ({
    id: cat.id || cat.categoryId || null,
    categoryId: cat.categoryId || null,
    state: cat.state || 1,
  })

  const normalizedOld = _.sortBy(oldCategories.map(normalizeCategory), 'id')
  const normalizedNew = _.sortBy(newCategories.map(normalizeCategory), 'id')

  return _.isEqual(normalizedOld, normalizedNew)
}

// Normalize keywords for comparison
const normalizeKeywords = keywords => {
  if (!keywords) return []

  return _.chain(keywords)
      .map(k => ({
        id: k.id || k.keywordId,
        keywordId: k.keywordId,
        name: _.toLower(_.trim(k.name || '')),
        priority: k.priority || null,
        state: k.state,
        categoryIds: k.categoryIds || [],
      }))
      .sortBy(['state', 'name'])
      .value()
}

// Check for changes in data
export const hasChanges = (oldData, newData) => {
  if (!oldData || !newData) return false

  try {
    const oldKeywords = normalizeKeywords(oldData.keywords)
    const newKeywords = normalizeKeywords(newData.keywords)

    const keywordsChanged = oldKeywords.length !== newKeywords.length ||
        oldKeywords.some((oldKeyword, index) => {
          const newKeyword = newKeywords[index]
          return !_.isEqual(_.omit(oldKeyword, 'categoryIds'), _.omit(newKeyword, 'categoryIds')) ||
              !compareCategoryArrays(oldKeyword.categoryIds, newKeyword.categoryIds)
        })

    if (keywordsChanged) return true

    return oldData.countryId !== newData.countryId ||
        oldData.timezoneId !== newData.timezoneId ||
        !_.isEqual(normalizeLocations(oldData.locations), normalizeLocations(newData.locations))
  } catch (error) {
    console.error('Error checking changes:', error)
    return true
  }
}

// Configuration data validation
export const validateConfigurationData = async (data, schema) => {
  try {
    if (!data?.keywords || data.countryId === undefined || data.timezoneId === undefined) {
      return {
        isValid: false,
        errors: { keywords: 'Keywords data is required, countryId and timezoneId also required' },
      }
    }

    await schema.validate(data, { abortEarly: false })
    return { isValid: true, errors: null }
  } catch (error) {
    return {
      isValid: false,
      errors: _.chain(error.inner)
          .filter(err => err?.path)
          .keyBy(err => err.path.split('.').pop())
          .mapValues('message')
          .value(),
    }
  }
}