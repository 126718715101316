import { ref } from 'vue'
import axios from '@/plugins/axiosInstance'
import { responseHandlerService } from '@/plugins/responseHandlerService'

/**
 * İçerik durum yönetimini oluştur
 */
export const createContentState = () => {
  return {
    isGenerating: ref(false),
    generatedContent: ref(null),
    previousContent: ref(null),
    lastGenerationParams: ref(null),
    cancelTokenSource: ref(null),
  }
}

/**
 * İçerik oluşturma öncesi durum hazırlığı
 */
export const prepareGeneration = (state) => {
  if (state.isGenerating.value) return false

  state.isGenerating.value = true

  // Mevcut içeriği önceki olarak kaydet
  if (state.generatedContent.value) {
    state.previousContent.value = JSON.parse(JSON.stringify(state.generatedContent.value))
  }

  // İptal jetonu oluştur
  state.cancelTokenSource.value = axios.CancelToken.source()

  return true
}

/**
 * İçerik oluşturma durumunu temizle
 */
export const finalizeGeneration = (state) => {
  state.isGenerating.value = false
  state.cancelTokenSource.value = null
}

/**
 * İçerik oluşturma isteğini iptal et
 */
export const cancelGeneration = (state, t) => {
  try {
    if (state.cancelTokenSource?.value) {
      state.cancelTokenSource.value.cancel('Content generation canceled by user')

      if (t) {
        responseHandlerService.handleInfo(t('socialMedia.components.createPost.info.cancelGeneration'))
      } else {
        responseHandlerService.handleInfo('Content generation canceled')
      }
    }
  } catch (error) {
    console.error('Error canceling request:', error)
  } finally {
    // Her durumda isGenerating'i false'a ayarla ve overlay'i kapat
    state.isGenerating.value = false
    state.cancelTokenSource.value = null
  }
}

/**
 * İçerik oluşturma durumunu sıfırla
 */
export const resetContent = (state) => {
  // Mevcut içeriği önceki olarak kaydet
  if (state.generatedContent.value) {
    state.previousContent.value = JSON.parse(JSON.stringify(state.generatedContent.value))
  }

  // İçerik durumunu sıfırla
  state.generatedContent.value = null
  state.lastGenerationParams.value = null
}

/**
 * Önceki içeriği geri yükle
 */
export const restorePreviousContent = (state) => {
  if (state.previousContent.value) {
    state.generatedContent.value = JSON.parse(JSON.stringify(state.previousContent.value))
    return true
  }
  return false
}