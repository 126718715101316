<template>
  <div class="py-6 md:py-10">
    <div class="text-center mb-10">
      <h2 class="text-2xl md:text-3xl font-bold text-gray-800 mb-3">{{ t('entity.components.newContent.components.newContentModal.welcomeSection.title') }}</h2>
      <p class="text-gray-600 max-w-2xl mx-auto">{{ t('entity.components.newContent.components.newContentModal.welcomeSection.description') }}</p>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl mx-auto">
      <!-- Manuel İçerik Oluşturma Kartı -->
      <div class="group relative overflow-hidden bg-white rounded-xl shadow-lg border border-gray-100 transform transition-all duration-300 hover:shadow-xl cursor-pointer flex flex-col" @click="$emit('select-mode', 'manual')">
        <div class="card-header flex items-center p-4 bg-gradient-to-r from-primary/10 to-primary/5 border-b">
          <div class="flex items-center">
            <div class="w-10 h-10 bg-gradient-to-br from-primary/70 to-primary/95 rounded-lg flex items-center justify-center shadow-sm mr-3 transition-transform duration-300 group-hover:scale-110">
              <span class="material-symbols-outlined text-white !text-[20px] transition-all duration-300 group-hover:!text-[22px]">edit_document</span>
            </div>
            <h3 class="text-lg font-bold text-gray-800 transition-colors duration-300 group-hover:text-primary">
              {{ t('entity.components.newContent.components.newContentModal.welcomeSection.manualCard.title') }}</h3>
          </div>
        </div>
        <div class="p-5 flex-1 flex flex-col">
          <p class="text-gray-600 flex-1">{{ t('entity.components.newContent.components.newContentModal.welcomeSection.manualCard.description') }}</p>
          <div class="flex items-center text-primary font-medium mt-4 group-hover:font-semibold">
            {{ t('entity.components.newContent.components.newContentModal.welcomeSection.manualCard.button') }}
            <span class="material-symbols-outlined ml-2 transition-all duration-300 group-hover:translate-x-2">arrow_forward</span>
          </div>
        </div>
        <div class="absolute bottom-0 left-0 w-full h-1 bg-primary scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-300"></div>
      </div>

      <!-- Trend İçerik Oluşturma Kartı -->
      <div class="group relative overflow-hidden bg-white rounded-xl shadow-lg border border-gray-100 transform transition-all duration-300 hover:shadow-xl cursor-pointer flex flex-col" @click="$emit('select-mode', 'trend')">
        <div class="card-header flex items-center p-4 bg-gradient-to-r from-accent/10 to-accent/5 border-b">
          <div class="flex items-center">
            <div class="w-10 h-10 bg-gradient-to-br from-accent/70 to-accent/95 rounded-lg flex items-center justify-center shadow-sm mr-3 transition-transform duration-300 group-hover:scale-110">
              <span class="material-symbols-outlined text-white !text-[20px] transition-all duration-300 group-hover:!text-[22px]">trending_up</span>
            </div>
            <h3 class="text-lg font-bold text-gray-800 transition-colors duration-300 group-hover:text-accent">
              {{ t('entity.components.newContent.components.newContentModal.welcomeSection.trendCard.title') }}</h3>
          </div>
        </div>
        <div class="p-5 flex-1 flex flex-col">
          <p class="text-gray-600 flex-1">{{ t('entity.components.newContent.components.newContentModal.welcomeSection.trendCard.description') }}</p>
          <div class="flex items-center text-accent font-medium mt-4 group-hover:font-semibold">
            {{ t('entity.components.newContent.components.newContentModal.welcomeSection.trendCard.button') }}
            <span class="material-symbols-outlined ml-2 transition-all duration-300 group-hover:translate-x-2">arrow_forward</span>
          </div>
        </div>
        <div class="absolute bottom-0 left-0 w-full h-1 bg-accent scale-x-0 group-hover:scale-x-100 origin-left transition-transform duration-300"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineEmits(['select-mode'])
</script>