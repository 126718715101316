<template>
  <Teleport to="body">
    <div v-if="modalVisible" @keydown.esc="escToClose && closeModal()" tabindex="-1" class="fixed inset-0 overflow-y-auto z-10" :style="{ zIndex: computedZIndex }" @click.self.stop.prevent="clickToClose && closeModal()">
      <!-- Backdrop -->
      <div class="fixed inset-0 bg-black bg-opacity-50 transition-opacity modal-backdrop z-0" :class="{ 'pointer-events-none': isNestedModal && preventCloseNested }" @click.stop.prevent="clickToClose && closeModal()" data-testid="modal-backdrop"></div>

      <!-- Modal Container -->
      <div :class="[modalClass, {'h-screen w-screen flex items-stretch justify-center': size === 'full'}]" class="relative h-full w-full">
        <!-- Modal Content -->
        <div :class="[computedContentClass, {'h-full w-full': size === 'full'}]" class="modal-content relative bg-white rounded-lg shadow-xl overflow-hidden min-h-[300px] flex flex-col" :style="props.minHeight ? {minHeight: props.minHeight} : {}">
          <!-- Modal Header -->
          <div v-if="!hideHeader" class="modal-header bg-gradient-to-r from-primary/5 to-transparent border-b backdrop-blur-sm px-6 py-4 flex items-center justify-between">
            <div class="flex items-center gap-3">
              <!-- İkon alanı -->
              <span v-if="icon && !customIcon" class="material-symbols-outlined text-primary text-2xl">{{ icon }}</span>

              <!-- Özel ikon slot'u -->
              <slot name="icon">
                <slot name="header-icon"></slot>
              </slot>

              <!-- Başlık ve açıklama alanı -->
              <div v-if="modalTitle || modalDescription">
                <h2 v-if="modalTitle" :class="[titleAlignmentClass, titleClass]" class="flex items-center gap-2">
                  {{ modalTitle }} </h2>
                <p v-if="modalDescription" class="text-sm text-gray-500">{{ modalDescription }}</p>
              </div>

              <!-- Özel header content slotu -->
              <slot name="header-content"></slot>
            </div>

            <!-- Sağ taraf: Action butonları ve kapatma -->
            <div class="flex items-center gap-3">
              <!-- Header aksiyonları için slot -->
              <slot name="header-actions"></slot>

              <!-- Kapatma butonu -->
              <button v-if="!hideCloseButton" @click="closeModal" class="text-gray-500 hover:text-gray-700 flex items-center" :title="closeButtonTitle">
                <span class="material-symbols-outlined">close</span>
              </button>
            </div>
          </div>

          <!-- Modal Body -->
          <div :class="{'p-6': !noPadding}" class="h-full flex-grow overflow-auto" :style="{ height: `calc(100% - ${hideHeader ? '0px' : '60px'})` }">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, nextTick, watch } from 'vue'
import { useVModalStore } from '@/stores/modal'

// Benzersiz modal ID değeri
const modalInstanceId = ref(`modal-${Math.random().toString(36).substring(2, 9)}`)
const modalStore = useVModalStore()

const props = defineProps({
  // Modal durumu
  modelValue: {
    type: Boolean,
    default: false,
  },

  // Modal tanımlayıcısı (opsiyonel)
  id: {
    type: String,
    default: null,
  },

  // Z-index değeri (manuel)
  zIndex: {
    type: [Number, String],
    default: null,
  },

  // Kapanma davranışları
  clickToClose: {
    type: Boolean,
    default: true,
  },
  escToClose: {
    type: Boolean,
    default: true,
  },

  // Nested modal davranışı
  isNestedModal: {
    type: Boolean,
    default: false,
  },
  preventCloseNested: {
    type: Boolean,
    default: false,
  },

  // Stil sınıfları
  modalClass: {
    type: String,
    default: 'flex justify-center items-center',
  },
  contentClass: {
    type: String,
    default: 'flex flex-col bg-white border rounded-md space-y-2 shadow-md relative',
  },

  // Boyut
  width: {
    type: String,
    default: 'w-[500px] max-w-full',
  },
  size: {
    type: String,
    default: 'default', // 'default', 'medium', 'large', 'full'
  },

  // Header içeriği
  modalTitle: {
    type: String,
    default: '',
  },
  modalDescription: {
    type: String,
    default: '',
  },
  titleAlignment: {
    type: String,
    default: 'left',
  },
  titleClass: {
    type: String,
    default: 'text-xl font-bold text-gray-800',
  },
  icon: {
    type: String,
    default: '',
  },
  customIcon: {
    type: Boolean,
    default: false,
  },

  // Görünürlük ayarları
  hideHeader: {
    type: Boolean,
    default: false,
  },
  hideCloseButton: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  closeButtonTitle: {
    type: String,
    default: 'Kapat',
  },

  // CSS özelliği
  minHeight: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:modelValue', 'opened', 'closed'])

// Modal durumu ve store ile entegrasyon
const modalVisible = ref(false)
const currentModalId = computed(() => props.id || modalInstanceId.value)

// Style hesaplamaları
const titleAlignmentClass = computed(() => {
  return {
    'text-left': props.titleAlignment === 'left',
    'text-center': props.titleAlignment === 'center',
    'text-right': props.titleAlignment === 'right',
  }
})

const computedContentClass = computed(() => {
  const sizeClasses = {
    'default': props.width,
    'medium': 'w-[700px] max-w-full',
    'large': 'w-[900px] max-w-full',
    'xlarge': 'w-[1200px] max-w-[90vw]',
    'full': 'w-screen h-screen rounded-none',
  }

  // Güvenli erişim - size geçerli değilse default kullan
  const sizeClass = sizeClasses[props.size] || sizeClasses['default']

  return `${props.contentClass} ${sizeClass}`
})

// Z-index hesaplaması
const computedZIndex = computed(() => {
  if (props.zIndex) {
    return parseInt(props.zIndex, 10)
  }

  // Modalın durumuna göre store'dan z-index alınır
  const modal = modalStore.modalById(currentModalId.value)
  if (modal && modal.zIndex) {
    return modal.zIndex
  }

  return 9999 // Varsayılan z-index değeri - navbar (z-index: 100) üzerinde görüntülenecek
})

// Modal açıldığında
const registerAndOpen = () => {
  // Modalın kapalı olduğundan emin ol
  if (!modalVisible.value) return

  // Modalı store'a kaydet/güncelle
  modalStore.registerModal({
    id: currentModalId.value,
    title: props.modalTitle || 'Modal',
    component: null,
    props: {},
    isOpen: true,
    zIndex: props.zIndex || 9999,
  })

  // Body scrollunu devre dışı bırak (modallar 1+ ise)
  document.body.style.overflow = 'hidden'

  // Modala odaklan
  nextTick(() => {
    const modalElement = document.querySelector('.modal-content')
    if (modalElement) {
      modalElement.focus()
    }

    emit('opened')
  })
}

// Modal kapandığında
const closeModal = () => {
  modalVisible.value = false
  emit('update:modelValue', false)

  // Modalı kapat
  modalStore.closeModal(currentModalId.value)

  // Modalı kapatırken iç modalların durumunu kontrol et
  if (props.isNestedModal && props.preventCloseNested) {
    // İç modal kapandığında üst modalın arka planına tıklanabilirliği kontrol et
    const parentModals = document.querySelectorAll('.modal-backdrop')
    if (parentModals.length > 0) {
      setTimeout(() => {
        // En üstteki backdrop'u tıklanabilir yap
        parentModals[parentModals.length - 1].classList.remove('pointer-events-none')
      }, 100)
    }
  }

  // Eğer başka açık modal yoksa body scroll'u geri aç
  if (modalStore.activeModals.length === 0) {
    document.body.style.overflow = ''
  }

  emit('closed')
}

// Modal kayıt ve yönetim işlemlerini ayrı fonksiyonlara bölelim
const registerModal = () => {
  if (!props.id) return

  modalStore.registerModal({
    id: currentModalId.value,
    title: props.modalTitle || 'Modal',
    isOpen: modalVisible.value,
    zIndex: props.zIndex || 9999,
    isNested: props.isNestedModal,
  })
}

// İç içe modal işlemleri için ayrı fonksiyon
const handleNestedModals = () => {
  if (!props.isNestedModal || !modalVisible.value) return

  const parentModals = document.querySelectorAll('.modal-backdrop')
  if (parentModals.length <= 1) return

  // Son eleman dışındaki arka planları etkisizleştir
  Array.from(parentModals)
      .slice(0, -1)
      .forEach(modal => modal.classList.add('pointer-events-none'))
}


// modelValue değiştiğinde modal durumunu güncelle
watch(() => props.modelValue, (newValue) => {
  modalVisible.value = newValue

  if (newValue) {
    registerAndOpen()
  } else {
    closeModal()
  }
}, { immediate: true })

// Component oluşturulduğunda
onMounted(() => {
  // Modal kayıt işlemi
  registerModal()

  // İç içe modal kontrolü
  handleNestedModals()

  // Modal açıksa açılış işlemi
  if (modalVisible.value) {
    registerAndOpen()
  }
})

// Component kaldırıldığında
onBeforeUnmount(() => {
  // Store kaydını kaldır
  if (props.id) {
    modalStore.unregisterModal(currentModalId.value)
  }

  // Eğer başka açık modal yoksa body scroll'u geri aç
  if (modalStore.activeModals.length === 0) {
    document.body.style.overflow = ''
  }
})
</script>

<style scoped>
/* Modal animasyonları */
@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalSlideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fixed {
  animation: modalFadeIn 0.2s ease-out;
}

.fixed > div > div {
  animation: modalSlideDown 0.3s ease-out;
}

/* Pointer events none için stil */
.pointer-events-none {
  pointer-events: none !important;
}
</style>