import { responseHandlerService } from '@/plugins/responseHandlerService'
import { processKeyword, validateRow } from './keywordProcessors'
import { initializeResults, updateResultsByType, addInvalidResult, addSkippedResult, showImportResults } from './importResultHandlers'
import { readExcelFile, validateWorksheetExists, worksheetToJson, getContentRowsWithoutHeader } from './excelUtils/excelCore'
import { extractValidCategoryIds } from '@/views/settings/components/configuration/components/keywordTable/utils/keywordImport/excelUtils'

export class KeywordImportManager {
  constructor(props, t) {
    this.maxKeywords = props.maxKeywords
    this.existingKeywords = [...props.keywords]
    this.t = t
    this.results = initializeResults()
    this.validCategoryIds = new Set()
  }

  async importFile(file) {
    try {
      const workbook = await readExcelFile(file)

      // First validate Categories sheet and extract valid IDs
      await this.validateCategories(workbook)

      // Then process Keywords sheet
      await this.processWorkbook(workbook)
      showImportResults(this.results, this.t)

      return {
        success: true,
        keywords: this.existingKeywords,
        results: this.results,
      }
    } catch (error) {
      console.error('Import failed:', error)
      responseHandlerService.errorHandler(
          this.t('settings.components.configuration.components.keywordsTable.importError'),
      )
      return { success: false, error }
    }
  }

  async validateCategories(workbook) {
    // Check if Categories sheet exists
    if (!validateWorksheetExists(workbook, 'Categories')) {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.noCategoriesSheet'),
      )
      return
    }

    // Get valid category IDs from Categories sheet
    this.validCategoryIds = extractValidCategoryIds(workbook)

    if (this.validCategoryIds.size === 0) {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.emptyCategoriesSheet'),
      )
    }
  }

  async processWorkbook(workbook) {
    const allKeywords = []

    // Check Keywords sheet
    if (validateWorksheetExists(workbook, 'Keywords')) {
      const rows = worksheetToJson(workbook, 'Keywords')
      if (rows.length <= 1) {
        responseHandlerService.warningHandler(
            this.t('settings.components.configuration.components.keywordsTable.emptySheet'),
        )
        return
      }

      const contentRows = getContentRowsWithoutHeader(rows)
      await this.validateKeywordLimit(contentRows.length)

      if (this.canProcessKeywords) {
        const sheetKeywords = this.processSheet(contentRows, 'Keywords')
        allKeywords.push(...sheetKeywords)
      }
    } else {
      responseHandlerService.warningHandler(
          this.t('settings.components.configuration.components.keywordsTable.noKeywordsSheet'),
      )
      return
    }

    await this.processKeywords(allKeywords)
  }

  async validateKeywordLimit(newKeywordsCount) {
    this.canProcessKeywords = true
    const totalKeywords = this.existingKeywords.length + newKeywordsCount

    if (totalKeywords > this.maxKeywords) {
      const availableSlots = this.maxKeywords - this.existingKeywords.length

      if (availableSlots <= 0) {
        responseHandlerService.errorHandler(
            this.t('settings.components.configuration.components.keywordsTable.noAvailableSlots'),
        )
        this.canProcessKeywords = false
      } else {
        responseHandlerService.warningHandler(
            this.t('settings.components.configuration.components.keywordsTable.partialImport',
                {
                  available: availableSlots,
                  total: newKeywordsCount,
                },
            ),
        )
      }
    }
  }

  processSheet(rows, sheetName) {
    const keywords = []
    let processedCount = 0

    for (let i = 0; i < rows.length; i++) {
      // Limit kontrolü
      if (this.existingKeywords.length + processedCount >= this.maxKeywords) {
        addSkippedResult(this.results, { rowInfo: { row: i + 2, sheet: sheetName } }, 'keyword_limit_exceeded')
        continue
      }

      const rowInfo = {
        sheet: sheetName,
        row: i + 2, // +2 because we want to show actual Excel row number (1-based + header)
      }

      const result = validateRow(rows[i], this.validCategoryIds)
      if (result.valid) {
        keywords.push({ ...result.data, rowInfo })
        processedCount++
      } else {
        addInvalidResult(result.reason, rowInfo, result.invalidIds)
      }
    }

    return keywords
  }

  async processKeywords(keywords) {
    try {
      // Sıralama - isteğe bağlı
      keywords.sort((a, b) => a.keyword.localeCompare(b.keyword))

      for (const keywordData of keywords) {
        const result = processKeyword(keywordData, this.existingKeywords)
        updateResultsByType(this.results, result, keywordData.rowInfo)
      }
    } catch (error) {
      console.error('Error processing keywords:', error)
      throw new Error('Failed to process keywords')
    }
  }
}