<template>
  <VueFinalModal :click-to-close="!isLoading" :esc-to-close="!isLoading" @opened="handleModalOpened" @closed="handleModalClosed" class="flex justify-center items-center" content-class="flex flex-col bg-white rounded-lg shadow-xl w-full md:w-[90vw] md:max-w-7xl h-screen md:h-[80vh]">
    <div class="h-full flex flex-col">
      <header class="bg-white border-b px-4 py-2 rounded-t-lg flex items-center justify-between">
        <h2 class="text-lg font-bold text-gray-800">{{ t('settings.components.configuration.components.documentModal.title') }}</h2>
        <button type="button" @click="closeModal" class="text-gray-600 hover:text-gray-800 flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </header>

      <div class="flex-grow flex flex-col md:flex-row overflow-hidden">
        <nav class="w-full md:w-1/5 bg-gray-100 p-4 space-y-1">
          <CustomButton v-for="tab in tabs" :key="tab.value" @click="setActiveTab(tab.value)" class="w-full text-left p-2 rounded-lg transition-colors" :button-text="tab.label" :icon="tab.icon" :color="activeTab === tab.value ? 'primary' : 'neutral'" :variation="activeTab === tab.value ? 'solid' : 'outline'" :full-width="true" icon-position="right" :is-icon-spaced="true" :show-icon="true"/>
        </nav>

        <main class="w-full md:w-4/5 p-2 md:p-6 overflow-auto flex flex-col relative">
<!-- 
Buton Özellikleri Açıklaması:
- button-text: Buton üzerinde gösterilecek metin
- size: Butonun boyutu (xs, sm, md, lg)
- color: Butonun renk teması (primary, secondary, neutral, success, warning, danger)
- is-loading: Yükleme durumunda spinner gösterilir
- is-disabled: Butonun devre dışı kalma durumu

Silme işlemi için "Evet, Sil" butonunda sistem tarafından "primary" renk özelliği tercih edilmiştir.
Sistem içindeki buton renkleri ve anlamları:
- primary: Ana buton, önemli işlemler için (ekleme, kaydetme, silme onaylama)
- secondary: İkincil butonlar (iptal, geri dönme)
- danger: Tehlikeli işlemler iöin kullanılır ancak burada "primary" tercih edilmiştir
-->

          <div v-if="showDeleteConfirm" class="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div class="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
              <div class="flex items-center mb-4 text-red-600">
                <span class="material-symbols-outlined mr-2">warning</span>
                <h3 class="text-lg font-semibold">Dosyayı silmek istediğinizden emin misiniz?</h3>
              </div>
              <div class="flex justify-end space-x-2">
              
                <CustomButton 
                  @click="cancelDelete" 
                  button-text="İptal" 
                  size="sm" 
                  color="secondary" 
                  :is-disabled="isDeleting"/>
                  <CustomButton 
                  @click="confirmDelete" 
                  button-text="Evet, Sil" 
                  size="sm" 
                  color="primary"
                  :is-loading="isDeleting"/>
              </div>
            </div>
          </div>

          <div v-if="activeTab === 'uploadFile'" class="flex-grow">
            <FileUpload v-model="files"/>
          </div>

          <div v-else-if="activeTab === 'addUrl'" class="flex-grow">
            <UrlUpload v-model="urls"/>
          </div>

          <div v-else-if="activeTab === 'documents'" class="flex-grow">
            <div class="flex justify-between items-center mb-4">
              <h3 class="text-lg font-medium text-gray-700">Tüm Dosyalar</h3>
              <div class="flex items-center space-x-2">
                <span v-if="isLoadingDocuments" class="text-sm text-gray-500">Yükleniyor...</span>
                <button 
                  @click="refreshDocuments" 
                  class="flex items-center space-x-1 px-3 py-1.5 rounded-md bg-white border border-gray-300 text-primary hover:bg-primary/5 transition-colors"
                  :disabled="isLoadingDocuments"
                >
                  <span 
                    class="material-symbols-outlined !text-[18px]"
                    :class="{'animate-spin': isLoadingDocuments}"
                  >refresh</span>
                  <span class="text-sm font-medium">Yenile</span>
                </button>
              </div>
            </div>
            <CustomTable 
              :data="paginatedDocuments" 
              :columns="tableColumns" 
              :is-loading="isLoadingDocuments">
              <template #fileName="{ item }">
                <Tooltip :title="item.fileName" :display-condition="true" class="z-50">
                  <template #content>
                    <span>{{ truncateText(item.fileName, 30) }}</span>
                  </template>
                </Tooltip>
              </template>

              <template #category="{ item }">
                <span v-if="item.categoryName" class="px-2 py-1 bg-primary/10 text-primary text-xs rounded-full">
                  {{ item.categoryName }}
                </span>
                <span v-else class="text-gray-400 text-xs">-</span>
              </template>

              <template #fileType="{ item }">
                <span 
                  :class="{
                    'bg-blue-100 text-blue-800': item.fileType === 'DOSYA',
                    'bg-purple-100 text-purple-800': item.fileType === 'SUNUM',
                    'bg-green-100 text-green-800': item.fileType === 'GÖRSEL',
                    'bg-red-100 text-red-800': item.fileType === 'VİDEO',
                    'bg-yellow-100 text-yellow-800': item.fileType === 'TEKLİF',
                    'bg-indigo-100 text-indigo-800': item.fileType === 'PROSEDÜR',
                    'bg-pink-100 text-pink-800': item.fileType === 'TALİMAT',
                    'bg-gray-100 text-gray-600': !item.fileType
                  }"
                  class="px-2 py-1 text-xs font-medium rounded-full"
                >
                  {{ item.fileType || '-' }}
                </span>
              </template>
              
              <template #state="{ item }">
                <div class="flex items-center justify-center">
                  <span 
                    :class="getStateColor(item.state)"
                    class="px-2 py-1 text-xs font-medium rounded-full flex items-center"
                  >
                    <span v-if="getStateIcon(item.state)" class="material-symbols-outlined !text-[14px] mr-1">{{ getStateIcon(item.state) }}</span>
                    {{ getStateText(item.state) }}
                  </span>
                </div>
              </template>

              <template #addDate="{item}">
                <div class="flex items-center w-full justify-center">
                  <span>
                  {{ item.addDate }}
                  </span>
                </div>
              </template>

              <template #description="{ item }">
                <Tooltip :title="item.description || t('settings.components.configuration.components.documentModal.noDescription')" :display-condition="true" class="z-50" :full-width="true" container-class="flex justify-center z-50">
                  <template #content>
                    <span class="material-symbols-outlined cursor-pointer text-primary !text-[20px]">info</span>
                  </template>
                </Tooltip>
              </template>

              <template #actions="{ item }">
                <div class="flex items-center space-x-2 justify-center">
                  <Tooltip :title="t('settings.components.configuration.components.documentModal.deleteTooltip')" :display-condition="true" class="z-50" container-class="flex justify-center z-50">
                    <template #content>
                      <button 
                        @click="deleteDocument(item.id)" 
                        class="text-red-500 hover:text-red-700 flex items-center relative" 
                        :disabled="item.isDeleting"
                        aria-label="Belgeyi sil"
                      >
                        <span v-if="item.isDeleting" class="absolute inset-0 flex items-center justify-center">
                          <span class="animate-spin h-4 w-4 border-2 border-red-500 rounded-full border-t-transparent"></span>
                        </span>
                        <span :class="{'opacity-0': item.isDeleting}" class="material-symbols-outlined !text-[20px]">delete</span>
                      </button>
                    </template>
                  </Tooltip>
                  <Tooltip :title="t('settings.components.configuration.components.documentModal.viewDocument')" :display-condition="true" class="z-50" container-class="flex justify-center z-50">
                    <template #content>
                      <a :href="item.data" target="_blank" rel="noopener noreferrer" class="text-blue-500 hover:text-blue-700 flex items-center"> <span class="material-symbols-outlined !text-[20px]">open_in_new</span></a>
                    </template>
                  </Tooltip>
                </div>
              </template>

            </CustomTable>
            
            <!-- Özel pagination bileşeni -->
            <Pagination 
              :currentPage="pagination.currentPage" 
              :pageSize="pagination.pageSize" 
              :totalItems="pagination.total"
              @update:pagination="updatePagination"
            />
          </div>

          <div v-if="activeTab !== 'documents'" class="mt-4 flex flex-col md:flex-row md:justify-between md:items-center space-y-2 md:space-y-0">
            <div class="text-sm text-gray-600">
              {{ uploadSummary }}
            </div>
            <CustomButton :button-text="t('settings.components.configuration.components.documentModal.uploadButton')" @click="handleUpload" :is-loading="isLoading" :is-disabled="isUploadDisabled" size="sm" variant="primary" class="w-full md:w-auto"/>
          </div>
        </main>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import FileUpload from './components/fileUpload.vue'
import UrlUpload from './components/urlUpload.vue'
import Pagination from './components/pagination.vue'
import CustomTable from '@/components/table/VTable.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import dayjs from 'dayjs'
import { useAssetStore } from '@/stores/asset'
import {getFileTypeName, getStateText, getStateIcon, getStateColor} from './utils'

const { t } = useI18n()
const assetStore = useAssetStore()

const emit = defineEmits(['update:show'])

const isLoading = ref(false)
const isLoadingDocuments = ref(false)
const activeTab = ref('documents')
const files = ref([])
const urls = ref([])
const documents = ref([])
const showDeleteConfirm = ref(false)
const documentToDelete = ref(null)

// Pagination için değişkenler
const pagination = ref({
  currentPage: 1,
  pageSize: 10,
  total: 0
})

const tabs = [
  { value: 'documents', label: t('settings.components.configuration.components.documentModal.documentsTab'), icon: 'description' },
  { value: 'uploadFile', label: t('settings.components.configuration.components.documentModal.uploadFileTab'), icon: 'upload_file' }
  //{ value: 'addUrl', label: t('settings.components.configuration.components.documentModal.addUrlTab'), icon: 'link' },
]

const tableColumns = [
  { key: 'fileName', label: t('settings.components.configuration.components.documentModal.documentName') },
  { key: 'category', label: 'Kategori', align: 'center' },
  { key: 'fileType', label: 'Dosya Türü', align: 'center' },
  { key: 'state', label: 'Durum', align: 'center' },
  { key: 'addDate', label: t('settings.components.configuration.components.documentModal.uploadDate'), align: 'center' },
  { key: 'description', label: t('settings.components.configuration.components.documentModal.description'), align: 'center' },
  { key: 'actions', label: t('settings.components.configuration.components.documentModal.actions'), align: 'center' },
]

const uploadSummary = computed(() => {
  if (activeTab.value === 'uploadFile') {
    return t('settings.components.configuration.components.documentModal.filesSummary', { count: files.value.length })
  } else if (activeTab.value === 'addUrl') {
    return t('settings.components.configuration.components.documentModal.urlsSummary', { count: urls.value.length })
  }
  return ''
})

const isUploadDisabled = computed(() => {
  if (activeTab.value === 'uploadFile') {
    // Dosya, kategori ve dosya türü seçimi zorunlu
    return files.value.length === 0 || files.value.some(file => !file.categoryId || !file.fileType)
  } else if (activeTab.value === 'addUrl') {
    return urls.value.length === 0 || urls.value.some(url => !url.categoryId || !url.fileType)
  }
  return true
})

const setActiveTab = (tabValue) => {
  if (showDeleteConfirm.value) {
    cancelDelete()
  }
  activeTab.value = tabValue
}

const handleModalOpened = () => {
  fetchDocuments()
}

const handleModalClosed = () => {
  resetState()
}

const resetState = () => {
  files.value = []
  urls.value = []
  activeTab.value = 'documents'
  isDeleting.value = false
  cancelDelete()
}

const handleUpload = () => {
  if (isUploadDisabled.value) return

  cancelDelete()
  isLoading.value = true

  const formData = new FormData()
  formData.append('assetId', assetStore.assetId)

  if (activeTab.value === 'uploadFile') {
    files.value.forEach((fileItem, index) => {
      formData.append(`file`, fileItem.file)
      formData.append(`description[${index}]`, fileItem.description || '')
      formData.append(`categoryId[${index}]`, fileItem.categoryId)
      formData.append(`typeId[${index}]`, fileItem.fileType)
    })
  } else {
    urls.value.forEach((urlItem, index) => {
      formData.append(`url`, urlItem.url)
      formData.append(`description`, urlItem.description || '')
      if (urlItem.categoryId) {
        formData.append(`categoryId`, urlItem.categoryId)
      }
      if (urlItem.fileType) {
        formData.append(`fileType`, urlItem.fileType)
      }
    })
  }

  axios.post(settings.uploadDocumentFiles, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then(response => {
      responseHandlerService.handleSuccess(t('settings.components.configuration.components.documentModal.uploadSuccess'))
      
      // Yükleme başarılı ise, docs tabına geç ve belgeleri yeniden yükle
      fetchDocuments()
      resetState()
      setActiveTab('documents')
      
      // Pagination'u ilk sayfaya sıfırla (en yeni yüklenen belgeler gözüksün diye)
      pagination.value.currentPage = 1
    })
    .catch(error => {
      responseHandlerService.handleError(error, t('settings.components.configuration.components.documentModal.uploadError'))
    })
    .finally(() => {
      isLoading.value = false
    })
}

// Belgeleri yeniden yükleme fonksiyonu
const refreshDocuments = () => {
  // Onay modalı açıksa kapat
  if (showDeleteConfirm.value) {
    cancelDelete()
  }
  
  // Pagination'u ilk sayfaya sıfırla
  pagination.value.currentPage = 1
  
  // Belgeleri yeniden yükle
  fetchDocuments()
}

const fetchDocuments = async () => {
  isLoadingDocuments.value = true
  try {
    const response = await axios.get(settings.getDocumentFiles, {
      params: { assetId: assetStore.assetId },
    })

    documents.value = response.data.data.map(doc => ({
      ...doc,
      typeId: doc.typeId === 1 ? t('settings.components.configuration.components.documentModal.file') : t('settings.components.configuration.components.documentModal.url'),
      addDate: dayjs(doc.addDate).format('YYYY-MM-DD HH:mm'),
      description: doc.description || t('settings.components.configuration.components.documentModal.noDescription'),
      fileType: getFileTypeName(doc.typeId),
      // State değeri (API'den gelmiyorsa varsayılan olarak 2 (hazır) yap)
      state: doc.state || 2
    }))
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.configuration.components.documentModal.fetchError'))
  } finally {
    isLoadingDocuments.value = false
  }
}

const deleteDocument = (id) => {
  // Önce onay modalını göster, gereksiz isDeleting işaretlemeyi kaldır
  documentToDelete.value = id
  showDeleteConfirm.value = true
}

const isDeleting = ref(false)

const confirmDelete = async () => {
  if (!documentToDelete.value) return
  isDeleting.value = true
  
  // Silinen belgenin spinner'ını göster
  const docIndex = documents.value.findIndex(doc => doc.id === documentToDelete.value)
  if (docIndex !== -1) {
    documents.value[docIndex] = { ...documents.value[docIndex], isDeleting: true }
  }

  try {
    await axios.put(settings.deleteDocumentFiles, { id: documentToDelete.value, assetId: assetStore.assetId })
    documents.value = documents.value.filter(doc => doc.id !== documentToDelete.value)
    responseHandlerService.handleSuccess(t('settings.components.configuration.components.documentModal.deleteSuccess'))
  } catch (error) {
    // Hata durumunda spinner'ı kapat
    if (docIndex !== -1 && documents.value[docIndex]) {
      documents.value[docIndex].isDeleting = false
    }
    responseHandlerService.handleError(error, t('settings.components.configuration.components.documentModal.deleteError'))
  } finally {
    isDeleting.value = false
    cancelDelete()
  }
}

const cancelDelete = () => {
  documentToDelete.value = null
  showDeleteConfirm.value = false
}

const closeModal = () => {
  cancelDelete()
  emit('update:show', false)
}

const truncateText = (text, maxLength) => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
}

// Not: getStateText artık utils içinden import ediliyor

// Pagination fonksiyonu
const updatePagination = (newPagination) => {
  pagination.value = newPagination;
}

// Tarihsel olarak sıralanmış ve sayfalanmış dökümanlar
const paginatedDocuments = computed(() => {
  // Belgeleri tarihe göre sıralama (en yeni en üstte)
  const sortedDocs = [...documents.value].sort((a, b) => {
    return new Date(b.addDate) - new Date(a.addDate);
  });
  
  // Toplam sayıyı güncelle
  pagination.value.total = sortedDocs.length;
  
  // Sayfalama işlemi
  const start = (pagination.value.currentPage - 1) * pagination.value.pageSize;
  const end = start + pagination.value.pageSize;
  return sortedDocs.slice(start, end);
});

watch(activeTab, () => {
  if (showDeleteConfirm.value) {
    cancelDelete()
  }
})
</script>