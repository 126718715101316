<template>
  <div class="space-y-6">
    <!-- Minimal Dosya Yükleme Alanı -->
    <div class="border-2 border-dashed rounded-lg transition-all duration-300 ease-in-out" 
        :class="{
          'border-blue-500 bg-blue-50': isDragOver,
          'border-gray-300 hover:border-blue-200': !isDragOver
        }" 
        @dragenter.prevent="handleDragEnter" 
        @dragleave.prevent="handleDragLeave" 
        @dragover.prevent 
        @drop.prevent="handleFileDrop">
      <input type="file" ref="fileInput" class="hidden" multiple @change="handleFileUpload" accept=".pdf,.doc,.docx,.txt,.rtf,.jpg,.jpeg,.png,.gif,.mp4,.avi,.pptx,.ppt"/>
      
      <div class="flex items-center justify-center py-3 px-4">
        <div class="flex flex-col items-center text-center">
          <div class="mb-2">
            <span class="material-symbols-outlined text-3xl" :class="isDragOver ? 'text-blue-500' : 'text-gray-400'">
              upload_file
            </span>
          </div>
          <div class="max-w-sm">
            <p class="text-sm font-medium mb-1" :class="{ 'text-blue-600': isDragOver, 'text-gray-600': !isDragOver }">
              Dosyaları sürükleyin veya <button @click.prevent="fileInput.click()" class="text-primary hover:underline focus:outline-none">dosya seçin</button>
            </p>
            <p class="text-xs text-gray-500">
              Belge, sunum, görsel, video ve diğer dosyalar (maks. 10MB)
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Yüklenen dosyalar listesi - Daha minimal ve kullanıcı dostu tasarım -->
    <div v-if="modelValue && modelValue.length > 0" class="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div class="px-4 py-3 bg-gray-50 border-b flex items-center justify-between">
        <h3 class="text-sm font-medium text-gray-700">Yüklemeye Hazır Dosyalar ({{ modelValue.length }})</h3>
        <button @click="expandAll = !expandAll" class="text-xs text-primary hover:text-primary-dark">
          {{ expandAll ? 'Tümünü Daralt' : 'Tümünü Genişlet' }}
        </button>
      </div>

      <TransitionGroup name="list" tag="ul" class="divide-y divide-gray-100">
        <li v-for="(file, index) in modelValue" :key="file.id" class="transition-all duration-300">
          <!-- Dosya Başlık Satırı (her zaman görünür) -->
          <div class="flex items-center justify-between px-4 py-3 hover:bg-gray-50 cursor-pointer"
               @click="toggleExpand(file.id)">
            <div class="flex items-center space-x-3 overflow-hidden">
              <span class="material-symbols-outlined text-gray-400" v-if="isImage(file)">image</span>
              <span class="material-symbols-outlined text-gray-400" v-else-if="isVideo(file)">videocam</span>
              <span class="material-symbols-outlined text-gray-400" v-else-if="isPresentation(file)">slideshow</span>
              <span class="material-symbols-outlined text-gray-400" v-else>description</span>
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-gray-900 truncate" :title="file.name">{{ file.name }}</p>
                <p class="text-xs text-gray-500">{{ formatFileSize(file.file.size) }}</p>
              </div>
            </div>
            <div class="flex items-center space-x-1">
              <div class="z-50">
              <CustomButton @click.stop="removeFile(index)" size="xs" variant="danger" icon="delete" :isTextShowable="false" :show-icon="true" class="!p-1 z-50" />
              </div>
              <span class="material-symbols-outlined text-gray-400 transition-transform duration-300" :class="{'rotate-180': expandedFiles.includes(file.id)}">
                expand_more
              </span>
            </div>
          </div>

          <!-- Genişletilebilir İçerik (kategori ve dosya türü) -->
          <div v-if="expandedFiles.includes(file.id) || expandAll" class="px-4 py-3 bg-gray-50 border-t border-gray-100 grid grid-cols-1 md:grid-cols-2 gap-4">
            <!-- Kategori Seçimi -->
            <div>
              <label class="block text-xs font-medium text-gray-600 mb-1">Kategori <span class="text-red-500">*</span></label>
              <CustomSelect 
                v-model="file.categoryId" 
                :options="categoryOptions" 
                placeholder="Kategori seçin" 
                class="w-full"
                size="sm"
              />
            </div>
            
            <!-- Dosya Türü Seçimi -->
            <div>
              <label class="block text-xs font-medium text-gray-600 mb-1">Dosya Türü <span class="text-red-500">*</span></label>
              <CustomSelect 
                v-model="file.fileType" 
                :options="fileTypeOptions" 
                placeholder="Tür seçin" 
                class="w-full"
                size="sm"
              />
            </div>
            
            <!-- Açıklama Alanı -->
            <div class="md:col-span-2">
              <label class="block text-xs font-medium text-gray-600 mb-1">Açıklama (Opsiyonel)</label>
              <CustomInput 
                :model-value="file.description" 
                @update:model-value="updateDescription(index, $event)" 
                placeholder="Kısa bir açıklama girin..." 
                type="text" 
                size="sm"
              />
            </div>
          </div>
        </li>
      </TransitionGroup>

      <!-- Eksik alan uyarısı -->
      <div v-if="hasEmptyFields" class="px-4 py-2 bg-yellow-50 border-t border-yellow-100">
        <p class="text-xs text-yellow-700 flex items-center">
          <span class="material-symbols-outlined text-yellow-500 mr-1 text-sm">warning</span>
          Lütfen tüm dosyalar için kategori ve dosya türü seçin
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, toRefs, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid'
import { useAssetStore } from '@/stores/asset'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomSelect from '@/components/base/inputs/VSelect.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import {getTypesAll} from '../utils'
const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const { modelValue } = toRefs(props)
const assetStore = useAssetStore()

const fileInput = ref(null)
const isDragOver = ref(false)
const categoryOptions = ref([])
const isLoadingCategories = ref(false)
const expandedFiles = ref([])
const expandAll = ref(false)

// Dosya türleri için seçenekler
const fileTypeOptions =getTypesAll();

// Kategori veya dosya türü seçilmemiş dosyalar var mı kontrol et
const hasEmptyFields = computed(() => {
  return modelValue.value.some(file => !file.categoryId || !file.fileType)
})

const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB

const ALLOWED_FILE_TYPES = [
  'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain', 'application/rtf', 'image/jpeg', 'image/png', 'image/gif',
  'video/mp4', 'video/avi', 'video/quicktime',
  'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
]

// Kategorileri yükle (sadece 3 seviyeye kadar)
const fetchCategories = async () => {
  isLoadingCategories.value = true
  try {
    const response = await axios.get(settings.keywordCategories, {
      params: {
        asset_id: assetStore.assetId,
        level: 3,  // Maksimum 3 seviye kategori
        is_active: 1,
        isCustom: true,
      },
    })


    if (response?.data?.data?.data) {
      // Kategorileri select için formatlama
      categoryOptions.value = response.data.data.data.map(category => ({
        value: category.id,
        label: category.name
      }))
    }
  } catch (error) {
    responseHandlerService.handleError('Kategoriler yüklenirken bir hata oluştu')
    categoryOptions.value = []
  } finally {
    isLoadingCategories.value = false
  }
}

const formatFileSize = (size) => {
  if (size < 1024) return size + ' B'
  else if (size < 1048576) return (size / 1024).toFixed(1) + ' KB'
  else return (size / 1048576).toFixed(1) + ' MB'
}

const isValidFile = (file) => {
  if (!file || typeof file !== 'object') {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.invalidFile'))
    return false
  }
  if (!file.size || file.size > MAX_FILE_SIZE) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.fileTooLarge', { fileName: file.name, maxSize: formatFileSize(MAX_FILE_SIZE) }))
    return false
  }
  if (!file.type || !ALLOWED_FILE_TYPES.includes(file.type)) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.invalidFileType', { fileName: file.name }))
    return false
  }
  return true
}

const isImage = (file) => {
  return file && file.type && typeof file.type === 'string' && file.type.startsWith('image/')
}

const isVideo = (file) => {
  return file && file.type && typeof file.type === 'string' && file.type.startsWith('video/')
}

const isPresentation = (file) => {
  return file && file.file && file.file.name && 
    (file.file.name.endsWith('.ppt') || file.file.name.endsWith('.pptx') || 
     file.type === 'application/vnd.ms-powerpoint' || 
     file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation')
}

const handleDragEnter = (event) => {
  event.preventDefault()
  isDragOver.value = true
}

const handleDragLeave = (event) => {
  event.preventDefault()
  if (!event.currentTarget.contains(event.relatedTarget)) {
    isDragOver.value = false
  }
}

const handleFileUpload = (event) => {
  const newFiles = Array.from(event.target.files)
    .filter(isValidFile)
    .map(file => ({
      id: uuidv4(),
      file,
      name: file.name,
      description: '',
      categoryId: null,
      fileType: autoDetectFileType(file) || null,  // Otomatik dosya türü algılama
    }))
  if (newFiles.length === 0) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.noValidFiles'))
  } else {
    const updatedFiles = [...(modelValue.value || []), ...newFiles]
    emit('update:modelValue', updatedFiles)
  }
}

// Dosya türünü otomatik algılama
const autoDetectFileType = (file) => {
  if (file.type.startsWith('image/')) {
    return 'GÖRSEL'
  } else if (file.type.startsWith('video/')) {
    return 'VİDEO'
  } else if (file.type.includes('presentation') || file.name.endsWith('.ppt') || file.name.endsWith('.pptx')) {
    return 'SUNUM'
  } else {
    return 'DOSYA'  // Varsayılan tür
  }
}

const handleFileDrop = (event) => {
  event.preventDefault()
  isDragOver.value = false
  const newFiles = Array.from(event.dataTransfer.files)
    .filter(isValidFile)
    .map(file => ({
      id: uuidv4(),
      file,
      name: file.name,
      description: '',
      categoryId: null,
      fileType: autoDetectFileType(file) || null,
    }))
  if (newFiles.length === 0) {
    responseHandlerService.handleWarning(t('settings.components.configuration.components.documentModal.noValidFiles'))
  } else {
    const updatedFiles = [...(modelValue.value || []), ...newFiles]
    emit('update:modelValue', updatedFiles)
  }
}

const removeFile = (index) => {
  if (!modelValue.value) return
  const updatedFiles = [...modelValue.value]
  updatedFiles.splice(index, 1)
  emit('update:modelValue', updatedFiles)
}

const updateDescription = (index, newDescription) => {
  if (!modelValue.value) return
  const updatedFiles = [...modelValue.value]
  updatedFiles[index] = { ...updatedFiles[index], description: newDescription }
  emit('update:modelValue', updatedFiles)
}

// Dosya genişletme/daraltma fonksiyonu
const toggleExpand = (fileId) => {
  const index = expandedFiles.value.indexOf(fileId)
  if (index === -1) {
    expandedFiles.value.push(fileId)
  } else {
    expandedFiles.value.splice(index, 1)
  }
}

// Bileşen yüklendiğinde kategorileri getir
onMounted(() => {
  fetchCategories()
})
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>