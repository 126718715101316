<template>
  <div class="flex-grow overflow-auto p-6 thin-scrollbar">
    <div class="max-w-4xl mx-auto">
      <!-- Header -->
      <div class="flex items-center justify-between mb-8">
        <div>
          <h3 class="text-xl font-semibold text-gray-800">
            {{ t('settings.components.configuration.components.categoryModal.creator.title') }} </h3>
          <p class="text-sm text-gray-500 mt-1">
            {{ t('settings.components.configuration.components.categoryModal.creator.description') }} </p>
        </div>
      
      <!-- Action Buttons -->
      <div>
        <div class="flex items-center gap-3">
          <button @click="$emit('cancel')" class="text-gray-500 hover:text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-100 transition-all duration-300">
            {{ t('settings.components.configuration.components.categoryModal.creator.actions.cancel') }}
          </button>
          <VButton :button-text="t('settings.components.configuration.components.categoryModal.creator.actions.create')" icon="save" :show-icon="true" :is-loading="isSubmitting" :is-disabled="!hasValidCategories" @click="handleSave" class="hover:shadow-lg transform hover:-translate-y-0.5 transition-all duration-300"/>
        </div>
      </div>
      </div>

      <!-- Excel Tools -->
      <div class="mb-6 p-4 bg-gray-50 rounded-lg border border-gray-200">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-4">
            <button @click="handleDownloadTemplate" class="flex items-center gap-2 px-4 py-2 text-sm text-primary hover:bg-primary/10 rounded-lg transition-all duration-300">
              <span class="material-symbols-outlined">download</span> {{ t('settings.components.configuration.components.categoryModal.creator.excel.downloadTemplate') }}
            </button>

            <label class="flex items-center gap-2 px-4 py-2 text-sm text-primary hover:bg-primary/10 rounded-lg transition-all duration-300 cursor-pointer"> <span class="material-symbols-outlined">upload</span>
              {{ t('settings.components.configuration.components.categoryModal.creator.excel.uploadExcel') }} <input type="file" class="hidden" accept=".xlsx,.xls" @change="handleFileUpload"> </label>
          </div>

          <div class="text-xs text-gray-500">
            {{ t('settings.components.configuration.components.categoryModal.creator.excel.maxLevels', { count: maxLevel }) }}
          </div>
        </div>

        <!-- Excel Info Section -->
        <div v-if="showExcelInfo" class="mt-4 p-3 bg-white rounded-lg border border-primary/20">
          <div class="flex items-start gap-3">
            <span class="material-symbols-outlined text-primary mt-0.5">info</span>
            <div class="flex-grow">
              <p class="text-sm text-gray-600 mb-2">
                {{ t('settings.components.configuration.components.categoryModal.creator.excel.info.title') }} </p>
              <ul class="text-xs text-gray-500 space-y-1 list-disc ml-4">
                <li>{{ t('settings.components.configuration.components.categoryModal.creator.excel.info.format') }}</li>
                <li>{{ t('settings.components.configuration.components.categoryModal.creator.excel.info.levels', { count: maxLevel }) }}</li>
              </ul>
            </div>
            <button @click="showExcelInfo = false" class="text-gray-400 hover:text-gray-600">
              <span class="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Category Builder -->
      <div class="space-y-6">
        <CategoryLevel v-for="(category, index) in categories" :key="index" v-model:category="categories[index]" :current-level="1" :max-level="maxLevel" @remove="removeCategory(index)"/>

        <!-- Add Root Category Button -->
        <button @click="addCategory" class="w-full py-3 border-2 border-dashed border-gray-200 rounded-lg text-gray-400 hover:text-primary hover:border-primary/30 hover:bg-primary/5 transition-all duration-300 flex items-center justify-center gap-2">
          <span class="material-symbols-outlined">add_circle</span> <span>{{ t('settings.components.configuration.components.categoryModal.creator.actions.addRoot') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useUIStore } from '@/stores/ui'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import {
  downloadCategoryTemplate,
  parseCategoryExcel,
  validateCategoryStructure,
  flattenCategoryTree,
} from './utils/excelHelper'
import VButton from '@/components/base/buttons/VButton.vue'
import CategoryLevel from './CategoryLevel.vue'

const { t } = useI18n()
const props = defineProps({
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  maxLevel: {
    type: Number,
    default: 4,
  },
})

const emit = defineEmits(['save', 'cancel'])
const uiStore = useUIStore()

// States
const categories = ref([])
const showExcelInfo = ref(true)

// Computed
const hasValidCategories = computed(() => {
  return categories.value.length > 0 &&
      validateCategoryStructure(categories.value, props.maxLevel)
})

const currentLanguage = computed(() => uiStore.getCurrentLanguage)

// Methods
const handleDownloadTemplate = () => {
  try {
    downloadCategoryTemplate(currentLanguage.value)
  } catch (error) {
    responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.downloadTemplate'))
  }
}

const handleFileUpload = async (event) => {
  const file = event.target.files[0]
  if (!file) return
  try {
    const parsedData = await parseCategoryExcel(file)
    // Validate imported structure
    if (!validateCategoryStructure(parsedData.categories, props.maxLevel)) {
      throw new Error(t('settings.components.configuration.components.categoryModal.common.error.invalidStructure'))
    }

    categories.value = parsedData.categories
    
    responseHandlerService.handleSuccess(t('settings.components.configuration.components.categoryModal.common.success.imported'))
  } catch (error) {
    responseHandlerService.handleError(error.message)
  } finally {
    event.target.value = ''
  }
}

const addCategory = () => {
  categories.value.push({
    name: '',
    level: 1,
    children: [],
  })
}

const removeCategory = (index) => {
  categories.value.splice(index, 1)
}

const handleSave = () => {
  if (!hasValidCategories.value) return

  const flattenedCategories = flattenCategoryTree(categories.value)
  emit('save', {
    categories: flattenedCategories
  })
}
</script>