<template>
  <div class="bg-white rounded-lg p-6 shadow-sm h-full">
    <h3 class="text-lg font-semibold text-gray-800 mb-6 flex items-center">
      <span class="material-symbols-outlined text-primary mr-2">settings</span> {{ $t('socialMedia.components.createPost.contentOptions.title') }} </h3>

    <div class="space-y-4">
      <!-- Create New Content -->
      <div class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer" @click="$emit('new-content')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-primary/10 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-primary">add</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.newContent.title') }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.newContent.description') }}</p>
          </div>
        </div>
      </div>

      <!-- Create Trend Content -->
      <div class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer" @click="$emit('trend-content')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-accent/10 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-accent">trending_up</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.trendContent.title') }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.trendContent.description') }}</p>
          </div>
        </div>
      </div>

      <!-- Regenerate Content -->
      <div class="p-4 bg-primary/5 rounded-lg hover:bg-primary/10 transition-colors cursor-pointer" @click="$emit('regenerate')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-primary/15 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-primary">refresh</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.regenerate.title') }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.regenerate.description') }}</p>
          </div>
        </div>
      </div>

      <!-- Return to Previous Content -->
      <div v-if="hasPreviousContent" class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer" @click="$emit('restore-previous')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-amber-50 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-amber-600">history</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.restorePrevious.title') }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.restorePrevious.description') }}</p>
          </div>
        </div>
      </div>

      <!-- Edit Content Text -->
      <div class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer" @click="$emit('edit-text')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-blue-50 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-blue-600">edit</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.editText.title') }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.editText.description') }}</p>
          </div>
        </div>
      </div>

      <!-- Edit Hashtags -->
      <div class="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors cursor-pointer" @click="$emit('edit-hashtags')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-indigo-50 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-indigo-600">tag</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.editHashtags.title') }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.editHashtags.description') }}</p>
          </div>
        </div>
      </div>

      <!-- Copy All Content -->
      <div class="p-4 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors cursor-pointer" @click="$emit('copy-all')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-blue-600">content_copy</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.copyAll.title') || 'Tümünü Kopyala' }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.copyAll.description') || 'İçeriğin tamamını panoya kopyala' }}</p>
          </div>
        </div>
      </div>

      <!-- Copy Link -->
      <div v-if="hasLink" class="p-4 bg-purple-50 rounded-lg hover:bg-purple-100 transition-colors cursor-pointer" @click="$emit('copy-link')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-purple-100 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-purple-600">link</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.copyLink.title') || 'Bağlantıyı Kopyala' }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.copyLink.description') || 'İçerik bağlantısını panoya kopyala' }}</p>
          </div>
        </div>
      </div>

      <!-- Copy Video URL - New option -->
      <div v-if="hasVideo" class="p-4 bg-red-50 rounded-lg hover:bg-red-100 transition-colors cursor-pointer" @click="$emit('copy-video-url')">
        <div class="flex items-center">
          <div class="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center mr-3">
            <span class="material-symbols-outlined text-red-600">videocam</span>
          </div>
          <div>
            <h4 class="font-medium text-gray-800">{{ $t('socialMedia.components.createPost.contentOptions.copyVideoUrl.title') || 'Video URL Kopyala' }}</h4>
            <p class="text-sm text-gray-500">{{ $t('socialMedia.components.createPost.contentOptions.copyVideoUrl.description') || 'Video bağlantısını panoya kopyala' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  hasPreviousContent: {
    type: Boolean,
    default: false,
  },
  hasLink: {
    type: Boolean,
    default: false,
  },
  hasVideo: {
    type: Boolean,
    default: false,
  },
})

defineEmits([
  'new-content',
  'trend-content',
  'regenerate',
  'restore-previous',
  'edit-text',
  'edit-hashtags',
  'copy-all',
  'copy-link',
  'copy-video-url',
])
</script>