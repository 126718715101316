const env = process.env.VUE_APP_MODE

export const baseUrl = env === 'newProd' ? 'https://api.lobsterlead.com'
    : env === 'development'
        ? 'http://localhost:3000'
        : env === 'production'
            ? 'https://api.lobsterlead.com'
            : env === 'test' || !env
                ? 'https://api.lobster.softwareoptimize.com'
                : 'Invalid VUE_APP_MODE'

// Artık path'leri baseUrl olmadan tanımlıyoruz
export const auth = {
  signIn: '/users/signin',
  signUp: '/users/signup',
  logOut: '/users/signout',
  requestPasswordReset: '/users/forgot-password',
  resetPassword: '/users/reset-password',
  validateResetToken: '/users/validate-reset-token',
  confirmMail: '/users/confirm-mail',
  sendConfirmMail: '/users/send-confirm-mail',
  validateToken: '/users/validate-token',
}

export const users = {
  getAll: '/users',
  add: '/users',
  edit: '/users',
  delete: '/users/delete',
}

export const assets = {
  getAll: '/assets',
  get: '/assets/one',
  categories: '/assets/source-types',
  languages: '/assets/languages',
  countries: '/assets/countries',
  timezones: '/assets/timezones',
  info: '/assets/info',
  add: '/assets',
  edit: '/assets',
  customerTokenController: '/assets/token-controller',
  searchEngineLocation: '/assets/search-engine-location',
  addCrawler: '/assets/add-crawler',
  startCrawler: '/assets/start-crawler',
  checkSocialAuth: '/assets/platform-auth-status',
  getServiceDefinitions: '/assets/integration/services',
  integrationAddEntity: '/assets/integration/add-entity',
  integrationGenerateApiKey: '/assets/integration/generate-key',
  integrationVerifyApiKey: '/assets/integration/verify',
  integrationActivate: '/assets/integration/activate',
  integrationDeactivate: '/assets/integration/deactivate',
  suggestAboutUsWithAi: '/assets/suggestions/about-us',
  suggestKeywordsWithAi: '/assets/suggestions/keywords',
  changeAssetSetup: '/assets/change-asset-setup',
}

export const dashboard = {
  getSeoScores: '/dashboard/seo-scores',
  getKeywordAnalysis: '/dashboard/keyword-analysis',
  getKeywordMainKategory: '/dashboard/keyword-main-category',
  exportKeywords: '/dashboard/export-keywords',
  getTrendKeywords: '/dashboard/trend-keyword',
  getMonthlyKeywordPositions: '/dashboard/monthly-keyword-positions',
  getMonthlyKeywordVolumes: '/dashboard/monthly-keyword-volume',
  getKeywordEntity: '/dashboard/with-keyword',
  saveContentWithArticle: '/dashboard/save-content-article',
  competitorAnalysis: '/dashboard/competitor-analysis',
  competitorKeywords: '/dashboard/competitor-keywords',
  addCompetitor: '/dashboard/add-competitor',
  deleteCompetitor: '/dashboard/delete-competitor',
  getAnalyticsData: '/dashboard/get-analytics-data',
  getCategoryKeyword: '/dashboard/get-categories-related-keyword',
}

export const entity = {
  getAll: '/entities',
  add: '/entities',
  createContent: '/entities/content-and-image',
  createVideo: '/entities/create-video',
  createTrendContent: '/entities/trend-content',
  getDetail: '/entities/detail',
  assetContentEdit: '/entities',
  saveEditedContent: '/entities/save-edited-content',
  createSocialmediaContent: '/entities/create-social-media-content',
  revise: '/entities/revise',
  contentHistory: '/entities/content-history',
}

export const socialMedia = {
  socialAuth: '/social-media/authorize',
  socialOauth: '/social-media/auth/callback',
  checkSocialAuth: '/social-media/check-auth',
  disconnect: '/social-media/auth/disconnect',
  getPosts: '/social-media/posts',
  getPost: '/social-media/post',
  postGenerate: '/social-media/post/generate',
  savePost: '/social-media/post/save',
  publishPost: '/social-media/post/publish',
  commentsGetAll: '/social-media/comments',
  getProperty: '/social-media/get-property',
}

export const settings = {
  generalSettings: '/settings/general',
  scheduleSettings: '/settings/scheduling',
  assetConfiguration: '/settings/asset-configuration',
  sourceConfiguration: '/settings/source-configuration',
  getKeywordCategories: '/settings/all-categories',
  categoriesHierarchy: '/settings/categories-hierarchy',
  categories: '/assets/source-types',
  getPolicy: '/settings/get-policy',
  uploadDocumentFiles: '/settings/upload-document-files',
  getDocumentFiles: '/settings/get-document-files',
  deleteDocumentFiles: '/settings/delete-document-files',
  autoOptimize: '/entities/auto-optimize-contents',
  aiIframe: '/settings/assistant',
  getAnalyticProperties: '/settings/manage-integrations',
  saveManageIntegration: '/settings/save-manage-integrations',
  exportKeywords: '/settings/export-keywords',
  addBacklink: '/settings/add-backlink',
  editBacklink: '/settings/edit-backlink',
  deleteBacklink: '/settings/delete-backlink',
  getBacklinks: '/settings/get-backlinks',
  keywordCategories: '/settings/categories',
  subcategories: '/settings/subcategories',
  deleteCategory: '/settings/delete-custom-categories',
  customCategories: '/settings/custom-categories',
  exportKeywordTemplate: '/settings/export-templates',
  categoryPrompt: '/settings/category-prompt',
}

export const scheduleAssistant = {
  getContents: '/scheduling',
  getContent: '/scheduling/content-detail',
  refreshImage: '/scheduling/refresh/image',
  refreshContent: '/scheduling/refresh-content',
  confirmContent: '/scheduling',
  rejectContent: '/scheduling',
  toggleCreateContent: '/scheduling/toggle-create-content',
}

export const packages = {
  get: '/payment/packages',
  detail: '/payment/packages/detail',
  planDetail: '/payment/packages/plan/detail',
  oneTime: '/payment/one-time-packages',
  oneTimeType: '/payment/one-time-packages-type',
  purchase: '/payment/purchase',
  lobsPackages: '/payment/lobs-packages',
  extraLobs: '/payment/extra-lobs',
  futureDetail: '/payment/feature-lobs',
  upgrade: '/payment/upgrade-package',
}

export const payment = {
  start: '/payment/start',
  status: '/payment/check-status',
  paymentInformation: '/payment/payment-information',
  getExistingSubscription: '/payment/subscription',
}

export const memories = {
  getAll: '/memories',
  delete: '/memories',
}

export const genaralReporting = {
  keywordAnalysis: '/general-reporting/keyword-analysis',
  generatesKeywordCounts: '/general-reporting/generated-keyword-counts',
  assetsGetAll: '/general-reporting/assets/get/all',
  aiAnalysis: '/general-reporting/analysis/ai',
}