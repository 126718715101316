<template>
  <div class="mb-5">
    <h4 class="flex items-center gap-2 text-sm font-medium text-gray-700 mb-2">
      <span class="material-symbols-outlined text-red-500">videocam</span> {{ t('socialMedia.components.createPost.contentPreview.video') || 'Video İçeriği' }} </h4>

    <!-- Video Player -->
    <div class="relative rounded-lg overflow-hidden aspect-video shadow-sm mb-3">
      <video v-if="videoSrc" :src="videoSrc" controls class="w-full h-full object-cover"></video>
      <div v-else class="w-full h-full bg-gray-100 flex flex-col items-center justify-center p-4">
        <span class="material-symbols-outlined text-gray-400 !text-[48px] mb-3">smart_display</span>
        <p class="text-sm text-gray-500 text-center">
          {{ t('socialMedia.components.createPost.contentPreview.videoPlaceholder') || 'Video içeriği yayınlandığında gösterilecek' }} </p>
      </div>
    </div>

    <!-- Video Description -->
    <div v-if="description" class="p-3 bg-gray-50 rounded-lg border border-gray-200">
      <div class="flex items-center justify-between mb-1">
        <p class="text-sm font-medium text-gray-700">
          {{ t('socialMedia.components.createPost.contentPreview.videoDescription') || 'Video Açıklaması:' }} </p>
        <button @click="copyDescription" class="p-1 text-gray-500 hover:text-gray-700 rounded-full transition-colors">
          <span class="material-symbols-outlined !text-[16px]">content_copy</span>
        </button>
      </div>
      <p class="text-sm text-gray-600 whitespace-pre-line">{{ description }}</p>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  videoSrc: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['copy-description'])

const copyDescription = () => {
  emit('copy-description')
}
</script>