import { ref, computed } from 'vue'
import { filterActiveKeywords, createNormalizedKeywordMap, mapKeywordToTableFormat, sortKeywordsByPriorityAndName } from '../../utils/keywords/keywordStateUtils'

export function useKeywordState(props) {
  // Temel durum değişkenleri
  const selectedKeywords = ref([])
  const selectedKeyword = ref(null)
  const showEditModal = ref(false)

  // Tablo verisi
  const keywordsTableData = computed(() => {
    // 1. Aktif keywordleri filtrele
    const activeKeywords = filterActiveKeywords(props.keywords)

    // 2. Normalizasyon ve tekilleştirme
    const keywordMap = createNormalizedKeywordMap(activeKeywords)

    // 3. Map değerlerini tabloya uygun formata dönüştür
    const formattedKeywords = Array.from(keywordMap.values())
        .map(mapKeywordToTableFormat)

    // 4. Sırala ve döndür
    return sortKeywordsByPriorityAndName(formattedKeywords)
  })

  // Aktif keyword sayısı
  const activeKeywordsCount = computed(() => keywordsTableData.value.length)

  // Seçimi sıfırla
  const resetSelection = () => {
    selectedKeywords.value = []
    showEditModal.value = false
    selectedKeyword.value = null
  }

  return {
    selectedKeywords,
    selectedKeyword,
    showEditModal,
    keywordsTableData,
    activeKeywordsCount,
    resetSelection,
  }
}