import { defineStore } from 'pinia';

// Modal yönetimi için store
export const useVModalStore = defineStore('ui.modals', {
  state: () => ({
    // Aktif modalların listesi
    modals: [],
    // Modal sayacı (benzersiz ID'ler için)
    counter: 0
  }),
  
  getters: {
    // ID'ye göre modal bulma
    modalById: (state) => (id) => {
      return state.modals.find(modal => modal.id === id);
    },
    
    // Aktif modallar
    activeModals: (state) => {
      return state.modals.filter(m => m.isOpen);
    },
    
    // En üstte görünen modal
    topModal: (state) => {
      const activeModals = state.modals.filter(m => m.isOpen);
      if (!activeModals.length) return null;
      
      // En yüksek z-index değerine sahip modalı döndür
      return activeModals.reduce((highest, current) => {
        return current.zIndex > highest.zIndex ? current : highest;
      }, activeModals[0]);
    }
  },
  
  actions: {
    // Modal kaydet
    registerModal(payload) {
      // Eski API destekleniyor (string ID ile çağrılabilir)
      if (typeof payload === 'string' || payload === null || payload === undefined) {
        const id = payload || `modal-${this.counter++}`;
        const existingModal = this.modalById(id);
        
        if (existingModal) {
          return id;
        }
        
        this.modals.push({
          id,
          title: 'Modal',
          isOpen: false,
          isNested: false,
          zIndex: this.modals.length + 10
        });
        
        return id;
      }
      
      // Yeni API - object payload
      const { id, title, component, props } = payload;
      const modalId = id || `modal-${this.counter++}`;
      const existingModal = this.modalById(modalId);
      
      if (existingModal) {
        // Modal zaten var, güncelle
        if (title) existingModal.title = title;
        if (component) existingModal.component = component;
        if (props) existingModal.props = { ...existingModal.props, ...props };
        return modalId;
      }
      
      // Yeni modal ekle
      this.modals.push({
        id: modalId,
        title: title || 'Modal',
        component,
        props: props || {},
        isOpen: false,
        isNested: payload.isNested || false,
        zIndex: this.modals.length + 10 // Her yeni modal daha yüksek bir z-index alır
      });
      
      return modalId;
    },
    
    // Modal kaydını kaldır
    unregisterModal(id) {
      const index = this.modals.findIndex(m => m.id === id);
      if (index !== -1) {
        this.modals.splice(index, 1);
      }
    },
    
    // Modal aç
    openModal(id, props = {}) {
      const modal = this.modalById(id);
      if (!modal) return;
      
      // Props güncelle
      if (props) {
        modal.props = { ...modal.props, ...props };
      }
      
      // Bu modalı en üste çıkar
      const highestZIndex = Math.max(...this.modals.map(m => m.zIndex), 10);
      modal.zIndex = highestZIndex + 1;
      modal.isOpen = true;
      
      // İç içe modal olması durumunda üst modalların arka planlarını devre dışı bırak
      if (modal.isNested) {
        setTimeout(() => {
          const parentModals = document.querySelectorAll('.modal-backdrop');
          if (parentModals.length > 1) {
            // Son modal dışındaki tüm backdrop'ları devre dışı bırak
            for (let i = 0; i < parentModals.length - 1; i++) {
              parentModals[i].classList.add('pointer-events-none');
            }
          }
        }, 50);
      }
      
      // Sayfa scroll'unu engelle
      if (this.activeModals.length === 1) {
        document.body.style.overflow = 'hidden';
      }
      
      return modal;
    },
    
    // Modal kapat
    closeModal(id) {
      const modal = this.modalById(id);
      if (!modal) return;
      
      modal.isOpen = false;
      
      // Hiç açık modal kalmadıysa scroll'u geri aç
      if (this.activeModals.length === 0) {
        document.body.style.overflow = '';
      }
    },
    
    // Tüm modalları kapat
    closeAllModals() {
      this.modals.forEach(modal => {
        modal.isOpen = false;
      });
      
      document.body.style.overflow = '';
    }
  }
});

// Eskiye uyumlu store
export const useModalStore = defineStore('modal', {
  state: () => ({
    // Array of active modals with their IDs and states
    modals: [],
    // Counter for generating unique IDs
    modalCounter: 0
  }),
  
  getters: {
    // Get modal state by ID
    getModalById: (state) => (id) => {
      return state.modals.find(modal => modal.id === id);
    },
    
    // Get current top modal
    topModal: (state) => {
      if (!state.modals.length) return null;
      return state.modals[state.modals.length - 1];
    },
    
    // Count active modals
    modalCount: (state) => {
      return state.modals.length;
    }
  },
  
  actions: {
    // Register a new modal
    registerModal(id = null) {
      // If no ID is provided, generate one
      const modalId = id || `modal-${this.modalCounter++}`;
      
      // Check if modal already exists
      if (this.getModalById(modalId)) {
        console.warn(`Modal with ID ${modalId} already exists.`);
        return modalId;
      }
      
      // Add new modal to the stack
      this.modals.push({
        id: modalId,
        isOpen: false,
        zIndex: this.modals.length // zIndex based on position in stack
      });
      
      return modalId;
    },
    
    // Unregister a modal
    unregisterModal(id) {
      const index = this.modals.findIndex(modal => modal.id === id);
      if (index !== -1) {
        this.modals.splice(index, 1);
      }
    },
    
    // Open a modal
    openModal(id) {
      const modal = this.getModalById(id);
      
      if (!modal) {
        console.warn(`Cannot open modal with ID ${id}: not registered.`);
        return;
      }
      
      // Calculate new zIndex for this modal based on its position
      modal.zIndex = this.modals.length;
      
      // Set modal as open
      modal.isOpen = true;
      
      // Re-sort modals to put this one at the end (top)
      const currentIndex = this.modals.findIndex(m => m.id === id);
      if (currentIndex !== -1) {
        // Move to the end
        const tempModal = { ...this.modals[currentIndex] };
        this.modals.splice(currentIndex, 1); // Remove from current position
        this.modals.push(tempModal); // Add to the end
      }
    },
    
    // Close a modal
    closeModal(id) {
      const modal = this.getModalById(id);
      
      if (!modal) {
        console.warn(`Cannot close modal with ID ${id}: not registered.`);
        return;
      }
      
      // Set modal as closed
      modal.isOpen = false;
    },
    
    // Close all modals
    closeAllModals() {
      this.modals.forEach(modal => {
        modal.isOpen = false;
      });
    }
  }
});
