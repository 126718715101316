import { KeywordUtils } from '@/utils/keywordUtils'
import { validateAndNormalizePriority } from './priorityUtils'

export function processKeyword(data, existingKeywords) {
  const normalizedKeyword = KeywordUtils.normalize(data.keyword)
  const priorityResult = validateAndNormalizePriority(data.priority)

  if (!normalizedKeyword) {
    return { type: 'invalid', reason: 'invalid_keyword_format' }
  }

  const existing = findExistingKeyword(normalizedKeyword, existingKeywords)
  if (existing) {
    return updateExistingKeyword(existing, priorityResult, data.categoryIds, existingKeywords)
  }

  const deleted = findDeletedKeyword(normalizedKeyword, existingKeywords)
  if (deleted) {
    return reactivateKeyword(deleted, priorityResult, data.categoryIds, existingKeywords)
  }

  return createNewKeyword(normalizedKeyword, priorityResult, data.categoryIds, existingKeywords)
}

export function validateRow(row, validCategoryIds) {
  if (!Array.isArray(row) || row.length === 0) {
    return { valid: false, reason: 'empty_row' }
  }

  const keyword = row[0]?.toString().trim()
  if (!keyword) {
    return { valid: false, reason: 'empty_keyword' }
  }

  const priority = (!row[1] || row[1] === '') ? null : row[1]
  const categoryResult = parseCategoryIds(row[2], validCategoryIds)

  // Eğer hiç geçerli kategori yoksa ve kategori girilmeye çalışılmışsa
  if (categoryResult.invalidIds.length > 0 && categoryResult.validCategories.length === 0) {
    return {
      valid: false,
      reason: 'invalid_categories',
      invalidIds: categoryResult.invalidIds,
    }
  }

  return {
    valid: true,
    data: {
      keyword,
      priority,
      categoryIds: categoryResult.validCategories,
      invalidCategoryIds: categoryResult.invalidIds,
    },
  }
}

export function parseCategoryIds(categoryIdsInput, validCategoryIds) {
  if (!categoryIdsInput || categoryIdsInput.toString().trim() === '') {
    return {
      validCategories: [],
      invalidIds: [],
    }
  }

  const validCategories = []
  const invalidIds = []

  // Hem virgül hem nokta ile ayırma
  const segments = categoryIdsInput.toString().trim().split(/[,.]/)

  segments.forEach(segment => {
    const trimmedId = segment.trim()
    if (trimmedId) {
      const numericId = Number(trimmedId)
      if (!isNaN(numericId) && numericId > 0) {
        if (validCategoryIds.has(numericId.toString())) {
          validCategories.push({
            id: null,  // Yeni eklendiği için henüz DB id'si yok
            categoryId: numericId,  // Excel'den gelen category id değeri
            state: 2,   // Yeni eklenen kategori ilişkisi
          })
        } else {
          invalidIds.push(trimmedId)
        }
      } else if (trimmedId !== '') {
        invalidIds.push(trimmedId)
      }
    }
  })

  return {
    validCategories,
    invalidIds,
  }
}

export function findExistingKeyword(keyword, existingKeywords) {
  return existingKeywords.find(k =>
      k.state !== 3 && k.name.toLowerCase() === keyword.toLowerCase(),
  )
}

export function findDeletedKeyword(keyword, existingKeywords) {
  return existingKeywords.find(k =>
      k.state === 3 && k.name.toLowerCase() === keyword.toLowerCase(),
  )
}

export function updateExistingKeyword(existing, priorityResult, categoryIds, existingKeywords) {
  const updatedCategories = updateCategoryRelations(existing.categoryIds || [], categoryIds)

  const hasChanges =
      existing.priority !== priorityResult.normalized ||
      JSON.stringify(updatedCategories) !== JSON.stringify(existing.categoryIds || [])

  if (!hasChanges) {
    return { type: 'unchanged', keyword: existing }
  }

  const updated = {
    ...existing,
    priority: priorityResult.normalized,
    categoryIds: updatedCategories,
    state: existing.state === 1 ? 4 : existing.state,
  }

  const index = existingKeywords.findIndex(k => k.name === existing.name)
  if (index !== -1) {
    existingKeywords[index] = updated
  }

  return {
    type: 'updated',
    keyword: updated,
    priorityChange: priorityResult,
  }
}

export function reactivateKeyword(keyword, priorityResult, categoryIds, existingKeywords) {
  const reactivated = {
    ...keyword,
    state: 4,
    priority: priorityResult.normalized,
    categoryIds: categoryIds.map(category => ({
      id: null,
      categoryId: category.categoryId,
      state: 2,
    })),
  }

  const index = existingKeywords.findIndex(k => k.name === keyword.name)
  if (index !== -1) {
    existingKeywords[index] = reactivated
  }

  return {
    type: 'reactivated',
    keyword: reactivated,
    priorityChange: priorityResult,
  }
}

export function createNewKeyword(keyword, priorityResult, categoryIds, existingKeywords) {
  const newKeyword = {
    name: keyword,
    state: 2,
    priority: priorityResult.normalized,
    categoryIds: categoryIds.map(category => ({
      id: null,
      categoryId: category.categoryId,
      state: 2,
    })),
  }

  existingKeywords.push(newKeyword)

  return {
    type: 'new',
    keyword: newKeyword,
    priorityChange: priorityResult,
  }
}

export function updateCategoryRelations(existingCategories = [], newCategories = []) {
  const updatedCategories = [...(existingCategories || [])]
  const existingCategoryMap = createCategoryMap(updatedCategories)

  processNewCategories(newCategories, existingCategoryMap, updatedCategories)
  markRemovedCategories(updatedCategories, new Set(newCategories.map(cat => String(cat?.categoryId))))

  return updatedCategories
}

function createCategoryMap(categories) {
  const map = new Map()
  categories.forEach(cat => {
    if (cat?.categoryId !== undefined) {
      map.set(String(cat.categoryId), cat)
    }
  })
  return map
}

function processNewCategories(newCategories, existingMap, updatedCategories) {
  newCategories.forEach(newCat => {
    if (!newCat?.categoryId) return

    const existingCat = existingMap.get(String(newCat.categoryId))
    if (existingCat) {
      if (existingCat.state === 3) {
        existingCat.state = 1
      }
    } else {
      updatedCategories.push({ ...newCat, state: 2 })
    }
  })
}

function markRemovedCategories(categories, newIds) {
  categories.forEach(cat => {
    if (cat?.categoryId !== undefined && !newIds.has(String(cat.categoryId)) && cat.state !== 3) {
      cat.state = 3
    }
  })
}