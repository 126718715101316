<template>
  <div class="flex items-center">
    <!-- Kategori yoksa -->
    <span v-if="!categories || categories.length === 0" class="text-gray-400 text-sm">-</span>

    <!-- Kategoriler varsa -->
    <div v-else class="flex items-center">
      <!-- İlk kategori adı görüntülenir -->
      <span class="text-sm truncate max-w-48">{{ categories[0].categoryName }}</span>

      <!-- Birden fazla kategori varsa sayı gösterilir -->
      <span v-if="categories.length > 1" class="text-xs text-gray-500 ml-1">
        +{{ categories.length - 1 }}
      </span>

      <!-- Birden fazla kategori varsa tıklanabilir bilgi ikonu gösterilir -->
      <div v-if="categories.length > 1" class="ml-1">
        <VTooltip :title="fullCategoryList" placement="bottom" color="secondary" container-class="flex items-center" information :information-title="t('settings.components.configuration.components.keywordsTable.categories')">
          <template #content>
            <span class="material-symbols-outlined !text-[16px] text-primary cursor-pointer ">info</span>
          </template>
        </VTooltip>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import VTooltip from '@/components/common/VTooltip.vue'

const props = defineProps({
  categories: {
    type: Array,
    default: () => [],
  },
})

const { t } = useI18n()

// Tooltip için tüm kategorilerin listesi
const fullCategoryList = computed(() => {
  if (!props.categories || props.categories.length === 0) {
    return ''
  }

  return props.categories.map(category => category.categoryName).join('\n')
})
</script>