/**
 * Yeni eklenecek keywordlerin limit kontrolünü yapar
 * @param {number} currentCount - Mevcut keyword sayısı
 * @param {number} newCount - Yeni eklenecek keyword sayısı
 * @param {number} maxKeywords - Maksimum keyword sayısı
 * @returns {boolean} Limit aşılmış mı?
 */
export function isKeywordLimitExceeded(currentCount, newCount, maxKeywords) {
  return currentCount + newCount > maxKeywords
}

/**
 * Geçersiz keywordleri bulur
 * @param {Array} keywords - Kontrol edilecek keyword dizisi
 * @returns {Array} Geçersiz keywordler
 */
export function findInvalidKeywords(keywords) {
  return keywords.filter(keyword => keyword.name.trim().length < 2)
}

/**
 * Bir keyword'ün sistemde mevcut olup olmadığını kontrol eder
 * @param {Object} newKeyword - Yeni keyword
 * @param {Array} existingKeywords - Mevcut keyword dizisi
 * @returns {Object|undefined} Mevcut keyword veya undefined
 */
export function findExistingKeyword(newKeyword, existingKeywords) {
  return existingKeywords
      .filter(keyword => keyword.state !== 3)
      .find(keyword => keyword.name.trim() === newKeyword.name.trim())
}

/**
 * Yeni eklenecek keyword'ü formatlar
 * @param {Object} keywordData - Keyword verisi
 * @returns {Object} Formatlanmış keyword
 */
export function formatNewKeyword(keywordData) {
  return {
    name: keywordData.name.trim(),
    priority: keywordData.priority,
    categoryIds: (keywordData.categoryIds || []).map(category => ({
          id: category.id || null,
          categoryId: category.categoryId,
          state: 2, // Yeni eklenenler için state 2
        }
    )),
    state: 2, // Yeni eklenen keyword
  }
}

/**
 * Yeni keywordleri işler ve sonuçları hesaplar
 * @param {Array} newKeywords - Yeni keyword dizisi
 * @param {Array} existingKeywords - Mevcut keyword dizisi
 * @returns {Object} Sonuç ve güncellenmiş keyword dizisi
 */
export function processNewKeywords(newKeywords, existingKeywords) {
  const updatedKeywords = [...existingKeywords]
  const results = { new: 0, reactivated: 0, updated: 0, unchanged: 0 }

  newKeywords.forEach(newKeyword => {
    const existingKeyword = findExistingKeyword(newKeyword, updatedKeywords)

    if (existingKeyword) {
      // Zaten varsa değiştirme
      results.unchanged++
    } else {
      // Yeni keyword ekle
      updatedKeywords.push(formatNewKeyword(newKeyword))
      results.new++
    }
  })

  return { updatedKeywords, results }
}