import { responseHandlerService } from '@/plugins/responseHandlerService'
import { processSelectedKeywords } from '../../utils/keywords/keywordSelectionUtils'

export function useKeywordSelection(props, emit, t, { selectedKeywords }) {
  /**
   * Seçim değişikliklerini işler
   * @param {Array} newSelection - Yeni seçim dizisi
   */
  const handleSelectionChange = (newSelection) => {
    selectedKeywords.value = newSelection
  }

  /**
   * Seçili keywordleri toplu olarak siler
   */
  const handleBulkDelete = () => {
    // Seçili keyword yoksa çık
    if (!selectedKeywords.value.length) return

    // Seçili keywordleri işle
    const updatedKeywords = processSelectedKeywords(
        props.keywords,
        selectedKeywords.value,
    )

    // Değişiklikleri emit et
    emit('update:keywords', updatedKeywords)

    // Başarı mesajı göster
    showSuccessMessage(selectedKeywords.value.length)

    // Seçimi sıfırla
    selectedKeywords.value = []
  }

  /**
   * Başarı mesajı gösterir
   * @param {number} count - Silinen keyword sayısı
   */
  const showSuccessMessage = (count) => {
    responseHandlerService.successHandler(
        t(
            'settings.components.configuration.components.keywordsTable.bulkDeleteSuccess',
            { count },
        ),
    )
  }

  return {
    handleSelectionChange,
    handleBulkDelete,
  }
}