<template>
  <div class="bg-white rounded-lg p-6 shadow-sm">
    <!-- Navigation -->
    <div class="flex items-center justify-between mb-6">
      <button @click="$emit('back-to-trends')" class="flex items-center gap-1 text-sm text-primary hover:text-primary-700 transition-colors">
        <span class="material-symbols-outlined !text-[18px]">arrow_back</span> {{ $t('socialMedia.components.createPost.general.backToTrends') }}
      </button>

      <button @click="$emit('select-mode', 'welcome')" class="flex items-center gap-1 text-sm text-gray-500 hover:text-primary transition-colors">
        <span class="material-symbols-outlined !text-[18px]">home</span> <span class="hidden sm:inline">{{ $t('socialMedia.components.createPost.general.home') }}</span>
      </button>
    </div>

    <!-- Trend Information -->
    <div class="bg-gray-50 rounded-lg p-5 mb-6">
      <div class="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <div class="flex-grow">
          <div class="flex items-center">
            <span class="inline-block w-3 h-3 bg-green-500 rounded-full mr-2"></span>
            <h2 class="text-xl font-bold text-gray-800">{{ trend?.name }}</h2>
          </div>

          <div class="flex items-center gap-2 mt-2">
            <span class="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
              +{{ trend.increasePercentage }}%
            </span> <span class="text-gray-500 text-sm">
              {{ formatTimeDisplay(trend.startTimestamp) }}
            </span>
          </div>
        </div>

        <div class="shrink-0">
          <div class="flex items-center justify-center bg-primary/10 px-4 py-2 rounded-full">
            <span class="mr-2 text-primary font-semibold">{{ formatVolumeDisplay(trend.volume) }}</span> <span class="text-xs text-primary/70">{{
              $t('socialMedia.components.createPost.trends.volume')
            }}</span>
          </div>
        </div>
      </div>

      <!-- News URL link - if available -->
      <div v-if="trend?.newsUrl" class="mt-4 p-3 bg-white rounded-lg border border-gray-100">
        <a :href="trend.newsUrl" target="_blank" rel="noopener noreferrer" class="flex items-center gap-2 text-primary hover:text-primary/80 hover:underline">
          <span class="material-symbols-outlined !text-[18px]">link</span> <span class="truncate">{{ extractDomain(trend.newsUrl) }}</span>
          <span class="material-symbols-outlined ml-auto text-gray-400 !text-[16px]">open_in_new</span> </a>
      </div>
    </div>

    <!-- Content Creation Settings -->
    <div class="mb-6">
      <h3 class="text-lg font-semibold text-gray-800 mb-4">{{ $t('socialMedia.components.createPost.trendDetail.settings') }}</h3>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <!-- Sol Kolon -->
        <div class="space-y-5">
          <!-- Platform Selection -->
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2"> {{ $t('socialMedia.components.createPost.trendDetail.platforms') }} </label>
            <div class="flex flex-wrap gap-3">
              <button v-for="platform in platforms" :key="platform.id" @click="togglePlatform(platform.id)" type="button" :class="[
                  'flex items-center gap-2 px-4 py-2 rounded-md text-sm font-medium transition-all',
                  selectedPlatforms.includes(platform.id)
                    ? 'bg-primary text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
                ]">
                <img :src="platform.icon" :alt="platform.name" class="w-4 h-4" :class="selectedPlatforms.includes(platform.id) ? 'brightness-0 invert' : ''"/> {{ platform.name }}
              </button>
            </div>
          </div>

          <!-- Language Selection -->
          <div>
            <LanguageSelector v-model="languageId" :label="$t('socialMedia.components.createPost.trendDetail.language')" :additionalInputClass="'bg-white rounded-md shadow-sm'"/>
          </div>

          <!-- Additional Keywords -->
          <div>
            <TagInput v-model="additionalKeywords" :label="$t('socialMedia.components.createPost.trendDetail.keywords')" :description="$t('socialMedia.components.createPost.trendDetail.keywordsDescription')" :maxCount="10" :showSearchandFileUpload="false" color="primary" containerClass="bg-white rounded-md shadow-sm"/>
          </div>
        </div>

        <!-- Sağ Kolon -->
        <div class="space-y-5">
          <!-- Content Format Selection -->
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2"> {{ $t('socialMedia.components.createPost.trendDetail.contentFormat') }} </label>
            <div class="flex flex-wrap gap-3">
              <button type="button" v-for="ratio in aspectRatios" :key="ratio.id" @click="selectAspectRatio(ratio.id)" :class="[
                  'relative flex items-center gap-2 px-6 py-3 rounded-md text-sm font-medium transition-all',
                  selectedAspectRatio === ratio.id
                    ? 'bg-primary text-white'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200',
                ]">
                <div class="aspect-ratio-box" :class="ratio.boxClass">
                  <div class="flex items-center justify-center p-1 w-full h-full rounded" :class="selectedAspectRatio === ratio.id ? 'bg-white/20' : 'bg-gray-200'">
                    {{ ratio.label }}
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- Video Content Options -->
          <div class="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <div class="flex items-center mb-3">
              <VSwitch v-model="hasVideo" color="primary">
                <template #label-right>
                  <span class="ml-2 block text-sm font-medium text-gray-700">
                    {{ $t('socialMedia.components.createPost.trendDetail.includeVideo') || 'Video içeriği ekle' }}
                  </span>
                </template>
              </VSwitch>
            </div>

            <div class="mt-3">
              <VInput type="textarea" v-model="videoDescription" :disabled="!hasVideo" :label="$t('socialMedia.components.createPost.trendDetail.videoDescription') || 'Video Açıklaması'" :description="$t('socialMedia.components.createPost.trendDetail.videoDescriptionHelp') || 'Trend ile ilgili videoda ne olacağını açıklayın'" :placeholder="$t('socialMedia.components.createPost.trendDetail.videoDescriptionPlaceholder') || 'Videoda gösterilecek içeriği anlatın'" :error="videoDescriptionError" textAreaHeight="h-28" size="md"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Action button -->
    <div class="mt-8">
      <VButton :button-text="$t('socialMedia.components.createPost.trendDetail.generateButton')" icon="auto_awesome" :show-icon="true" :is-loading="false" :is-disabled="!canGenerate" size="lg" color="primary" variation="solid" :full-width="true" @click="generateContent"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrends } from '../composables/useTrends'
import { useAssetStore } from '@/stores/asset'
import LanguageSelector from '@/components/base/inputs/VLanguageSelector.vue'
import TagInput from '@/components/base/inputs/VTagInput.vue'
import VButton from '@/components/base/buttons/VButton.vue'
import VSwitch from '@/components/base/inputs/VSwitch.vue'
import VInput from '@/components/base/inputs/VInput.vue'

const { t } = useI18n()
const { formatTimeDisplay, formatVolumeDisplay } = useTrends()
const assetStore = useAssetStore()

const props = defineProps({
  trend: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits([
  'back-to-trends',
  'select-mode',
  'generate-from-trend',
])

// State management
const languageId = ref(assetStore.assetLanguage)
const selectedPlatforms = ref([])
const selectedAspectRatio = ref(3) // Default to 16:9
const additionalKeywords = ref([])
const hasVideo = ref(false)
const videoDescription = ref('')
const videoDescriptionError = ref('')

// Videoya bağlı videoDescription validasyonu
watch(hasVideo, (newValue) => {
  if (newValue && !videoDescription.value) {
    videoDescriptionError.value = t('socialMedia.components.createPost.trendDetail.videoDescriptionRequired') || 'Video açıklaması zorunludur'
  } else {
    videoDescriptionError.value = ''
  }
})

watch(videoDescription, (newValue) => {
  if (hasVideo.value && !newValue) {
    videoDescriptionError.value = t('socialMedia.components.createPost.trendDetail.videoDescriptionRequired') || 'Video açıklaması zorunludur'
  } else {
    videoDescriptionError.value = ''
  }
})

const platforms = [
  { id: 1, name: 'Instagram', icon: require('@/assets/icons/instagram.svg') },
  { id: 2, name: 'Facebook', icon: require('@/assets/icons/facebook.svg') },
  { id: 3, name: 'TwitterX', icon: require('@/assets/icons/twitterx.svg') },
]

const aspectRatios = [
  { id: 1, label: '1:1', boxClass: 'w-6 h-6' },
  { id: 2, label: '9:16', boxClass: 'w-5 h-8' },
  { id: 3, label: '16:9', boxClass: 'w-8 h-5' },
]

// Computed properties
const canGenerate = computed(() => {
  return selectedPlatforms.value.length > 0 &&
      languageId.value &&
      selectedAspectRatio.value &&
      (!hasVideo.value || (hasVideo.value && videoDescription.value.trim().length > 0))
})

// Methods
const togglePlatform = (platformId) => {
  const index = selectedPlatforms.value.indexOf(platformId)
  if (index === -1) {
    selectedPlatforms.value.push(platformId)
  } else {
    selectedPlatforms.value.splice(index, 1)
  }
}

const selectAspectRatio = (ratioId) => {
  selectedAspectRatio.value = ratioId
}

const generateContent = () => {
  if (!canGenerate.value) return

  if (hasVideo.value && !videoDescription.value.trim()) {
    videoDescriptionError.value = t('socialMedia.components.createPost.trendDetail.videoDescriptionRequired') || 'Video açıklaması zorunludur'
    return
  }

  // Add trend name to keywords array
  const keywords = [props.trend.name]

  // Add additional keywords to the keywords array
  if (additionalKeywords.value.length > 0) {
    additionalKeywords.value.forEach(keyword => {
      if (keyword && keyword.name) {
        keywords.push(keyword.name)
      } else if (typeof keyword === 'string') {
        keywords.push(keyword)
      }
    })
  }

  emit('generate-from-trend', {
    trend: props.trend,
    selectedPlatforms: selectedPlatforms.value,
    selectedAspectRatio: selectedAspectRatio.value,
    languageId: languageId.value,
    keywords: keywords,
    hasVideo: hasVideo.value,
    videoDescription: hasVideo.value ? videoDescription.value : null,
  })
}

const extractDomain = (url) => {
  try {
    const domain = new URL(url).hostname
    return domain.replace('www.', '')
  } catch (e) {
    return url
  }
}
</script>