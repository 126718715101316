<template>
  <div class="bg-white rounded-lg shadow-md border border-gray-100 overflow-hidden flex flex-col h-full">
    <!-- Header -->
    <div class="flex justify-between items-center p-4 border-b border-gray-100 bg-gray-50">
      <h3 class="text-lg font-semibold text-gray-800 flex items-center gap-2">
        <span class="material-symbols-outlined text-primary">visibility</span> {{ t('socialMedia.components.createPost.contentPreview.title') }} </h3>
      <div class="flex space-x-2">
        <button v-if="generatedContent && !isGenerating && !isSaved" @click="$emit('save-content')" class="flex items-center gap-1 px-4 py-2 bg-primary text-white text-sm font-medium rounded-md hover:bg-primary-dark transition-colors" :disabled="isSaving">
          <span v-if="isSaving" class="animate-spin">
            <span class="material-symbols-outlined !text-[16px]">progress_activity</span>
          </span> <span v-else class="material-symbols-outlined !text-[16px]">save</span>
          {{ isSaving ? t('socialMedia.components.createPost.general.saving') : t('socialMedia.components.createPost.general.save') }}
        </button>
        <button v-if="generatedContent && canPublish" @click="$emit('publish-content')" class="flex items-center gap-1 px-4 py-2 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 transition-colors" :disabled="isPublishing">
          <span v-if="isPublishing" class="animate-spin">
            <span class="material-symbols-outlined !text-[16px]">progress_activity</span>
          </span> <span v-else class="material-symbols-outlined !text-[16px]">send</span>
          {{ isPublishing ? t('socialMedia.components.createPost.general.publishing') : t('socialMedia.components.createPost.general.publish') }}
        </button>
      </div>
    </div>

    <!-- Content Area -->
    <div class="flex-grow overflow-y-auto thin-scrollbar p-4">
      <!-- Loading State -->
      <div v-if="isGenerating" class="flex flex-col items-center justify-center h-64 text-center">
        <div class="w-14 h-14 rounded-full bg-primary/10 flex items-center justify-center mb-4">
          <span class="material-symbols-outlined !text-[24px] text-primary animate-pulse">auto_awesome</span>
        </div>
        <p class="text-gray-600 font-medium mb-2">{{ t('socialMedia.components.createPost.contentPreview.loading') }}</p>
        <p class="text-sm text-gray-500">{{ t('socialMedia.components.createPost.contentPreview.loadingDescription') }}</p>
      </div>

      <!-- Empty State -->
      <div v-else-if="!generatedContent" class="flex flex-col items-center justify-center h-64 text-center">
        <div class="w-14 h-14 rounded-full bg-gray-100 flex items-center justify-center mb-4">
          <span class="material-symbols-outlined !text-[24px] text-gray-400">content_paste</span>
        </div>
        <p class="text-gray-600 font-medium mb-2">{{ t('socialMedia.components.createPost.contentPreview.empty.title') }}</p>
        <p class="text-sm text-gray-500">{{ t('socialMedia.components.createPost.contentPreview.empty.description') }}</p>
      </div>

      <!-- Content Display -->
      <div v-else>
        <!-- Platform Info -->
        <div class="mb-5">
          <PlatformInfo :platforms="selectedPlatforms"/>
        </div>

        <!-- Content Text Card -->
        <div class="mb-5">
          <ContentTextCard :message="generatedContent.message" :hashtags="generatedContent.hashtags" @copy-text="copyText" @copy-hashtags="copyHashtags"/>
        </div>

        <!-- Video Preview (If Any) -->
        <VideoPreview v-if="hasVideo" :videoSrc="videoSrc" :description="videoDescription" @copy-description="copyVideoDescription" class="mb-5"/>

        <!-- Media Preview (If Any) -->
        <MediaPreview v-if="hasMedia" :mediaItems="generatedContent.media" class="mb-5"/>

        <!-- Link Preview (If Any) -->
        <LinkPreview v-if="hasLink" :link="generatedContent.link" :isVideoLink="isVideoLink" :isImageLink="isImageLink" :videoSrc="isVideoLink ? videoSrc : null" :hasLinkPreview="hasLinkPreview" :linkPreview="generatedContent.linkPreview" @copy-link="copyLink" class="mb-4"/>
      </div>
    </div>

    <!-- Stats (Published Content) -->
    <ContentStats v-if="isSaved || isPublished" :isSaved="isSaved" :isPublished="isPublished" :stats="analytics"/>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useClipboard } from '../../composables/useClipboard'
import PlatformInfo from './components/PlatformInfo.vue'
import ContentTextCard from './components/ContentTextCard.vue'
import VideoPreview from './components/VideoPreview.vue'
import MediaPreview from './components/MediaPreview.vue'
import LinkPreview from './components/LinkPreview.vue'
import ContentStats from './components/ContentStats.vue'

const { t } = useI18n()
const clipboard = useClipboard()

const props = defineProps({
  generatedContent: {
    type: Object,
    default: () => null,
  },
  selectedPlatforms: {
    type: Array,
    required: true,
    default: () => [],
  },
  isGenerating: {
    type: Boolean,
    default: false,
  },
  isSaving: {
    type: Boolean,
    default: false,
  },
  isPublishing: {
    type: Boolean,
    default: false,
  },
  isSaved: {
    type: Boolean,
    default: false,
  },
  isPublished: {
    type: Boolean,
    default: false,
  },
  analytics: {
    type: Object,
    default: () => ({
      views: 0,
      likes: 0,
      comments: 0,
      shares: 0,
    }),
  },
})

const emit = defineEmits([
  'edit-content-text',
  'edit-hashtags',
  'save-content',
  'publish-content',
])

// Computed properties
const canPublish = computed(() => props.isSaved && !props.isPublished && !props.isPublishing)

const hasMedia = computed(() => {
  return props.generatedContent &&
      props.generatedContent.media &&
      props.generatedContent.media.length > 0
})

// Link ile ilgili computed değerler
const hasLink = computed(() => {
  return props.generatedContent &&
      props.generatedContent.link &&
      typeof props.generatedContent.link === 'string' &&
      props.generatedContent.link.trim() !== ''
})

const isImageLink = computed(() => {
  if (!hasLink.value) {
    return false
  }

  const link = props.generatedContent.link.toLowerCase()

  // Resim uzantılarını kontrol et
  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg', '.bmp']
  const hasImageExtension = imageExtensions.some(ext => link.endsWith(ext))

  // CDN veya resim yollarını kontrol et
  const isImagePath = link.includes('/image/') ||
      link.includes('/images/') ||
      link.includes('/cdn/image/') ||
      link.includes('estethica.com.tr/cdn/image')

  return hasImageExtension || isImagePath
})

const isVideoLink = computed(() => {
  if (!hasLink.value) {
    return false
  }

  const link = props.generatedContent.link.toLowerCase()

  // Video uzantılarını kontrol et
  const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.wmv']
  const hasVideoExtension = videoExtensions.some(ext => link.endsWith(ext))

  // Video hosting servislerini kontrol et
  const isVideoHosting = link.includes('youtube.com/watch') ||
      link.includes('youtu.be/') ||
      link.includes('vimeo.com/') ||
      link.includes('/video/') ||
      link.includes('/videos/') ||
      link.includes('tiktok.com')

  return hasVideoExtension || isVideoHosting
})

const hasVideo = computed(() => {
  // Check if the content has a dedicated video flag or media with video type
  return props.generatedContent &&
      (props.generatedContent.hasVideo ||
          isVideoLink.value ||
          (props.generatedContent.media && props.generatedContent.media.some(m => isVideo(m))))
})

// Video kaynağı için birleştirilmiş computed
const videoSrc = computed(() => {
  if (!props.generatedContent) return null

  // 1. Özel video URL'si varsa onu kullan
  if (props.generatedContent.videoUrl) return props.generatedContent.videoUrl

  // 2. Link bir video ise onu kullan
  if (isVideoLink.value) return props.generatedContent.link

  // 3. Media içinde video varsa onu kullan
  if (props.generatedContent.media) {
    const videoMedia = props.generatedContent.media.find(m => isVideo(m))
    if (videoMedia) return videoMedia.url || videoMedia.preview
  }

  return null
})

const videoDescription = computed(() => {
  return props.generatedContent?.videoDescription || null
})

const hasLinkPreview = computed(() => {
  return props.generatedContent &&
      props.generatedContent.linkPreview &&
      props.generatedContent.linkPreview.image
})

// Helper methods - Video detection
const isVideo = (media) => {
  if (!media) return false

  if (media.type && media.type.includes('video')) {
    return true
  }

  // Check if URL ends with common video extensions
  const url = media.url || media.preview || ''
  const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.wmv']
  return videoExtensions.some(ext => url.toLowerCase().endsWith(ext))
}

// Copy methods
const copyText = () => {
  if (props.generatedContent) {
    clipboard.copyText(props.generatedContent)
  }
}

const copyHashtags = () => {
  if (props.generatedContent) {
    clipboard.copyHashtags(props.generatedContent)
  }
}

const copyLink = () => {
  if (props.generatedContent) {
    clipboard.copyLink(props.generatedContent)
  }
}

const copyVideoDescription = () => {
  if (videoDescription.value) {
    clipboard.copy(videoDescription.value)
  }
}
</script>