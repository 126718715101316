<template>
  <div class="absolute inset-0 bg-black/50 backdrop-blur-sm z-20 flex items-center justify-center rounded-lg">
    <div class="bg-white rounded-lg p-8 shadow-xl max-w-md text-center">
      <div class="w-16 h-16 mx-auto mb-4 flex items-center justify-center">
        <span class="material-symbols-outlined text-primary !text-[28px] animate-spin">progress_activity</span>
      </div>
      <h3 class="text-xl font-semibold text-gray-800 mb-2">{{ message }}</h3>
      <p class="text-gray-600 mb-6">{{ description }}</p>

      <!-- Cancel Button (optional) -->
      <button v-if="showCancelButton" @click="$emit('cancel')" class="px-4 py-2 bg-red-50 text-red-600 border border-red-200 rounded-md hover:bg-red-100 transition-colors flex items-center gap-2 mx-auto">
        <span class="material-symbols-outlined !text-[18px]">cancel</span> {{ $t('socialMedia.components.createPost.loadingOverlay.cancel') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  message: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  showCancelButton: {
    type: Boolean,
    default: true,
  },
})

defineEmits(['cancel'])
</script>