
const typeList= [
    { value: '1', label: 'DOSYA' },
    { value: '2', label: 'SUNUM' },
    { value: '3', label: 'TEKLİF' },
    { value: '4', label: 'PROSEDÜR' },
    { value: '5', label: 'TALİMAT' },
    { value: '6', label: 'GÖRSEL' },
    { value: '7', label: 'VİDEO' }
]

// Dosya durum listesi
const stateList = [
    { value: 1, label: 'İşleniyor', color: 'bg-yellow-100 text-yellow-800', icon: 'pending' },
    { value: 2, label: 'Hazır', color: 'bg-green-100 text-green-800', icon: 'check_circle' },
    { value: 3, label: 'Hata', color: 'bg-red-100 text-red-800', icon: 'error' }
]

const utils={
    getFileTypeName(val){
        if(!val) return '-'
        return typeList.find(r=>Number(r.value)==val)?.label || '-';
    },
    getTypesAll(){
        return typeList;
    },
    getStateText(val){
        if(!val) return 'Bilinmiyor';
        return stateList.find(r=>r.value === Number(val))?.label || 'Bilinmiyor';
    },
    getStateColor(val){
        if(!val) return 'bg-gray-100 text-gray-600';
        return stateList.find(r=>r.value === Number(val))?.color || 'bg-gray-100 text-gray-600';
    },
    getStateIcon(val){
        if(!val) return '';
        return stateList.find(r=>r.value === Number(val))?.icon || '';
    },
    getStateList(){
        return stateList;
    }
}

module.exports=utils;

