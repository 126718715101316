import { responseHandlerService } from '@/plugins/responseHandlerService'
import { isKeywordLimitExceeded, findInvalidKeywords, processNewKeywords } from '../../utils/keywords/keywordAddUtils'

export function useKeywordAdd(props, emit, t, { activeKeywordsCount }) {
  /**
   * Yeni keywordleri ekler
   * @param {Array} newKeywords - Eklenecek keyword dizisi
   */
  const handleAddKeywords = (newKeywords) => {
    // Limit kontrolü
    if (isKeywordLimitExceeded(activeKeywordsCount.value, newKeywords.length, props.maxKeywords)) {
      showLimitExceededError()
      return
    }

    // Geçerlilik kontrolü
    const invalidKeywords = findInvalidKeywords(newKeywords)
    if (invalidKeywords.length > 0) {
      showValidationError()
      return
    }

    // Keywordleri işle
    const { updatedKeywords, results } = processNewKeywords(newKeywords, props.keywords)

    // Sonuçlara göre işlem yap
    if (results.new > 0) {
      // Değişiklikleri emit et
      emit('update:keywords', updatedKeywords)

      // Başarı mesajlarını göster
      showSuccessMessages(results)
    }else if(results.unchanged > 0){
      showDuplicateKeywordWarningMessages()
    }
  }

  /**
   * Limit aşıldı hatasını gösterir
   */
  const showLimitExceededError = () => {
    responseHandlerService.errorHandler(
        t('settings.components.configuration.components.keywordsTable.keywordLimitExceeded',
            { max: props.maxKeywords },
        ),
    )
  }
const showDuplicateKeywordWarningMessages = () => {
  responseHandlerService.warningHandler(
    t(
      'settings.components.configuration.validation.keyword.duplicateKeywords',
  ),
  )
}
  /**
   * Doğrulama hatasını gösterir
   */
  const showValidationError = () => {
    responseHandlerService.errorHandler(
        t('settings.components.configuration.validation.keyword.minLength'),
    )
  }

  /**
   * İşlem sonuç mesajlarını gösterir
   * @param {Object} results - İşlem sonuçları
   */
  const showSuccessMessages = (results) => {
    Object.entries(results).forEach(([type, count]) => {
      if (count > 0 && type !== 'unchanged') {
        responseHandlerService.successHandler(
            t(`settings.components.configuration.components.keywordsTable.keywords${
                    type.charAt(0).toUpperCase() + type.slice(1)
                }`,
                { count },
            ),
        )
      }
    })
  }

  return {
    handleAddKeywords,
  }
}