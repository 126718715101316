import ExcelJS from 'exceljs'

/**
 * Excel worksheet için temel kolonları ve stilleri ayarlar
 * @param {Object} worksheet - Excel worksheet objesi
 */
export function setupExportWorksheet(worksheet) {
  // Kolon tanımlamaları - Categories ve Status kolonları eklendi
  worksheet.columns = [
    { header: 'Keyword', key: 'keyword', width: 30 },
    { header: 'Priority Score (1/10)', key: 'priorityScore', width: 25 },
    { header: 'Categories', key: 'categories', width: 40 },
    { header: 'Status', key: 'status', width: 15 },
  ]

  // Başlık satırı stilleri
  worksheet.getRow(1).font = { bold: true }
  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFE9ECEF' },
  }
}

/**
 * Excel worksheet için zebra çizgili stil uygular
 * @param {Object} worksheet - Excel worksheet objesi
 */
export function applyWorksheetStyling(worksheet) {
  // Tüm hücrelere kenarlık ekle
  worksheet.eachRow((row) => {
    row.eachCell({ includeEmpty: true }, (cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: 'FFE9ECEF' } },
        left: { style: 'thin', color: { argb: 'FFE9ECEF' } },
        bottom: { style: 'thin', color: { argb: 'FFE9ECEF' } },
        right: { style: 'thin', color: { argb: 'FFE9ECEF' } },
      }
    })
  })
}

/**
 * Satırlara zebra çizgili stil uygular
 * @param {Object} worksheet - Excel worksheet objesi
 * @param {number} rowIndex - Satır indeksi
 */
export function applyZebraStriping(worksheet, rowIndex) {
  if (rowIndex % 2 === 1) { // Tek indeksli satırlara (2, 4, 6...) uygula
    worksheet.getRow(rowIndex + 2).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFF8F9FA' },
    }
  }
}

/**
 * Boş bir Excel workbook ve worksheet oluşturur
 * @param {string} sheetName - Worksheet adı
 * @returns {Object} Workbook ve worksheet objeleri
 */
export function generateKeywordWorkbook(sheetName = 'Keywords') {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet(sheetName)

  setupExportWorksheet(worksheet)

  return { workbook, worksheet }
}

/**
 * Keyword state değerine göre durum metnini döndürür
 * @param {number} state - Keyword state değeri
 * @returns {string} Durum metni
 */
export function getStatusText(state) {
  switch (state) {
    case 1:
      return 'Saved'
    case 2:
      return 'Not Saved'
    default:
      return ''
  }
}

/**
 * Kategori listesini virgülle ayrılmış string'e dönüştürür
 * @param {Array} categoryIds - Kategori dizisi
 * @returns {string} Virgülle ayrılmış kategori isimleri
 */
export function formatCategoryNames(categoryIds) {
  if (!categoryIds || !Array.isArray(categoryIds) || categoryIds.length === 0) {
    return ''
  }

  return categoryIds
      .filter(category => category && category.categoryName)
      .map(category => category.categoryName)
      .join(', ')
}