import { ref } from 'vue'
import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

/**
 * İçerik yönetim durumunu oluştur
 */
function useContentState() {
  const state = {
    isSaving: ref(false),
    isPublishing: ref(false),
    isSaved: ref(false),
    isPublished: ref(false),
    contentId: ref(null),
    contentAnalytics: ref({
      views: 0,
      likes: 0,
      comments: 0,
      shares: 0,
    }),
  }

  /**
   * İçerik yönetim durumunu sıfırla
   */
  const resetContentState = () => {
    state.isSaved.value = false
    state.isPublished.value = false
    state.contentId.value = null
    state.contentAnalytics.value = {
      views: 0,
      likes: 0,
      comments: 0,
      shares: 0,
    }
  }

  return {
    state,
    resetContentState,
  }
}

/**
 * İçerik analitikleriyle ilgili işlemleri yönet
 */
function useContentAnalytics() {
  /**
   * İçerik analitiklerini getir
   */
  const fetchContentAnalytics = async (contentId) => {
    if (!contentId) return null

    try {
      const response = await axios.get(socialMedia.getContentAnalytics, {
        params: { contentId },
      })

      return response.data.data || {
        views: 0,
        likes: 0,
        comments: 0,
        shares: 0,
      }
    } catch (error) {
      console.error('İçerik analitiklerini alırken hata oluştu:', error)
      return null
    }
  }

  /**
   * İçerik analitiklerini güncelle
   */
  const updateAnalytics = async (contentId, analyticsRef) => {
    if (!contentId) return

    const analytics = await fetchContentAnalytics(contentId)
    if (analytics) {
      analyticsRef.value = analytics
    }
  }

  return {
    fetchContentAnalytics,
    updateAnalytics,
  }
}

/**
 * İçerik kaydetme ve yayınlama operasyonlarını yönet
 */
function useContentOperations(state, analyticsService) {
  const { t } = useI18n()

  /**
   *Modified saveContent function that uses the prepareContentData helper
   */
  const saveContent = async (content, assetId, platforms) => {
    if (!content) return false

    state.isSaving.value = true

    try {
      // Use the helper function to prepare the data
      const contentData = prepareContentData(content, assetId, platforms)

      const response = await axios.post(socialMedia.savePost, contentData)

      state.contentId.value = response.data.data
      state.isSaved.value = true

      responseHandlerService.handleSuccess(t('socialMedia.components.createPost.success.contentSaved'))
      return true
    } catch (error) {
      responseHandlerService.handleError(
          error,
          t('socialMedia.components.createPost.errors.contentSave'),
      )
      return false
    } finally {
      state.isSaving.value = false
    }
  }

  /**
   * İçeriği yayınla
   */
  const publishContent = async (assetId, platforms) => {
    if (!state.contentId.value) return false

    state.isPublishing.value = true

    try {
      await axios.post(socialMedia.publishPost, {
        assetId,
        platforms,
        contentId: state.contentId.value,
      })

      state.isPublished.value = true

      // Analitikleri güncelle
      await analyticsService.updateAnalytics(state.contentId.value, state.contentAnalytics)

      responseHandlerService.handleSuccess(t('socialMedia.components.createPost.success.contentPublished'))
      return true
    } catch (error) {
      responseHandlerService.handleError(
          error,
          t('socialMedia.components.createPost.errors.contentPublish'),
      )
      return false
    } finally {
      state.isPublishing.value = false
    }
  }

  return {
    saveContent,
    publishContent,
  }
}

export function useContentManagement() {
  const { state, resetContentState } = useContentState()
  const analyticsService = useContentAnalytics()
  const contentOps = useContentOperations(state, analyticsService)

  /**
   * İçerik analitiklerini güncelle
   */
  const updateAnalytics = async () => {
    await analyticsService.updateAnalytics(state.contentId.value, state.contentAnalytics)
  }

  return {
    // State değerleri
    isSaving: state.isSaving,
    isPublishing: state.isPublishing,
    isSaved: state.isSaved,
    isPublished: state.isPublished,
    contentId: state.contentId,
    contentAnalytics: state.contentAnalytics,

    // Metodlar
    saveContent: contentOps.saveContent,
    publishContent: contentOps.publishContent,
    updateAnalytics,
    resetContentState,
  }
}

/**
 * Prepare content data for saving
 * Ensures video URLs are properly formatted for API requests
 */
function prepareContentData(content, assetId, platforms) {
  // Create a copy to avoid modifying the original content
  const contentData = {
    assetId,
    message: content.message,
    hashtags: content.hashtags,
    platforms,
    media: content.media || [],
  }

  // Handle video URL - make sure it's saved as a link
  if (content.videoUrl) {
    contentData.link = content.videoUrl
    contentData.hasVideo = true
  } else if (content.link) {
    // If the link is already provided, use it
    contentData.link = content.link

    // Check if it's a video link
    const isVideoLink = checkIfVideoLink(content.link)
    if (isVideoLink || content.hasVideo) {
      contentData.hasVideo = true
    }
  }

  // Make sure video description is included if it exists
  if (content.videoDescription) {
    contentData.videoDescription = content.videoDescription
  }

  return contentData
}

/**
 * Check if the provided URL is a video link
 */
function checkIfVideoLink(url) {
  if (!url) return false

  const link = url.toLowerCase()

  // Check video extensions
  const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.wmv']
  const hasVideoExtension = videoExtensions.some(ext => link.endsWith(ext))

  // Check video hosting services
  const isVideoHosting = link.includes('youtube.com/watch') ||
      link.includes('youtu.be/') ||
      link.includes('vimeo.com/') ||
      link.includes('/video/') ||
      link.includes('/videos/') ||
      link.includes('tiktok.com')

  return hasVideoExtension || isVideoHosting
}
