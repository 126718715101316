<template>
  <div class="px-5 py-3 bg-white flex items-center justify-between border-t border-gray-200 mt-auto">
    <!-- Tarih Bilgisi -->
    <div class="flex items-center">
      <span class="text-xs text-gray-500 flex items-center gap-1">
        <span class="material-symbols-outlined !text-[14px]">schedule</span>
        {{ formattedDate }}
      </span>
    </div>

    <!-- İşlem Butonları -->
    <div class="flex gap-2">
      <!-- Yayınla butonu -->
      <v-button v-if="!isPublished" @click="$emit('publish')" :is-disabled="true" :is-loading="isPublishing" :button-text="t('socialMedia.components.postInspector.publish')" icon="publish" color="primary" variation="solid" :show-icon="true"/>

      <!-- Analitik butonu -->
      <v-button @click="$emit('analytics')" :is-disabled="true" :button-text="t('socialMedia.components.postInspector.analytics')" icon="analytics" color="secondary" variation="outline" :show-icon="true"/>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/tr'
import VButton from '@/components/base/buttons/VButton.vue'

dayjs.extend(relativeTime)

const { t } = useI18n()

const props = defineProps({
  date: {
    type: [String, Date],
    default: null,
  },
  isPublished: {
    type: Boolean,
    default: false,
  },
  isPublishing: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['publish', 'analytics'])

const formattedDate = computed(() => {
  if (!props.date) return ''
  const locale = document.documentElement.lang || 'tr'
  return dayjs(props.date).locale(locale).fromNow()
})
</script>