<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" @closed="handleClose" class="flex justify-center items-center" :content-class="[
      'flex flex-col bg-white w-full shadow-lg h-screen lg:h-auto rounded-none md:rounded-md md:w-[calc(100%-4rem)] lg:w-full max-w-5xl']">
    <div class="flex flex-col w-full h-full">
      <!-- Header -->
      <div class="sticky top-0 z-10 w-full flex items-center justify-between bg-primary/95 px-6 py-3 text-white shrink-0 rounded-t-md">
        <h2 class="text-base font-medium flex items-center gap-2">
          <span class="material-symbols-outlined !text-[20px]">
            {{ getHeaderIcon }}
          </span> <span>{{ getHeaderTitle }}</span>
        </h2>
        <button @click="$emit('update:modelValue', false)" class="p-1.5 rounded-md hover:bg-white/10 active:bg-white/20 transition-colors flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Scrollable Content -->
      <div class="flex-1 overflow-y-auto">
        <div class="px-4 md:px-6 py-4 md:py-5">
          <!-- Welcome Screen -->
          <WelcomeSection v-if="currentMode === 'welcome'" @select-mode="selectMode"/>

          <!-- Trend List Screen -->
          <TrendListSection v-else-if="currentMode === 'trendList'" :trends="trends" :is-loading="isLoadingTrends" :trend-filter-items="trendFilterItems" v-model:selected-trend-type="selectedTrendType" @trend-filter-change="handleTrendFilterChange" @select-trend="selectTrend" @select-mode="selectMode"/>

          <!-- Trend Content Form -->
          <TrendContentForm v-else-if="currentMode === 'trend'" :trend="selectedTrend" :form-data="formData" :validation-errors="validationErrors" @back-to-trends="goBackToTrends" @update:form-data="updateFormData" @select-mode="selectMode"/>

          <!-- Manual Content Form -->
          <ManualContentForm v-else-if="currentMode === 'manual'" :form-data="formData" :validation-errors="validationErrors" @update:form-data="updateFormData" @trigger-image-upload="triggerImageUpload" @image-select="handleImageSelect" @image-drop="handleImageDrop" @remove-image="removeImage" @select-mode="selectMode"/>
        </div>
      </div>

      <!-- Footer -->
      <div v-if="currentMode !== 'welcome' && currentMode !== 'trendList'" class="sticky bottom-0 z-10 px-4 md:px-6 py-4 bg-gray-50 border-t border-gray-100 shrink-0 rounded-b-md">
        <CustomButton type="button" @click="handleSubmit" :button-text="t('entity.components.newContent.components.newContentModal.createButton')" icon="auto_awesome" :show-icon="true" size="md" :full-width="true"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import CustomButton from '@/components/base/buttons/VButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { formatFileSize } from '@/utils/common'
import axios from '@/plugins/axiosInstance'
import { dashboard, entity } from '@/networking/urlManager'

// Import components
import WelcomeSection from './components/WelcomeSection.vue'
import TrendListSection from './components/TrendListSection.vue'
import TrendContentForm from './components/TrendContentForm.vue'
import ManualContentForm from './components/ManualContentForm.vue'

// Content Length enum
const CONTENT_LENGTH = {
  short: 1,
  medium: 2,
  long: 3,
}

// Trend keyword types
const TREND_KEYWORD_TYPES = {
  COMPANY_RELATED: 1,
  UNRELATED: 2,
}


const emit = defineEmits(['update:modelValue', 'formSubmitted'])
const { t } = useI18n()
const assetStore = useAssetStore()
const fileInput = ref(null)
const validationErrors = reactive({})

// Current mode state
const currentMode = ref('welcome')  // 'welcome', 'manual', 'trend', 'trendList'

// Trends state
const trends = ref([])
const isLoadingTrends = ref(false)
const selectedTrendType = ref('')
const selectedTrend = ref(null)

// Trend filter items
const trendFilterItems = computed(() => [
  {
    id: '',
    name: t('entity.components.newContent.components.newContentModal.trendListSection.filters.general'),
  },
  {
    id: TREND_KEYWORD_TYPES.COMPANY_RELATED.toString(),
    name: t('entity.components.newContent.components.newContentModal.trendListSection.filters.assetRelated'),
  },
])

// Computed header properties
const getHeaderIcon = computed(() => {
  if (currentMode.value === 'welcome') return 'add_circle'
  if (currentMode.value === 'trendList') return 'trending_up'
  if (currentMode.value === 'trend') return 'trending_up'
  return 'smart_toy'
})

const getHeaderTitle = computed(() => {
  return t('entity.components.newContent.components.newContentModal.welcomeTitle')
})

// Form data
const formData = reactive({
  languageId: assetStore.assetLanguage || 1,
  contentLength: 2,
  assistiveDescription: '',
  assistiveImageDescription: '',
  keywords: [],
  images: [],
})

// Methods
const updateFormData = (newData) => {
  Object.assign(formData, newData)
}

const selectMode = (mode) => {
  if (mode === 'trend') {
    currentMode.value = 'trendList'
    fetchTrends()
  } else if (mode === 'welcome') {
    currentMode.value = 'welcome'
    selectedTrend.value = null
  } else {
    currentMode.value = mode
  }
}

const fetchTrends = async (trendKeywordType = TREND_KEYWORD_TYPES.UNRELATED) => {
  isLoadingTrends.value = true
  try {
    const response = await axios.get(dashboard.getTrendKeywords, {
      params: {
        assetId: assetStore.assetId,
        trendKeywordType,
      },
    })
    trends.value = response.data.data || []
  } catch (error) {
    console.error('Error fetching keyword trends:', error)
    responseHandlerService.handleError(t('common.error'))
    trends.value = []
  } finally {
    isLoadingTrends.value = false
  }
}

const handleTrendFilterChange = () => {
  const trendKeywordType = selectedTrendType.value ? parseInt(selectedTrendType.value) : TREND_KEYWORD_TYPES.UNRELATED
  fetchTrends(trendKeywordType)
}

const selectTrend = (trend) => {
  selectedTrend.value = trend
  currentMode.value = 'trend'
}

const goBackToTrends = () => {
  currentMode.value = 'trendList'
  selectedTrend.value = null
}

const handleSubmit = async () => {
  try {
    // Form doğrulama işlemi
    if (!validateForm()) return

    // Form verilerini hazırla
    const formDataObj = createFormData()

    // Form verisini ilet
    emit('formSubmitted', formDataObj)
  } catch (error) {
    console.error('error', error)
    responseHandlerService.handleError(t('common.validationError'))
  }
}

// Form doğrulama fonksiyonu
const validateForm = () => {
  // Önceki hataları temizle
  Object.keys(validationErrors).forEach(key => delete validationErrors[key])

  // Manuel içerik modunda ek doğrulamalar yap
  if (currentMode.value === 'manual') {
    // Keywords doğrulaması
    if (!formData.keywords || formData.keywords.length < 3) {
      validationErrors.keywords = t('form.errors.minKeywords', { min: 3 })
      responseHandlerService.handleError(t('common.validationError'))
      return false
    }

    // İçerik uzunluğu doğrulaması
    if (!formData.contentLength) {
      validationErrors.contentLength = t('form.errors.required')
      responseHandlerService.handleError(t('common.validationError'))
      return false
    }
  }

  // Trend içerik için içerik uzunluğu doğrulaması ekle
  if (currentMode.value === 'trend' && !formData.contentLength) {
    validationErrors.contentLength = t('form.errors.required')
    responseHandlerService.handleError(t('common.validationError'))
    return false
  }

  return true
}

// FormData objesi oluşturma fonksiyonu
const createFormData = () => {
  const formDataObj = new FormData()

  // Temel form alanlarını ekle
  addBasicFormFields(formDataObj)

  // Görselleri ekle
  addImageFields(formDataObj)

  // Trend içeriği varsa ilgili alanları ekle
  if (currentMode.value === 'trend') {
    addTrendFields(formDataObj)
  }

  return formDataObj
}

// Temel form alanlarını ekleyen fonksiyon
const addBasicFormFields = (formDataObj) => {
  formDataObj.append('languageId', formData.languageId)
  formDataObj.append('assetId', assetStore.assetId)
  formDataObj.append('contentLength', formData.contentLength || '')
  formDataObj.append('assistiveDescription', formData.assistiveDescription || '')
  formDataObj.append('assistiveImageDescription', formData.assistiveImageDescription || '')

  // keywords alanını JSON olarak ekle
  if (formData.keywords && formData.keywords.length > 0) {
    formDataObj.append('keywords', JSON.stringify(formData.keywords))
  }
}

// Görsel alanlarını ekleyen fonksiyon
const addImageFields = (formDataObj) => {
  if (formData.images && formData.images.length > 0) {
    if (formData.images[0]) formDataObj.append('image1', formData.images[0].file)
    if (formData.images[1]) formDataObj.append('image2', formData.images[1].file)
  }
}

// Trend ile ilgili alanları ekleyen fonksiyon
const addTrendFields = (formDataObj) => {
  if (selectedTrend.value) {
    formDataObj.append('keyword', selectedTrend.value.name || '')
    formDataObj.append('isFromTrendKeywords', 'true')

    // Trendin newsUrl'si varsa ekle
    if (selectedTrend.value.newsUrl) {
      formDataObj.append('newsUrl', selectedTrend.value.newsUrl)
    }
  }
}

const handleClose = () => {
  // Clear form data
  formData.languageId = assetStore.assetLanguage || 1
  formData.contentLength = 2
  formData.assistiveDescription = ''
  formData.assistiveImageDescription = ''
  formData.keywords = []
  formData.images = []

  // Reset to welcome screen
  currentMode.value = 'welcome'
  selectedTrend.value = null

  // Clear validation errors
  Object.keys(validationErrors).forEach(key => delete validationErrors[key])

  emit('update:modelValue', false)
}

const triggerImageUpload = () => {
  if (fileInput.value) {
    fileInput.value.click()
  }
}

const handleImageSelect = (event) => {
  const files = Array.from(event.target.files)
  files.forEach(file => handleImageFile(file))
  // Reset file input after selection
  if (event.target) {
    event.target.value = ''
  }
}

const handleImageDrop = (event) => {
  const files = Array.from(event.dataTransfer.files)
  files.forEach(file => {
    if (file.type.startsWith('image/')) handleImageFile(file)
  })
}

const handleImageFile = (file) => {
  if (formData.images.length >= 2) {
    responseHandlerService.handleWarning(t('common.maxImagesError'))
    return
  }

  const MAX_TOTAL_SIZE = 10 * 1024 * 1024

  const currentTotalSize = formData.images.reduce((sum, img) => sum + img.file.size, 0)
  const newTotalSize = currentTotalSize + file.size

  if (newTotalSize <= MAX_TOTAL_SIZE) {
    const reader = new FileReader()
    reader.onload = (e) => {
      formData.images.push({
        file: file,
        preview: e.target.result,
      })
    }
    reader.readAsDataURL(file)
  } else {
    const { size, unit } = formatFileSize(MAX_TOTAL_SIZE)
    responseHandlerService.handleWarning(t('common.fileSizeError', { size, unit }))
  }
}

const removeImage = (index) => {
  formData.images.splice(index, 1)
}
</script>