/**
 * Silinecek keyword'ü bulur
 * @param {Array} keywords - Keyword dizisi
 * @param {Object|string} keyword - Silinecek keyword
 * @returns {number} Bulunan keyword'ün indeksi, bulunamazsa -1
 */
export function findKeywordToRemove(keywords, keyword) {
  const keywordName = typeof keyword === 'string' ? keyword : keyword.name

  return keywords.findIndex(k => {
    if (!k || !keyword) return false

    return (
        k.name.toLowerCase() === keywordName.toLowerCase() &&
        String(k.id || '') === String(keyword.id || '') &&
        String(k.keywordId || '') === String(keyword.keywordId || '')
    )
  })
}

/**
 * Silinen keyword'deki kategorilerin state değerlerini günceller
 * @param {Array} categoryIds - Kategori dizisi
 * @returns {Array} Güncellenmiş kategori dizisi
 */
export function updateCategoryStatesForRemoval(categoryIds = []) {
  return categoryIds.map(category => {
    if (category.state === 1) {
      // Mevcut kategorileri silme olarak işaretle
      return {
        ...category,
        state: 3,
      }
    }
    // Diğer durumlardaki kategorileri olduğu gibi bırak
    return category
  })
}

/**
 * Silinen keyword'ün güncellenmiş versiyonunu oluşturur
 * @param {Object} keyword - Silinecek keyword
 * @returns {Object} Güncellenmiş keyword
 */
export function createDeletedKeyword(keyword) {
  return {
    ...keyword,
    state: 3,
    categoryIds: updateCategoryStatesForRemoval(keyword.categoryIds),
  }
}

/**
 * Orijinal keyword dizisini, silinen keyword ile günceller
 * @param {Array} keywords - Orijinal keyword dizisi
 * @param {number} index - Silinecek keyword'ün indeksi
 * @returns {Array} Güncellenmiş keyword dizisi
 */
export function updateKeywordsWithRemoved(keywords, index) {
  if (index === -1) return keywords

  const result = [...keywords]
  result[index] = createDeletedKeyword(result[index])
  return result
}