<template>
  <div class="mb-4">
    <div class="flex items-center justify-between mb-2">
      <h4 class="text-sm font-medium text-gray-700">
        {{ isVideoLink ? t('socialMedia.components.createPost.contentPreview.videoLink') || 'Video Bağlantısı' : t('socialMedia.components.createPost.contentPreview.link') }} </h4>
      <!-- Copy Link Button -->
      <button @click="copyLink" class="flex items-center gap-1 px-2 py-1 bg-gray-100 text-gray-600 text-xs font-medium rounded hover:bg-gray-200 transition-colors">
        <span class="material-symbols-outlined !text-[14px]">content_copy</span> {{ t('socialMedia.components.createPost.contentPreview.copy.link') }}
      </button>
    </div>

    <!-- Video Link -->
    <div v-if="isVideoLink" class="relative rounded-lg overflow-hidden aspect-video shadow-sm mb-3">
      <video v-if="videoSrc" :src="videoSrc" controls class="w-full h-full object-cover"></video>
      <div v-else class="w-full h-full bg-gray-100 flex flex-col items-center justify-center p-4">
        <span class="material-symbols-outlined text-gray-400 !text-[48px] mb-3">smart_display</span>
        <p class="text-sm text-gray-500 text-center">
          {{ t('socialMedia.components.createPost.contentPreview.videoLinkPlaceholder') || 'Video içeriği yüklenirken bir sorun oluştu' }} </p>
        <a :href="link" target="_blank" rel="noopener noreferrer" class="mt-2 text-xs text-primary hover:underline flex items-center">
          <span>{{ t('socialMedia.components.createPost.contentPreview.openVideoLink') || 'Video bağlantısını aç' }}</span> <span class="material-symbols-outlined !text-[14px] ml-1">open_in_new</span>
        </a>
      </div>
    </div>

    <!-- Image Link -->
    <div v-else-if="isImageLink" class="border rounded-lg overflow-hidden shadow-sm mb-3">
      <div class="relative aspect-video bg-gray-100 overflow-hidden">
        <img :src="link" alt="Image link" class="w-full h-full object-contain"/>
      </div>
      <div class="p-3 bg-white">
        <div class="flex items-center justify-between">
          <p class="text-sm text-gray-500 truncate">{{ t('socialMedia.components.createPost.contentPreview.imageLink') }}</p>
          <a :href="link" target="_blank" rel="noopener noreferrer" class="text-xs text-primary hover:underline flex items-center">
            <span>{{ t('socialMedia.components.createPost.contentPreview.openImage') }}</span> <span class="material-symbols-outlined !text-[14px] ml-1">open_in_new</span> </a>
        </div>
      </div>
    </div>

    <!-- Link Preview (Rich Content) -->
    <div v-else-if="hasLinkPreview" class="border rounded-lg overflow-hidden shadow-sm mb-3">
      <!-- Preview Image -->
      <div class="relative aspect-video bg-gray-100 overflow-hidden">
        <img :src="linkPreview.image" :alt="linkPreview.title || 'Link preview'" class="w-full h-full object-cover"/>
        <div class="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent h-1/3"></div>
        <div class="absolute bottom-2 left-2 right-2 text-white">
          <p class="text-sm truncate font-medium">{{ linkPreview.title || link }}</p>
          <p class="text-xs truncate opacity-80">{{ linkPreview.domain || extractDomain(link) }}</p>
        </div>
      </div>

      <!-- Preview Info -->
      <div class="p-3 bg-white">
        <p class="text-sm text-gray-800 line-clamp-2">{{ linkPreview.description || 'No description available for this link.' }}</p>
        <a :href="link" target="_blank" rel="noopener noreferrer" class="text-xs text-primary hover:underline mt-1 flex items-center">
          <span>{{ t('socialMedia.components.createPost.contentPreview.linkVisit') }}</span> <span class="material-symbols-outlined !text-[14px] ml-1">open_in_new</span> </a>
      </div>
    </div>

    <!-- Simple Link Display (No Preview) -->
    <div v-else class="p-3 bg-gray-50 rounded-lg border border-gray-200">
      <div class="flex items-center">
        <span class="material-symbols-outlined text-gray-500 mr-2">link</span>
        <a :href="link" target="_blank" rel="noopener noreferrer" class="text-primary hover:underline text-sm truncate flex-grow"> {{ link }} </a>
        <button class="p-1 text-gray-400 hover:text-gray-600">
          <span class="material-symbols-outlined !text-[16px]">open_in_new</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  link: {
    type: String,
    required: true,
  },
  isVideoLink: {
    type: Boolean,
    default: false,
  },
  isImageLink: {
    type: Boolean,
    default: false,
  },
  videoSrc: {
    type: String,
    default: null,
  },
  linkPreview: {
    type: Object,
    default: () => null,
  },
  hasLinkPreview: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['copy-link'])

const copyLink = () => {
  emit('copy-link')
}

const extractDomain = (url) => {
  try {
    const domain = new URL(url).hostname
    return domain.replace('www.', '')
  } catch (e) {
    return url
  }
}
</script>