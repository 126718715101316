<template>
  <div class="bg-white rounded-lg p-6 shadow-sm text-center">
    <div class="w-16 h-16 rounded-full bg-green-100 flex items-center justify-center mx-auto mb-4">
      <span class="material-symbols-outlined text-green-600 !text-[32px]">check_circle</span>
    </div>

    <h3 class="text-xl font-bold text-gray-800 mb-2">
      {{
        isPublished
            ? $t('socialMedia.components.createPost.contentResult.publishedTitle')
            : $t('socialMedia.components.createPost.contentResult.savedTitle')
      }} </h3>
    <p class="text-gray-600 mb-6">
      {{
        isPublished
            ? $t('socialMedia.components.createPost.contentResult.publishedDescription')
            : $t('socialMedia.components.createPost.contentResult.savedDescription')
      }} </p>

    <div class="flex flex-col sm:flex-row gap-4 justify-center">
      <button @click="$emit('new-content')" class="flex items-center justify-center gap-2 px-6 py-2 text-primary border border-primary rounded-md hover:bg-primary/5 transition-colors">
        <span class="material-symbols-outlined !text-[18px]">add</span> {{ $t('socialMedia.components.createPost.contentResult.newContent') }}
      </button>

      <button v-if="!isPublished && isSaved" @click="$emit('publish-content')" class="flex items-center justify-center gap-2 px-6 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors">
        <span class="material-symbols-outlined !text-[18px]">send</span> {{ $t('socialMedia.components.createPost.contentResult.publishNow') }}
      </button>

      <button v-if="isPublished" @click="$emit('view-analytics')" class="flex items-center justify-center gap-2 px-6 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition-colors">
        <span class="material-symbols-outlined !text-[18px]">analytics</span> {{ $t('socialMedia.components.createPost.contentResult.viewAnalytics') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  isPublished: {
    type: Boolean,
    default: false,
  },
  isSaved: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['new-content', 'publish-content', 'view-analytics'])
</script>