<template>
  <div class="p-0">
    <!-- Görsel/Video İçeriği - Aspect ratio ile -->
    <div v-if="isImageLink || (linkPreview && linkPreview.image)" class="mb-5">
      <div class="relative aspect-video overflow-hidden">
        <img :src="isImageLink ? link : linkPreview.image" alt="Content Preview" class="w-full h-full object-cover"/>
      </div>
    </div>

    <!-- İçerik Metni ve Hashtag'ler -->
    <div class="px-5 py-4">
      <p class="text-gray-800 text-sm whitespace-pre-line leading-relaxed mb-4">{{ message }}</p>

      <div v-if="hashtags && hashtags.length" class="flex flex-wrap gap-1.5 mb-3">
        <span v-for="(tag, index) in hashtags" :key="index" class="text-primary text-xs font-medium bg-primary/10 px-2 py-0.5 rounded-full">
          {{ tag }}
        </span>
      </div>
    </div>

    <!-- Link Preview (Resim olmayan linkler için) -->
    <div v-if="link && !isImageLink && (!linkPreview || !linkPreview.image)" class="border-t border-gray-100 mb-4 mx-5">
      <div class="py-3">
        <a :href="link" target="_blank" class="text-primary hover:underline font-medium text-sm flex items-center gap-1.5" @click.prevent="$emit('visit-link', link)">
          <span class="material-symbols-outlined !text-[16px]">link</span> {{ linkPreview?.title || extractDomain(link) }} </a>
        <p v-if="linkPreview?.description" class="text-gray-600 mt-1 text-xs line-clamp-2">
          {{ linkPreview.description }} </p>
      </div>

      <!-- Link Footer -->
      <div class="py-2 text-xs text-gray-500 flex items-center justify-between border-t border-gray-100">
        <span>{{ extractDomain(link) }}</span> <a :href="link" target="_blank" class="text-primary flex items-center gap-1" @click.prevent="$emit('visit-link', link)">
        <span>{{ t('socialMedia.components.postInspector.visitLink') }}</span> <span class="material-symbols-outlined !text-[14px]">open_in_new</span> </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineProps({
  message: {
    type: String,
    default: '',
  },
  hashtags: {
    type: Array,
    default: () => [],
  },
  link: {
    type: String,
    default: null,
  },
  linkPreview: {
    type: Object,
    default: () => ({
      title: '',
      description: '',
      image: '',
    }),
  },
  isImageLink: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['visit-link'])

function extractDomain(url) {
  if (!url) return ''
  try {
    return new URL(url).hostname.replace('www.', '')
  } catch {
    return url
  }
}
</script>