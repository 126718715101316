import axios from 'axios'
import router from '@/router'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { baseUrl } from '@/networking/urlManager'
import { errorMessages } from './errorMessages'

const DEFAULT_TIMEOUT = 500000

let stores = null

export const initializeAxiosStores = (authStore, uiStore) => {
  stores = { authStore, uiStore }
}

const instance = axios.create({
  baseURL: baseUrl,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

instance.interceptors.request.use(
    (config) => {
      if (!stores) {
        console.warn('Stores not initialized in axios instance')
        return config
      }

      const { authStore, uiStore } = stores
      responseHandlerService.resetConnectionErrorFlag()
      errorMessages.setLanguage(uiStore.getCurrentLanguage)

      const token = authStore.userData?.token
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      config.headers['Accept-Language'] = uiStore.getCurrentLanguage
      config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Istanbul'

      return config
    },
    (error) => Promise.reject(error),
)

/**
 * Bağlantı hatasının olup olmadığını kontrol eder
 */
function isConnectionError(error) {
  return (
      (error.code === 'ECONNABORTED' && error.message.includes('timeout')) ||
      !error.response
  )
}

/**
 * Bağlantı hatalarını işler
 */
function handleConnectionError(error) {
  responseHandlerService.handleError({
    isConnectionError: true,
    message: errorMessages.get('connectionError'),
  })
  return Promise.reject(error)
}

/**
 * 401 Unauthorized hatalarını işler
 */
async function handle401Error(error, authStore) {
  if (error.config._skipAuthRedirect) {
    await authStore.clearUserData()
    return Promise.reject(error)
  }

  await authStore.clearUserData()
  await router.push('/')

  if (!responseHandlerService.unauthorizedSnackbarDisplayed) {
    const message = error.response.data?.message || errorMessages.get('sessionExpired')
    responseHandlerService.handleError({ message })
    responseHandlerService.unauthorizedSnackbarDisplayed = true
  }
}

/**
 * 500 Server Error hatalarını işler
 */
function handle500Error(error) {
  const message = error.response.data?.message || errorMessages.get('serverError')
  responseHandlerService.handleError({ message })
}

/**
 * HTTP durum kodu hatalarını işler
 */
async function handleHttpErrors(error, stores) {
  const { authStore } = stores
  const status = error.response?.status

  switch (status) {
    case 401:
      await handle401Error(error, authStore)
      break
    case 500:
      handle500Error(error)
      break
      // Diğer HTTP hata kodları gerekirse buraya eklenebilir
  }

  return Promise.reject(error)
}

instance.interceptors.response.use(
    (response) => {
      responseHandlerService.resetConnectionErrorFlag()
      return response
    },
    async (error) => {
      if (axios.isCancel(error)) {
        return Promise.reject(error)
      }

      // Bağlantı hatalarını ele al
      if (isConnectionError(error)) {
        return handleConnectionError(error)
      }

      if (!stores) {
        console.warn('Stores not initialized in axios instance')
        return Promise.reject(error)
      }

      // HTTP durum kodu hatalarını ele al
      return handleHttpErrors(error, stores)
    },
)

export default instance