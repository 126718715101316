import {
  generateKeywordWorkbook,
  applyWorksheetStyling,
  applyZebraStriping,
  getStatusText,
  formatCategoryNames,
} from './keywordExportUtils.js'

/**
 * Örnek verilerle şablon Excel dosyası oluşturur
 * @returns {Promise<Object>} Workbook ve worksheet objeleri
 */
export async function generateTemplateWorkbook() {
  // Yeni workbook oluştur
  const { workbook, worksheet } = generateKeywordWorkbook()

  // Örnek veriler
  const sampleData = [
    {
      keyword: 'keyword 1',
      priorityScore: '1',
      categories: 'Category 1, Category 2',
      status: 'Saved',
    },
    {
      keyword: 'keyword 2',
      priorityScore: '2',
      categories: 'Category 3',
      status: 'Not Saved',
    },
  ]

  // Verileri ekle ve stilleri uygula
  sampleData.forEach((item, index) => {
    worksheet.addRow({
      keyword: item.keyword,
      priorityScore: item.priorityScore,
      categories: item.categories,
      status: item.status,
    })

    applyZebraStriping(worksheet, index)
  })

  applyWorksheetStyling(worksheet)

  return { workbook, worksheet }
}

/**
 * Keyword verilerini Excel buffer'ına dönüştürür
 * @param {Array} keywords - Keyword verileri
 * @returns {Promise<Buffer>} Excel dosyası buffer'ı
 */
export async function exportKeywordsToBuffer(keywords) {
  // Yeni workbook oluştur
  const { workbook, worksheet } = generateKeywordWorkbook()

  // Sadece state 1 (Saved) ve state 2 (Not Saved) olanları filtrele
  const filteredKeywords = keywords.filter(keyword => keyword.state === 1 || keyword.state === 2)

  // Verileri ekle ve stilleri uygula
  filteredKeywords.forEach((keyword, index) => {
    worksheet.addRow({
      keyword: keyword.name,
      priorityScore: keyword.priority || '',
      categories: formatCategoryNames(keyword.categoryIds),
      status: getStatusText(keyword.state),
    })

    applyZebraStriping(worksheet, index)
  })

  applyWorksheetStyling(worksheet)

  // Buffer olarak döndür
  return workbook.xlsx.writeBuffer()
}