<template>
  <div class="h-[280px] flex items-center justify-center">
    <template v-if="!isLoading && !isReady">
      <div class="flex flex-col items-center gap-4">
        <div class="w-16 h-16 bg-red-50 rounded-full flex items-center justify-center animate-pulse">
          <span class="material-symbols-outlined text-red-600 !text-5xl">add_circle</span>
        </div>
        <div class="text-center">
          <p class="text-gray-800 text-center items-center flex justify-center font-medium mb-2">
            {{ t('assets.dashboard.aiReport.create.title') }} </p>
          <p class="text-gray-600 text-sm mb-4">
            {{ t('assets.dashboard.aiReport.create.message') }} </p>
          <div class="flex gap-2 justify-center">
            <custom-button :isLoading="excelExporting" :isDisabled="!canExport" @click="exportToExcel" color="secondary" size="md" :button-text="'Excel'"/>
          </div>
        </div>
      </div>
    </template>
    <!-- Error State -->
    <template v-else-if="hasError">
      <div class="flex flex-col items-center gap-4">
        <div class="w-16 h-16 bg-red-50 rounded-full flex items-center justify-center animate-pulse">
          <span class="material-symbols-outlined text-red-600 !text-5xl">error</span>
        </div>
        <div class="text-center">
          <p class="text-gray-800 font-medium mb-2">
            {{ t('assets.dashboard.aiReport.error.title') }} </p>
          <p class="text-gray-600 text-sm mb-4">
            {{ t('assets.dashboard.aiReport.error.message') }} </p>
          <div class="flex gap-2 justify-center">
            <custom-button @click="$emit('retry')" color="primary" size="md" :button-text="t('assets.dashboard.aiReport.error.retry')"/>
            <custom-button :isLoading="excelExporting" :isDisabled="!canExport" @click="exportToExcel" color="secondary" size="md" :button-text="t('assets.dashboard.aiReport.error.export')"/>
          </div>
        </div>
      </div>
    </template>

    <!-- Loading State -->
    <template v-else-if="isLoading">
      <AIAnimation>
        <p class="text-gray-600 font-medium text-center">
          {{ t('assets.dashboard.aiReport.loading') }} </p>
        <LoadingDots/>
      </AIAnimation>
    </template>
    <!-- Ready State -->
    <template v-else>
      <div class="flex flex-col items-center justify-center h-full w-full">
        <div class="flex flex-col items-center justify-center gap-4">
          <div class="w-16 h-16 bg-emerald-50 rounded-full flex items-center justify-center animate-bounce-slow">
            <span class="material-symbols-outlined text-emerald-500 !text-5xl">task_alt</span>
          </div>
          <div class="text-center space-y-3">
            <div class="space-y-1">
              <p class="text-gray-800 font-medium text-xl">
                {{ t('assets.dashboard.aiReport.ready.title') }} </p>
              <p class="text-gray-500 text-sm">
                {{ t('assets.dashboard.aiReport.ready.message') }} </p>
            </div>
            <div class="flex gap-2">
              <custom-button @click="$emit('view-report')" color="primary" size="md" variation="solid" class="w-full" :button-text="t('assets.dashboard.aiReport.ready.view')"/>
              <custom-button :isLoading="excelExporting" :isDisabled="!canExport" @click="exportToExcel" color="secondary" size="md" variation="solid" class="w-full" :button-text="t('assets.dashboard.aiReport.ready.export')"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { saveAs } from 'file-saver'
import { useAssetStore } from '@/stores/asset'
import AIAnimation from '@/components/features/ai/AiAssistantAnimation.vue'
import LoadingDots from '@/components/loading/LoadingDots.vue'
import CustomButton from '@/components/base/buttons/VButton.vue'
import VTooltip from '@/components/common/VTooltip.vue'

const { t } = useI18n()
const assetStore = useAssetStore()
const excelExporting = ref(false)

const props = defineProps({
  isLoading: Boolean,
  hasError: Boolean,
  isReady: Boolean,
  isDisabled: Boolean,
  reportStatusLoad: Boolean,
  datePickerModel: {
    type: Object,
    default: () => ({
      start: null,
      end: null,
    }),
  },
  assetList: {
    type: Array,
    default: () => [],
  },
})

const canExport = computed(() => {
  // Enable export if we have at least one asset ID
  return props.assetList.length > 0 || assetStore.assetId
})

const exportToExcel = () => {
  excelExporting.value = true

  const startDate = props.datePickerModel?.start
      ? dayjs(props.datePickerModel.start).utc().format()
      : dayjs().startOf('year').utc().format()

  const endDate = props.datePickerModel?.end
      ? dayjs(props.datePickerModel.end).utc().format()
      : dayjs().utc().format()

  // Use the asset list from props if available, otherwise fallback to the asset store ID
  const assetListToExport = props.assetList.length > 0
      ? props.assetList
      : [assetStore.assetId]

  const params = {
    assetList: assetListToExport,
    startDate,
    endDate,
  }

  axios
      .post(dashboard.exportKeywords, params, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'] ||
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const fileName = `keyword_analysis_${dayjs().format('YYYY-MM-DD')}.xlsx`
        saveAs(blob, fileName)
      })
      .catch((error) => console.error('Export failed:', error))
      .finally(() => {
        excelExporting.value = false
      })
}

defineEmits(['retry', 'create', 'view-report'])
</script>