import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

/**
 * Bağlantı hatasının olup olmadığını kontrol eder
 */
function isConnectionError(error) {
  return (
      (error.code === 'ECONNABORTED' && error.message.includes('timeout')) ||
      !error.response
  )
}

/**
 * Bağlantı önizlemesi getir
 */
export const fetchLinkPreview = async (link) => {
  if (!link) return null

  try {
    const response = await axios.get(`${socialMedia.getLinkPreview}?url=${encodeURIComponent(link)}`)
    return response.data && response.data.image ? response.data : null
  } catch (error) {
    console.log('Link preview error:', error)
    return null
  }
}

/**
 * API'den gelen yanıtı işleyerek içerik objesini oluşturur
 */
/**
 * API'den gelen yanıtı işleyerek içerik objesini oluşturur
 */
export const processApiResponse = async (response, mediaData = []) => {
  if (!response?.data?.data) {
    throw new Error('Invalid API response');
  }

  const content = response.data.data;

  // Temel içerik nesnesini oluştur
  const result = {
    message: content.socialMediaContent || '',
    hashtags: parseHashtags(content.socialMediaHashtags),
    link: content.link || '',
    media: mediaData || [],
    hasVideo: !!content.videoUrl || content.hasVideo === true || content.hasVideo === 1,
    videoDescription: content.videoDescription || null,
    videoUrl: content.videoUrl || null
  };

  // Link önizlemesi çekmeyi dene (sadece video olmayan geçerli linkler için)
  await tryFetchLinkPreview(result);

  return result;
};

// Hashtags'i parse et
function parseHashtags(hashtagsData) {
  if (!hashtagsData) return [];

  try {
    return typeof hashtagsData === 'string' ? JSON.parse(hashtagsData) : hashtagsData;
  } catch (e) {
    console.error('Error parsing hashtags:', e);
    return [];
  }
}

// Link önizlemesini çekmeyi dene
async function tryFetchLinkPreview(result) {
  // Video URL'si veya geçersiz link ise atlayalım
  if (!result.link || result.link === result.videoUrl || !isValidUrl(result.link)) {
    return;
  }

  try {
    const linkPreview = await fetchLinkPreview(result.link);
    if (linkPreview) {
      result.linkPreview = linkPreview;
    }
  } catch (error) {
    console.log('Link preview error:', error);
  }
}

// URL geçerliliğini kontrol et
function isValidUrl(url) {
  if (typeof url !== 'string') return false;

  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * İçerik oluşturma isteği gönderir
 */
export const sendContentRequest = async (requestData, cancelToken) => {
  return await axios.post(
      socialMedia.postGenerate,
      requestData,
      { cancelToken },
  )
}

/**
 * Hata durumunu işler
 */
export const handleContentError = (error, state, t, showErrorMessage = true) => {
  // İptal durumu kontrolü
  if (axios.isCancel(error)) {
    console.log('Content generation request canceled')
  }
  // Bağlantı hatası kontrolü - axios interceptor tarafından ele alınıyor
  else if (isConnectionError(error)) {
    console.error('Connection error in content generation (handled by axios interceptor):', error)
  }
  // Diğer hatalar için mesaj göster
  else if (showErrorMessage) {
    console.error('Content generation error:', error)
    responseHandlerService.handleError(
        error,
        t('socialMedia.components.createPost.errors.generateContent'),
    )
  } else {
    // Sadece loglama yap, hata mesajı gösterme
    console.error('Content generation error (silent):', error)
  }

  // Üretim durumunu sıfırla
  state.isGenerating.value = false
  return null
}

/**
 * Özellik bilgilerini yeniler
 */
export const refreshFeatures = async (featureStore) => {
  if (featureStore && typeof featureStore.refreshFeatures === 'function') {
    try {
      await featureStore.refreshFeatures()
    } catch (err) {
      console.error('Error refreshing features:', err)
    }
  }
}

/**
 * İçerik oluşturma işlemi - Ana fonksiyon
 */
export const generateContent = async (requestData, mediaData = [], cancelToken, state, featureStore, t, showErrorMessage = true) => {
  try {
    // İçerik oluşturma isteği gönder
    const response = await sendContentRequest(requestData, cancelToken)

    // API yanıtını işle
    return await processApiResponse(response, mediaData)
  } catch (error) {
    // Hata durumunu işle
    return handleContentError(error, state, t, showErrorMessage)
  } finally {
    // Özellik bilgilerini yenile
    await refreshFeatures(featureStore)
  }
}

/**
 * Trend verisinden istek verisi oluştur
 */
export const createTrendRequestData = (data, assetStore) => ({
  assetId: assetStore.assetId,
  languageId: data.languageId,
  keywords: data.keywords || [data.trend.name],
  size: data.selectedAspectRatio,
  trendKeyword: data.trend.name,
  newsUrl: data.trend.newsUrl || null,
  videoDescription: data.videoDescription || null,
  hasVideo: data.hasVideo ? 1 : 0,
})

/**
 * Manuel form verisinden istek verisi oluştur
 */
export const createManualRequestData = (formData, assetStore) => ({
  assetId: assetStore.assetId,
  languageId: formData.languageId,
  keywords: formData.keywords,
  size: formData.aspectRatio,
  videoDescription: formData.videoDescription || null,
  hasVideo: formData.hasVideo ? 1 : 0,
})