<template>
  <Alert v-if="showWarning" class="mb-4" layout="px-6" status="warning" :message="t('dashboard.components.keywordAnalysis.warnings.lowVolumeWarning', { count: deletedKeywords.length })" :hasAction="true" @action="showDeletedKeywordsModal = true" :actionText="t('dashboard.components.keywordAnalysis.warnings.viewDetails')" :onDismiss="dismissWarning"/>

  <div class="bg-white p-4 rounded-md shadow-md relative">
    <CustomTable :data="keywords" :columns="dynamicColumns" v-bind="tableProps" @page-change="handlePageChange" @search="handleSearch" min-width="900px">
      <template #table-actions>
        <div class="flex items-center justify-between w-full mr-2">
          <div class="flex items-center space-x-2">
            <h1 class="text-base font-semibold">
              {{ t('dashboard.components.keywordAnalysis.title') }} </h1>
            <Tooltip :title="t('dashboard.components.keywordAnalysis.tooltips.exportTooltip')" v-if="keywords.length > 0">
              <template #content>
                <div class="flex items-center">
                  <span @click="exportToExcel" class="material-symbols-outlined text-secondary cursor-pointer !text-[22px] hover:text-primary" :class="excelExporting ? 'animate-spin' : ''">
                    {{ excelExporting ? 'progress_activity' : 'export_notes' }}
                  </span>
                </div>
              </template>
            </Tooltip>
            <Tooltip :title="t('common.tooltipInformations.keywordAnalysis')" information>
              <template #content>
                <icon-component icon="info" iconClass="text-primary hover:bg-gray-100 p-1 rounded-full text-sm"/>
              </template>
            </Tooltip>
          </div>
        </div>
      </template>

      <template #number="{ item, index }">
        {{ calculateRank(index) }}
      </template>

      <template #volume="{ item }">
        <div class="flex items-center justify-center w-full">
          <span :class="[
            getVolumeDisplay(item).class,
            'whitespace-nowrap overflow-hidden text-ellipsis',
          ]" :title="getVolumeDisplay(item).fullText">
            {{ getVolumeDisplay(item).displayText }}
          </span>
        </div>
      </template>

      <template #inContent="{ item }">
        <div class="flex items-center justify-center">
          <span>{{ item.entityCount }}</span>
        </div>
      </template>

      <template #reporting="{ item }" v-if="type === '1'">
        <div class="flex items-center justify-center space-x-4">
          <Tooltip :title="t('assets.dashboard.keywordAnalysis.modals.categoryTable.title')" :display-condition="true">
            <template #content>
              <span class="material-symbols-outlined text-secondary !text-[16px] hover:text-primary cursor-pointer" @click="categoryKeywordModal(item)">
                account_tree
              </span>
            </template>
          </Tooltip>
        </div>
      </template>
    </CustomTable>

    <CategoryKeywordsModal v-model="showCategoryModal" :mainKeyword="selectedItem"/>
    <MonthlyKeywordVolumeModal v-model="showMonthlyVolumeModal" :item="selectedItem"/>
    <MonthlyKeywordPositionsModal v-model="showMonthlyPositionModal" :item="selectedItem"/>
    <DeletedKeywordsModal v-if="showDeletedKeywordsModal" v-model="showDeletedKeywordsModal" :keywords="deletedKeywords"/>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { saveAs } from 'file-saver'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import CustomTable from '@/components/table/VTable.vue'
import MonthlyKeywordVolumeModal from './components/keywordModals/monthlyKeywordVolumeModal.vue'
import MonthlyKeywordPositionsModal from './components/keywordModals/monthlyKeywordPositionsModal.vue'
import Tooltip from '@/components/common/VTooltip.vue'
import Alert from '@/components/feedback/Alert.vue'
import { useAssetStore } from '@/stores/asset'
import DeletedKeywordsModal from './components/keywordModals/DeletedKeywordsModal.vue'
import CategoryKeywordsModal from './components/keywordModals/categoryKeywordModal.vue'
import IconComponent from '@/components/common/VIcon.vue'

dayjs.extend(utc)

const props = defineProps({
  type: {
    type: String,
    default: '1',
    validator: (value) => ['1', '2'].includes(value),
  },
  assetIdList: {
    type: Array,
    default: () => [],
  },
  isFetchable: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['keywordData'])

const { t } = useI18n()
const assetStore = useAssetStore()

// State
const keywords = ref([])
const isLoading = ref(false)
const itemsPerPage = ref(30)
const totalCount = ref(0)
const pageCount = ref(0)
const currentPage = ref(1)
const showMonthlyVolumeModal = ref(false)
const showMonthlyPositionModal = ref(false)
const selectedItem = ref(null)
const datePickerModel = ref({ start: null, end: null })
const isDateChange = ref(false)
const sortKey = ref('')
const sortOrder = ref('DESC')
const searchQuery = ref('')
const selectedSourceFilter = ref('')
const excelExporting = ref(false)
const showCategoryModal = ref(false)

// Alert States
const showWarning = ref(false)
const showDeletedKeywordsModal = ref(false)
const deletedKeywords = ref([])

// Filter Items
const filterItems = computed(() => {
  const items = [
    {
      id: '',
      name: t('dashboard.components.keywordAnalysis.sourceType.all'),
      default: true,
    },
  ]

  if (props.type === '1') {
    items.push(
        {
          id: '1',
          name: t('dashboard.components.keywordAnalysis.sourceType.manuel'),
        },
        {
          id: '2',
          name: t('dashboard.components.keywordAnalysis.sourceType.suggestion'),
        },
    )
  } else {
    items.push({
      id: '2',
      name: t('dashboard.components.keywordAnalysis.sourceType.suggestion'),
    })
  }

  return items
})

// Dynamic Columns based on type
const dynamicColumns = computed(() => {
  const baseColumns = [
    {
      key: 'number',
      label: t('dashboard.components.keywordAnalysis.columns.number'),
      width: 'w-1/12',
    },
  ]

  if (props.type === '2') {
    baseColumns.push({
      key: 'assetName',
      label: t('dashboard.components.keywordAnalysis.columns.assetName'),
      width: 'w-1/12',
    })
  }

  baseColumns.push(
      {
        key: 'categoryName',
        label: t('dashboard.components.keywordAnalysis.columns.keywords'),
        width: props.type === '1' ? 'w-1/12' : 'w-2/12',
      },
      {
        key: 'volume',
        label: t('dashboard.components.keywordAnalysis.columns.searchVolume'),
        width: 'w-1/12',
        align: 'center',
        sortable: true,
      },
      {
        key: 'inContent',
        label: t('dashboard.components.keywordAnalysis.columns.incontent'),
        width: 'w-1/12',
        align: 'center',
        sortable: true,
      },
  )

  if (props.type === '1') {
    baseColumns.push({
      key: 'reporting',
      label: t('dashboard.components.keywordAnalysis.columns.reporting'),
      width: 'w-1/12',
      align: 'center',
    })
  }

  return baseColumns
})

// Table Props
const tableProps = computed(() => ({
  isLoading: isLoading.value,
  itemsPerPage: props.type === '1' ? itemsPerPage.value : keywords.value.length,
  totalCount: totalCount.value,
  pageCount: pageCount.value,
  currentPage: currentPage.value,
  sortKey: sortKey.value,
  sortOrder: sortOrder.value,
  showFilter: false,
  filterOptions: [
    {
      id: 'source',
      icon: 'filter_alt',
      iconClass: 'material-symbols-outlined text-gray-600 !text-[22px]',
      showText: false,
      items: filterItems.value,
    },
  ],
  minHeight: showWarning.value ? '250px' : '300px',
  maxHeight: showWarning.value ? '250px' : '300px',
  showPagination: props.type === '1',
}))

// API İşlemleri
const fetchData = async () => {
  isLoading.value = true

  if (!props.isFetchable) {
    isLoading.value = false
    return
  }

  try {
    const params = buildRequestParams()
    const response = await axios.post(dashboard.getKeywordMainKategory, params)

    if (!response?.data?.data) throw new Error('API response data is invalid')

    processResponse(response.data.data)
  } catch (error) {
    console.error('Error fetching keywords:', error)
    keywords.value = []
    showWarning.value = false
  } finally {
    isLoading.value = false
  }
}

// Temel parametreleri oluştur
const buildRequestParams = () => {
  // Temel parametreler - sabit olacak şekilde
  const baseParams = {
    assetList: props.type === '2' ? props.assetIdList : [assetStore.assetId],
    type: props.type,
    pagination: props.type === '1' ? 1 : 0,
  }

  // Koşullu parametreler
  return {
    ...baseParams,
    ...(props.type === '1' && { page: currentPage.value - 1 }),
    ...(selectedSourceFilter.value?.source && { source: selectedSourceFilter.value.source }),
    ...(sortKey.value && { sortKey: sortKey.value }),
    ...(sortOrder.value && { sortOrder: sortOrder.value }),
    ...(datePickerModel.value?.start && datePickerModel.value?.end && {
      startDate: dayjs(datePickerModel.value.start).utc().format(),
      endDate: dayjs(datePickerModel.value.end).utc().format(),
    }),
  }
}

// Yanıt işleme
const processResponse = (data) => {
  if (props.type === '1') {
    keywords.value = data.detail || []
    pageCount.value = data.pageCount || 0
    totalCount.value = data.totalCount || 0
    processDeletedKeywords(data.deletedKeywords || [])
  } else {
    keywords.value = Array.isArray(data) ? data : []
    emit('keywordData', keywords.value)
    pageCount.value = 1
    totalCount.value = keywords.value.length
    currentPage.value = 1
    processDeletedKeywords([])
  }
}

// Silinmiş anahtar kelimeleri işleyen yardımcı fonksiyon
const processDeletedKeywords = (data) => {
  // Önce verinin array olduğundan emin ol
  if (!Array.isArray(data)) {
    data = []
  }

  // Uyarı gösterilsin mi?
  showWarning.value = data.length > 0

  // Silinmiş anahtar kelimeleri ayarla
  deletedKeywords.value = data
}


// Yardımcı Fonksiyonlar
const checkWarningCondition = (data) => {
  const hasWarning = data.length > 0
  if (hasWarning) {
    showWarning.value = true
    deletedKeywords.value = data
  } else {
    showWarning.value = false
  }
}

const dismissWarning = () => {
  showWarning.value = false
}

const handlePageChange = (newPage) => {
  if (props.type === '1') {
    currentPage.value = newPage
    fetchData()
  }
}

const handleSort = ({ key, order }) => {
  sortKey.value = key
  sortOrder.value = order
  fetchData()
}

const handleFilter = (selectedFilter) => {
  selectedSourceFilter.value = selectedFilter
  currentPage.value = 1
  fetchData()
}

const handleSearch = (query) => {
  searchQuery.value = query
  fetchData()
}

// Veri Görüntüleme Yardımcıları
const getVolumeDisplay = (item) => {
  const volume = item.totalVolume || 0
  const volumeChangePercentage = parseFloat(item.volumeChangePercentage) || 0

  let displayText = volume.toString()
  let fullText = displayText
  let volumeClass = 'text-sm'

  if (isDateChange.value && volumeChangePercentage !== 0) {
    const sign = volumeChangePercentage > 0 ? '+' : ''
    const changeText = `(${sign}${volumeChangePercentage}%)`
    fullText = `${displayText} ${changeText}`
    displayText = fullText

    volumeClass += volumeChangePercentage > 0 ? ' text-green-500' : ' text-red-500'
  }

  if (fullText.length > 12) {
    volumeClass += ' text-xs'
  }

  return {
    displayText,
    fullText,
    class: volumeClass,
  }
}

// Excel Dışa Aktarma
const exportToExcel = () => {
  excelExporting.value = true

  const startDate = datePickerModel?.value?.start
      ? dayjs(datePickerModel.value.start).utc().format()
      : dayjs().startOf('year').utc().format()

  const endDate = datePickerModel?.value?.end
      ? dayjs(datePickerModel.value.end).utc().format()
      : dayjs().utc().format()

  const params = {
    assetList: [assetStore.assetId],
    startDate,
    endDate,
  }

  axios
      .post(dashboard.exportKeywords, params, { responseType: 'arraybuffer' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'] ||
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const fileName = `keyword_analysis_${dayjs().format('YYYY-MM-DD')}.xlsx`
        saveAs(blob, fileName)
      })
      .catch((error) => console.error('Export failed:', error))
      .finally(() => {
        excelExporting.value = false
      })
}

// Modal İşlemleri
const categoryKeywordModal = (item) => {
  selectedItem.value = { id: item.categoryId, name: item.categoryName }
  showCategoryModal.value = true
}

// Yardımcı Hesaplamalar
const calculateRank = (index) =>
    (currentPage.value - 1) * itemsPerPage.value + index + 1

// Tarih değişikliklerini izleme
watch(datePickerModel, (newValue, oldValue) => {
  if (newValue.start !== oldValue.start || newValue.end !== oldValue.end) {
    if (newValue.start && newValue.end) {
      currentPage.value = 1
      isDateChange.value = true
      fetchData()
    } else if (newValue.start === null && newValue.end === null) {
      isDateChange.value = false
      fetchData()
    }
  }
})

// İlk veri yükleme ve prop değişikliğini izleme
fetchData()

watch(
    () => props.isFetchable,
    () => {
      fetchData()
    },
)
</script>