<template>
  <div class="mb-5">
    <h4 class="text-sm font-medium text-gray-700 mb-2">{{ t('socialMedia.components.createPost.contentPreview.media') }}</h4>
    <div class="grid grid-cols-2 gap-3">
      <div v-for="(media, index) in mediaItems" :key="index" class="relative rounded-lg overflow-hidden aspect-video shadow-sm">
        <img v-if="isImage(media)" :src="media.url || media.preview" alt="Content image" class="w-full h-full object-cover"/>
        <video v-else-if="isVideo(media)" :src="media.url || media.preview" controls class="w-full h-full object-cover"></video>
        <div v-else class="w-full h-full bg-gray-100 flex items-center justify-center">
          <span class="material-symbols-outlined text-gray-400 !text-[24px]">image</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  mediaItems: {
    type: Array,
    required: true,
  },
})

// Media type helpers
const isImage = (media) => {
  if (!media) return false
  return media.type && media.type.includes('image')
}

const isVideo = (media) => {
  if (!media) return false

  if (media.type && media.type.includes('video')) {
    return true
  }

  // Check if URL ends with common video extensions
  const url = media.url || media.preview || ''
  const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.wmv']
  return videoExtensions.some(ext => url.toLowerCase().endsWith(ext))
}
</script>