<template>
  <VModal 
    v-model="modalVisible"
    :id="modalId"
    :z-index="10000"
    :modalTitle="''"
    :hideHeader="false"
    :contentClass="'flex flex-col bg-white border rounded-md shadow-xl relative h-[650px] max-h-[85vh]'"
    :clickToClose="true"
    :isNestedModal="true"
    :preventCloseNested="false"
    :width="'w-[1280px]'"
    size="default"
    @update:modelValue="updateVisibility"
    :minHeight="'600px'"
    @closed="handleClose">
    
    <template #icon>
      <span class="material-symbols-outlined text-primary text-2xl">smart_toy</span>
    </template>
    
    <template #header-content>
      <div>
        <h2 class="text-xl font-bold text-gray-800">Kategori Prompt Yönetimi</h2>
        <p class="text-sm text-gray-500">Her kategori için özel prompt ayarlarını düzenleyin</p>
      </div>
    </template>
    
    <PromptModalContent @close="handleClose"/>
  </VModal>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount } from 'vue'
import VModal from '@/components/modals/VModal.vue'
import PromptModalContent from './PromptModalContent.vue'

const props = defineProps({
  // Modal görünürlüğü
  modelValue: {
    type: Boolean,
    default: false,
  },
  
  // Özel modal ID (opsiyonel)
  id: {
    type: String,
    default: 'prompt-manager-modal'
  }
})

const emit = defineEmits(['update:modelValue', 'close'])

// Benzersiz bir modal ID oluştur
const modalId = computed(() => props.id)

// Modal görünürlüğü
const modalVisible = ref(false)

// modelValue değiştiğinde görünürlüğü güncelle
watch(() => props.modelValue, (newValue) => {
  modalVisible.value = newValue
}, { immediate: true })

// Modal kapatıldığında
const handleClose = () => {
  modalVisible.value = false
  emit('update:modelValue', false)
  emit('close')
}

// Modal görünürlüğünü güncelleme
const updateVisibility = (value) => {
  modalVisible.value = value
  emit('update:modelValue', value)
  
  if (!value) {
    emit('close')
  }
}
</script>