import * as XLSX from 'xlsx'

export async function readExcelFile(file) {
  try {
    const buffer = await file.arrayBuffer()
    return XLSX.read(buffer, {
      type: 'array',
      cellDates: true,
      cellStyles: true,
    })
  } catch (error) {
    throw new Error('Excel reading error: ' + error.message)
  }
}

export function worksheetToJson(workbook, sheetName, options = { header: 1 }) {
  const worksheet = workbook.Sheets[sheetName]
  if (!worksheet) return []
  return XLSX.utils.sheet_to_json(worksheet, options)
}

export function validateWorksheetExists(workbook, sheetName) {
  return workbook &&
      workbook.SheetNames &&
      workbook.SheetNames.includes(sheetName) &&
      workbook.Sheets[sheetName]
}

export function isWorksheetEmpty(rows) {
  return !rows || rows.length <= 1
}

export function getContentRowsWithoutHeader(rows) {
  if (!rows || rows.length <= 1) return []
  return rows.slice(1).filter(row =>
      row && Array.isArray(row) && row[0] && row[0].toString().trim() !== '',
  )
}