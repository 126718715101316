import { ref } from 'vue'
import { KeywordImportManager } from '../utils/keywordImport/keywordImportManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

/**
 * Keyword içe aktarma işlemlerini yöneten composable
 * @param {Object} props - Component propları (keywords, maxKeywords)
 * @param {Function} emit - Olay emisyon fonksiyonu
 * @param {Function} t - Çeviri fonksiyonu
 * @returns {Object} İçe aktarma işlemleri ve sonuçlar
 */
export function useKeywordImport(props, emit, t) {
  const importResults = ref(null)
  const isImporting = ref(false)

  /**
   * Dosya tipini kontrol eder
   * @param {File} file - Kontrol edilecek dosya
   * @returns {boolean} Dosya tipi geçerli mi?
   */
  const isValidFileType = (file) => {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel', // .xls
      'application/octet-stream', // Excel olarak algılanamayan dosyalar için
    ]

    // MIME tipi kontrolü
    if (validTypes.includes(file.type)) {
      return true
    }

    // Uzantı kontrolü (MIME tipi doğru algılanamadığında)
    const fileName = file.name.toLowerCase()
    return fileName.endsWith('.xlsx') || fileName.endsWith('.xls')
  }

  /**
   * Excel dosyasını içe aktarma işlemini gerçekleştirir
   * @param {File} file - Yüklenen Excel dosyası
   * @returns {Promise<Object>} İşlem sonucu
   */
  const handleFileUpload = async (file) => {
    try {
      isImporting.value = true

      // Dosya tipi kontrolü
      if (!file || !isValidFileType(file)) {
        responseHandlerService.errorHandler(
            t('settings.components.configuration.components.keywordsTable.invalidFileType'),
        )
        return { success: false, error: 'Invalid file type' }
      }

      // İçe aktarma işlemini başlat
      const manager = new KeywordImportManager(props, t)
      const result = await manager.importFile(file)

      if (result.success) {
        importResults.value = result.results
        emit('update:keywords', result.keywords)
      }

      return result
    } catch (error) {
      console.error('Import error:', error)
      responseHandlerService.errorHandler(
          t('settings.components.configuration.components.keywordsTable.importError'),
      )
      return { success: false, error: error.message }
    } finally {
      isImporting.value = false
    }
  }

  return {
    importResults,
    isImporting,
    handleFileUpload,
  }
}