<template>
  <div class="space-y-6">
    <!-- URL ekleme alanı -->
    <div class="bg-white p-4 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition duration-300">
      <div class="flex flex-col space-y-3">
        <div class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <div class="relative w-full">
            <CustomInput v-model="newUrl" :placeholder="t('settings.components.configuration.components.documentModal.urlPlaceholder')" type="text" container-class="w-full" icon="link" :show-icon="true"/>
          </div>
          <CustomButton :button-text="t('settings.components.configuration.components.documentModal.addUrl')" @click="addUrl" size="sm" variant="primary" :is-disabled="!isValidUrl(newUrl.trim())"/>
        </div>
        <p class="text-xs text-primary font-medium italic">
          {{ t('settings.components.configuration.components.documentModal.urlNote') }} </p>
      </div>
    </div>

    <!-- URL listesi -->
    <div v-if="modelValue && modelValue.length > 0">
      <div class="flex items-center mb-3">
        <div class="w-8 h-px bg-gray-300 mr-3"></div>
        <h3 class="text-sm font-medium text-gray-500">{{ t('settings.components.configuration.components.documentModal.addedUrls') }}</h3>
        <div class="flex-grow h-px bg-gray-300 ml-3"></div>
      </div>
      <div class="overflow-y-auto max-h-[30vh] thin-scrollbar">
        <TransitionGroup name="list" tag="ul" class="space-y-3">
          <li v-for="(item, index) in modelValue" :key="item.id" class="bg-white p-3 rounded-md shadow-sm transition duration-300 ease-in-out hover:shadow-md border border-gray-200">
            <div class="flex justify-between items-center mb-2">
              <a :href="item.url" target="_blank" rel="noopener noreferrer" class="text-secondary text-sm underline" :title="item.url">
                {{ truncateUrl(item.url) }} </a>
              <CustomButton @click="removeUrl(index)" size="sm" variant="danger" icon="delete" :isTextShowable="false" :show-icon="true"/>
            </div>
            
            <!-- Kategori Seçimi -->
            <div class="mb-2">
              <label class="block text-xs font-medium text-gray-600 mb-1">Kategori</label>
              <CustomSelect 
                v-model="item.categoryId" 
                :options="categoryOptions" 
                placeholder="Kategori seçin" 
                class="w-full"
                size="sm"
              />
            </div>
            
            <!-- Keywords Ekleme -->
            <div class="mb-2">
              <label class="block text-xs font-medium text-gray-600 mb-1">Anahtar Kelimeler</label>
              <CustomTagInput 
                v-model="item.keywords" 
                placeholder="Anahtar kelimeleri girin ve Enter'a basın" 
                class="w-full"
                size="sm"
              />
            </div>
            
            <!-- Açıklama Alanı -->
            <div>
              <label class="block text-xs font-medium text-gray-600 mb-1">Açıklama (Opsiyonel)</label>
              <CustomInput 
                :model-value="item.description" 
                @update:model-value="updateDescription(index, $event)" 
                :placeholder="t('settings.components.configuration.components.documentModal.descriptionPlaceholder')" 
                type="text" 
                size="sm"
              />
            </div>
          </li>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid'
import { useAssetStore } from '@/stores/asset'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import CustomButton from '@/components/base/buttons/VButton.vue'
import CustomInput from '@/components/base/inputs/VInput.vue'
import CustomSelect from '@/components/base/inputs/VSelect.vue'
import CustomTagInput from '@/components/base/inputs/VTagInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const { t } = useI18n()
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])
const assetStore = useAssetStore()

const newUrl = ref('')
const categoryOptions = ref([])
const isLoadingCategories = ref(false)

// Kategorileri yükle
const fetchCategories = async () => {
  isLoadingCategories.value = true
  try {
    const response = await axios.get(settings.keywordCategories, {
      params: {
        assetId: assetStore.assetId,
        level: 1,
        isActive: 1,
        isCustom: true,
      },
    })

    if (response?.data?.data?.detail) {
      // Kategorileri select için formatlama
      categoryOptions.value = response.data.data.detail.map(category => ({
        value: category.id,
        label: category.name
      }))
    }
  } catch (error) {
    responseHandlerService.handleError('Kategoriler yüklenirken bir hata oluştu')
    categoryOptions.value = []
  } finally {
    isLoadingCategories.value = false
  }
}

const isValidUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

const truncateUrl = (url, maxLength = 70) => {
  if (url.length <= maxLength) return url
  const half = Math.floor(maxLength / 2)
  return url.slice(0, half - 2) + '...' + url.slice(-half + 1)
}

const addUrl = () => {
  const trimmedUrl = newUrl.value.trim()
  if (isValidUrl(trimmedUrl)) {
    if (!props.modelValue.some(item => item.url === trimmedUrl)) {
      emit('update:modelValue', [{ 
        id: uuidv4(), 
        url: trimmedUrl, 
        description: '',
        categoryId: null,
        keywords: []
      }, ...props.modelValue])
      newUrl.value = ''
    } else responseHandlerService.handleError(t('settings.components.configuration.components.documentModal.urlAlreadyExists'))
  } else responseHandlerService.handleError(t('settings.components.configuration.components.documentModal.invalidUrl'))
}

const removeUrl = (index) => {
  const updatedUrls = [...props.modelValue]
  updatedUrls.splice(index, 1)
  emit('update:modelValue', updatedUrls)
}

const updateDescription = (index, newDescription) => {
  const updatedUrls = [...props.modelValue]
  updatedUrls[index] = { ...updatedUrls[index], description: newDescription }
  emit('update:modelValue', updatedUrls)
}

// Bileşen yüklendiğinde kategorileri getir
onMounted(() => {
  fetchCategories()
})
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>