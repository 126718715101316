<template>
  <div class="p-4 bg-gray-50 border-t border-gray-200">
    <div class="flex items-center justify-between text-sm">
      <div v-if="isPublished" class="text-green-600 font-medium flex items-center">
        <span class="material-symbols-outlined !text-[16px] mr-1">check_circle</span> {{ t('socialMedia.components.createPost.contentPreview.published') }}
      </div>
      <div v-else-if="isSaved" class="text-blue-600 font-medium flex items-center">
        <span class="material-symbols-outlined !text-[16px] mr-1">save</span> {{ t('socialMedia.components.createPost.contentPreview.saved') }}
      </div>

      <div class="flex space-x-4">
        <div class="flex items-center gap-1">
          <span class="material-symbols-outlined !text-[16px] text-blue-500">visibility</span> <span>{{ formatNumber(stats.views || 0) }}</span>
        </div>
        <div class="flex items-center gap-1">
          <span class="material-symbols-outlined !text-[16px] text-red-500">favorite</span> <span>{{ formatNumber(stats.likes || 0) }}</span>
        </div>
        <div class="flex items-center gap-1">
          <span class="material-symbols-outlined !text-[16px] text-green-500">share</span> <span>{{ formatNumber(stats.shares || 0) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  isSaved: {
    type: Boolean,
    default: false,
  },
  isPublished: {
    type: Boolean,
    default: false,
  },
  stats: {
    type: Object,
    default: () => ({
      views: 0,
      likes: 0,
      shares: 0,
    }),
  },
})

const formatNumber = (num) => {
  if (num >= 1000000) return `${(num / 1000000).toFixed(1)}M`
  if (num >= 1000) return `${(num / 1000).toFixed(1)}K`
  return num.toString()
}
</script>