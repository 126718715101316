import { useFeatureStore } from '@/stores/featureStore'
import { useI18n } from 'vue-i18n'
import {
  createContentState,
  cancelGeneration,
  resetContent,
  restorePreviousContent,
} from './contentState'
import {
  generateTrendContent,
  generateManualContentHelper,
  regenerateContentHelper,
} from './contentGenerationHelpers'

/**
 * İçerik üretimi için composable
 */
export function useContentGeneration() {
  // Gerekli bağımlılıkları oluştur
  const featureStore = useFeatureStore()
  const { t } = useI18n()
  const state = createContentState()

  // Trend içeriği oluştur
  const generateFromTrend = async (data, assetStore) => {
    const dependencies = { state, featureStore, t }
    return await generateTrendContent(data, assetStore, dependencies)
  }

  // Manuel içerik oluştur
  const generateManualContent = async (formData, assetStore) => {
    const dependencies = { state, featureStore, t }
    return await generateManualContentHelper(formData, assetStore, dependencies)
  }

  // İçeriği yeniden oluştur
  const regenerateContent = async (assetStore) => {
    const generateFuncs = {
      fromTrend: generateFromTrend,
      manual: generateManualContent,
    }
    return await regenerateContentHelper(state, assetStore, generateFuncs, t)
  }

  // İptal et
  const handleCancel = () => cancelGeneration(state, t)

  // İçeriği sıfırla
  const handleResetContent = () => resetContent(state)

  // Önceki içeriği geri yükle
  const handleRestorePrevious = () => restorePreviousContent(state)

  // Dışa açılan API
  return {
    // Durum değişkenleri
    isGenerating: state.isGenerating,
    generatedContent: state.generatedContent,
    previousContent: state.previousContent,
    lastGenerationParams: state.lastGenerationParams,

    // Metodlar
    generateFromTrend,
    generateManualContent,
    regenerateContent,
    cancelGeneration: handleCancel,
    resetContent: handleResetContent,
    restorePreviousContent: handleRestorePrevious,
  }
}