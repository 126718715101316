<template>
  <div class="bg-white rounded-lg p-6 shadow-sm">
    <!-- Navigation Bar -->
    <div class="flex items-center justify-between mb-5">
      <button @click="$emit('select-mode', 'welcome')" class="flex items-center gap-1 text-sm text-primary hover:text-primary-700 transition-colors">
        <span class="material-symbols-outlined !text-[18px]">arrow_back</span> {{ $t('socialMedia.components.createPost.general.backToHome') }}
      </button>
    </div>

    <div class="mb-6">
      <h3 class="text-xl font-bold text-gray-800 mb-2">{{ $t('socialMedia.components.createPost.trends.title') }}</h3>
      <p class="text-sm text-gray-600">{{ $t('socialMedia.components.createPost.trends.description') }}</p>
    </div>

    <!-- Filter Buttons -->
    <div class="bg-gray-50 rounded-lg mb-6">
      <div class="flex border-b border-gray-100">
        <button v-for="item in trendFilterItems" :key="item.id" @click="handleFilterItemClick(item.id)" class="flex-1 py-3 px-4 text-center font-medium transition-colors relative rounded-lg" :class="selectedTrendType === item.id ?
                 'text-primary bg-white shadow-sm' :
                 'text-gray-500 hover:text-gray-800 hover:bg-white/50'">
          {{ item.name }}
        </button>
      </div>
    </div>

    <!-- Trends List -->
    <div class="relative">
      <!-- Loading State -->
      <div v-if="isLoading" class="min-h-[400px] flex items-center justify-center">
        <VLoading size="lg"/>
      </div>

      <!-- Empty State -->
      <div v-else-if="trends.length === 0" class="min-h-[350px] flex flex-col items-center justify-center py-10 bg-gray-50 rounded-lg">
        <span class="material-symbols-outlined !text-[48px] text-gray-300 mb-4">search_off</span>
        <p class="text-gray-500 text-center">{{ $t('socialMedia.components.createPost.trends.empty.title') }}</p>
        <button @click="$emit('trend-filter-change')" class="mt-4 px-4 py-2 text-sm text-primary border border-primary rounded-md hover:bg-primary/5">
          {{ $t('socialMedia.components.createPost.trends.empty.action') }}
        </button>
      </div>

      <!-- Trends List -->
      <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-4 max-h-[450px] overflow-y-auto pr-2 py-2 thin-scrollbar">
        <div v-for="trend in trends" :key="trend.name" class="bg-white border border-gray-100 rounded-lg p-5 hover:shadow-md hover:border-primary/30 transition-all cursor-pointer transform hover:-translate-y-1 group" @click="$emit('select-trend', trend)">
          <div class="flex flex-col h-full">
            <!-- Trend Name and Volume -->
            <div class="flex justify-between items-start mb-3">
              <h4 class="font-semibold text-gray-800 flex items-center">
                <span class="inline-block w-2 h-2 bg-green-500 rounded-full mr-2"></span> {{ trend.name }} </h4>
              <span class="bg-primary/10 text-primary px-3 py-1 rounded-full text-xs font-medium">
                {{ formatVolumeDisplay(trend.volume) }}
              </span>
            </div>

            <!-- Platform Info (if available) -->
            <div v-if="trend.platforms && trend.platforms.length > 0" class="flex space-x-2 mb-3">
              <div v-for="platform in trend.platforms" :key="platform" class="w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center" :title="platform">
                <img :src="getPlatformIcon(platform)" class="w-4 h-4" :alt="platform"/>
              </div>
            </div>

            <!-- Trend Statistics -->
            <div class="flex items-center gap-4 mt-auto pt-3 border-t border-gray-100">
              <div class="flex items-center gap-1">
                <span class="material-symbols-outlined text-green-600 !text-[16px]">trending_up</span> <span class="text-sm text-green-700 font-medium">+{{ trend.increasePercentage }}%</span>
              </div>

              <div class="flex items-center gap-1 text-gray-500 text-sm">
                <span class="material-symbols-outlined !text-[16px]">schedule</span> {{ formatTimeDisplay(trend.startTimestamp) }}
              </div>

              <!-- Action Button -->
              <div class="ml-auto">
                <span class="flex items-center justify-center w-6 h-6 rounded-full bg-primary/0 group-hover:bg-primary/10 text-primary transition-colors">
                  <span class="material-symbols-outlined !text-[16px]">arrow_forward</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrends } from '../composables/useTrends'
import VLoading from '@/components/loading/VLoading.vue'

const { t } = useI18n()
const { formatTimeDisplay, formatVolumeDisplay } = useTrends()

const props = defineProps({
  trends: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  trendFilterItems: {
    type: Array,
    default: () => [],
  },
  selectedTrendType: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['select-trend', 'trend-filter-change', 'update:selectedTrendType', 'select-mode'])

const getPlatformIcon = (platform) => {
  const platformIcons = {
    instagram: require('@/assets/icons/instagram.svg'),
    facebook: require('@/assets/icons/facebook.svg'),
    twitterx: require('@/assets/icons/twitterx.svg'),
  }
  return platformIcons[platform.toLowerCase()] || ''
}

const handleFilterItemClick = (filterType) => {
  emit('update:selectedTrendType', filterType)
  emit('trend-filter-change')
}
</script>