import * as XLSX from 'xlsx'

/**
 * Excel şablonu için farklı dillerdeki öneri verileri.
 */
const templateSuggestions = {
  tr: [
    ['Kategori Adı', 'Üst Kategori Adı', 'Seviye', 'Görsel Prompt', 'Video Prompt'],
    ['Elektronik', '', '1', 'Elektronik ürünlerin gerçekçi fotoğrafları', 'Elektronik ürünlerin tanıtım videoları'],
    ['Telefonlar', 'Elektronik', '2', 'Modern akıllı telefonların detaylı görüntüleri', 'Telefonların özelliklerini gösteren videolar'],
    ['Akıllı Telefonlar', 'Telefonlar', '3', '', ''],
    ['Android Telefonlar', 'Akıllı Telefonlar', '4', '', ''],
  ],
  en: [
    ['Category Name', 'Parent Category Name', 'Level', 'Image Prompt', 'Video Prompt'],
    ['Electronics', '', '1', 'Realistic photos of electronic products', 'Product introduction videos for electronics'],
    ['Phones', 'Electronics', '2', 'Detailed images of modern smartphones', 'Videos showing phone features'],
    ['Smartphones', 'Phones', '3', '', ''],
    ['Android Phones', 'Smartphones', '4', '', ''],
  ],
}

/**
 * Excel dosyasını oluşturur ve kullanıcının indirmesini sağlar.
 * @param {string} [language='en']
 */
export const downloadCategoryTemplate = (language = 'en') => {
  const suggestions = templateSuggestions[language] || templateSuggestions.en
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(suggestions)

  // Sütun genişlikleri
  ws['!cols'] = [
    { wch: 30 }, // Kategori Adı
    { wch: 30 }, // Üst Kategori
    { wch: 10 }, // Seviye
    { wch: 40 }, // Görsel Prompt
    { wch: 40 }, // Video Prompt
  ]

  XLSX.utils.book_append_sheet(wb, ws, 'Categories')
  XLSX.writeFile(wb, 'category_template.xlsx')
}

/**
 * Excel dosyasını okuyarak Uint8Array şeklinde döndürür.
 * @param {File} file
 * @returns {Promise<Uint8Array>}
 */
function readFileAsArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      resolve(new Uint8Array(e.target.result))
    }
    reader.onerror = () => reject(new Error('Dosya okunamadı'))
    reader.readAsArrayBuffer(file)
  })
}

/**
 * Excel'in ilk sayfasındaki satırları (header dahil) JSON dizisine çevirir.
 * @param {Uint8Array} fileData
 * @returns {Array<Array<any>>} Satırları döndürür
 */
function parseSheetRows(fileData) {
  const workbook = XLSX.read(fileData, { type: 'array' })
  const firstSheet = workbook.Sheets[workbook.SheetNames[0]]
  return XLSX.utils.sheet_to_json(firstSheet, { header: 1 })
}

/**
 * Kategori verilerini temizler ve doğrular
 * @param {Array<Array<any>>} rows Excel satırları
 * @returns {Array<Array<any>>} Temizlenmiş satırlar
 */
function cleanAndValidateRows(rows) {
  return rows.map(row => {
    if (!Array.isArray(row) || row.length < 3) return null

    const [name, parentName, level, imagePrompt, videoPrompt] = row
    if (!name || !level) return null

    return [
      String(name).trim(),
      parentName ? String(parentName).trim() : '',
      typeof level === 'string' ? parseInt(level) : level,
      imagePrompt ? String(imagePrompt).trim() : '',
      videoPrompt ? String(videoPrompt).trim() : '',
    ]
  }).filter(row => row !== null)
}

/**
 * Excel satırlarını (header hariç) kategori yapısına dönüştürür.
 * @param {Array<Array<any>>} rows
 * @returns {Array} rootCategories -> Tüm ana kategoriler
 */
function buildCategoryHierarchy(rows) {
  // İlk satırı başlık olarak düşüneceğimiz için atlayalım
  const categoryData = cleanAndValidateRows(rows.slice(1))

  const categoryMap = new Map()
  const rootCategories = []
  let globalImagePrompt = ''
  let globalVideoPrompt = ''

  // Önce tüm kategorileri seviyelerine göre sırala
  categoryData.sort((a, b) => a[2] - b[2])

  categoryData.forEach((row) => {
    const [name, parentName, level, imagePrompt, videoPrompt] = row

    // İlk seviye kategorinin prompt'larını global olarak kaydedelim
    if (level === 1 && (imagePrompt || videoPrompt)) {
      if (imagePrompt) globalImagePrompt = imagePrompt
      if (videoPrompt) globalVideoPrompt = videoPrompt
    }

    const category = {
      name: name,
      level: level,
      children: [],
      imagePrompt: imagePrompt || '',
      videoPrompt: videoPrompt || ''
    }

    if (!parentName) {
      // Ana kategori
      rootCategories.push(category)
      categoryMap.set(name, category)
    } else {
      // Alt kategori
      const parentCategory = categoryMap.get(parentName)
      if (parentCategory) {
        parentCategory.children.push(category)
        categoryMap.set(name, category)
      }
    }
  })

  return {
    categories: rootCategories,
    imagePrompt: globalImagePrompt,
    videoPrompt: globalVideoPrompt
  }
}

/**
 * Kullanıcıdan gelen Excel dosyasını parse ederek kategori ağacı elde eder.
 * @param {File} file
 * @returns {Promise<Array>} Kökten başlayan kategori hiyerarşisi
 */
export const parseCategoryExcel = async (file) => {
  try {
    const fileData = await readFileAsArrayBuffer(file)
    const rows = parseSheetRows(fileData)
    return buildCategoryHierarchy(rows)
  } catch (error) {
    throw new Error('Excel dosyası işlenirken bir hata oluştu: ' + error.message)
  }
}

/**
 * Belirtilen kategori ağacının geçerli olup olmadığını kontrol eder.
 * @param {Array} categories
 * @param {number} maxLevel
 * @returns {boolean}
 */
export const validateCategoryStructure = (categories, maxLevel) => {
  /**
   * @param {Array} categoryList
   * @param {number} currentLevel
   * @returns {boolean}
   */
  function validateLevel(categoryList, currentLevel = 1) {
    // Seviye kontrolü
    if (currentLevel > maxLevel) return false

    return categoryList.every((category) => {
      // İsim kontrolü
      if (!category.name?.trim()) return false

      // Level kontrolü
      if (category.level > maxLevel) return false

      // Alt kategorileri kontrol et
      if (category.children?.length) {
        return validateLevel(category.children, currentLevel + 1)
      }
      return true
    })
  }

  return validateLevel(categories)
}

/**
 * Kategori ağacını düzleştirerek (flat) dizi haline getirir.
 * @param {Array} categoryList
 * @param {string|null} parentName
 * @param {Array} result
 * @returns {Array} Düzleştirilmiş kategori listesi
 */
export const flattenCategoryTree = (categoryList, parentName = null, result = []) => {
  categoryList.forEach((category) => {
    result.push({
      name: category.name,
      level: category.level,
      ...(parentName && { parentName }),
    })

    if (category.children?.length) {
      flattenCategoryTree(category.children, category.name, result)
    }
  })
  return result
}