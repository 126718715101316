import { responseHandlerService } from '@/plugins/responseHandlerService'
import { findSimilarKeyword, updateKeywordInList } from '../../utils/keywords/keywordEditUtils'

export function useKeywordEdit(props, emit, t, { selectedKeyword, showEditModal }) {
  /**
   * Düzenleme modalını açar
   * @param {Object} keyword - Düzenlenecek keyword
   */
  const handleEdit = (keyword) => {
    // Seçilen keyword'ü sakla - deep clone ile kopyala
    selectedKeyword.value = {
      ...JSON.parse(JSON.stringify(keyword)),
      categoryIds: Array.isArray(keyword.categoryIds) ?
          [...keyword.categoryIds] :
          [],
    }

    // Modalı göster
    showEditModal.value = true
  }

  /**
   * Keyword düzenleme işlemini tamamlar
   * @param {Object} editedKeyword - Düzenlenmiş keyword
   */
  const handleEditKeyword = (editedKeyword) => {
    // Props.keywords'ün dizi olup olmadığını kontrol et
    if (!Array.isArray(props.keywords)) {
      showErrorMessage('updateError')
      return
    }

    // Benzer keyword kontrolü
    const similarKeyword = findSimilarKeyword(editedKeyword, props.keywords)

    if (similarKeyword) {
      showErrorMessage('duplicateKeyword')
      return
    }

    // categoryIds formatını düzenle
    const formattedKeyword = {
      ...editedKeyword,
      categoryIds: (editedKeyword.categoryIds || []).map(category => ({
        id: category.id || null,
        categoryId: category.categoryId || null,
        state: category.state || 1,
      })),
    }

    // Keyword listesini güncelle
    const updatedKeywords = updateKeywordInList(props.keywords, formattedKeyword)

    // Değişiklikleri emit et
    emit('update:keywords', updatedKeywords)

    // Başarı mesajı göster
    showSuccessMessage(editedKeyword.name)

    // Modalı kapat
    showEditModal.value = false
  }

  /**
   * Başarı mesajı gösterir
   * @param {string} keywordName - Güncellenmiş keyword adı
   */
  const showSuccessMessage = (keywordName) => {
    responseHandlerService.successHandler(
        t(
            'settings.components.configuration.components.keywordsTable.keywordUpdated',
            { keyword: keywordName },
        ),
    )
  }

  /**
   * Hata mesajı gösterir
   * @param {string} errorKey - Hata mesaj anahtarı
   */
  const showErrorMessage = (errorKey) => {
    responseHandlerService.errorHandler(
        t(`settings.components.configuration.components.keywordsTable.${errorKey}`),
    )
  }

  return {
    handleEdit,
    handleEditKeyword,
  }
}