<template>
  <VueFinalModal v-model="showModal" class="flex justify-center items-center" content-class="relative bg-white rounded-xl w-7/12 max-w-full mx-4 p-6 shadow-lg border flex flex-col max-h-screen" :click-to-close="!isLoading" :esc-to-close="!isLoading" @closed="handleModalClosed">
    <!-- Modal Header -->
    <div class="p-4 border-b border-gray-200 bg-gradient-to-r from-primary-50 to-white flex justify-between items-center">
      <div class="flex items-center gap-3">
        <span class="material-symbols-outlined text-primary text-2xl">analytics</span>
        <div>
          <h2 class="text-xl font-bold text-gray-800">
            {{ t('dashboard.components.competitorAnalysis.keywordsModal.title') }} </h2>
          <p class="text-sm text-gray-500 flex items-center">
            <a :href="competitorDomain" target="_blank" rel="noopener" class="text-primary hover:underline flex items-center"> {{ competitorDomain }}
              <span class="material-symbols-outlined text-xs ml-1">open_in_new</span> </a>
          </p>
        </div>
      </div>
      <button @click="closeModal" class="text-gray-500 hover:text-primary p-2 rounded transition-colors">
        <span class="material-symbols-outlined">close</span>
      </button>
    </div>

    <!-- Loading State -->
    <div v-if="isLoading" class="flex-grow flex flex-col items-center justify-center p-6">
      <div class="flex flex-col items-center gap-4">
        <div class="w-16 h-16 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
        <p class="text-gray-600">{{ t('common.loading') }}</p>
      </div>
    </div>

    <!-- Content Container -->
    <template v-else>
      <!-- Analysis Cards -->
      <div class="grid grid-cols-1 md:grid-cols-4 gap-4 p-4 bg-gray-50">
        <div class="bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center">
          <h3 class="text-sm text-gray-500 mb-1">{{ t('dashboard.components.competitorAnalysis.keywordsModal.stats.total') }}</h3>
          <div class="text-2xl font-bold text-gray-800">{{ formatLargeNumber(keywordsData.analysis?.totalKeywords || 0) }}</div>
        </div>

        <div class="bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center">
          <h3 class="text-sm text-gray-500 mb-1">{{ t('dashboard.components.competitorAnalysis.keywordsModal.stats.avgPosition') }}</h3>
          <div class="text-2xl font-bold text-gray-800">{{ formatDecimal(keywordsData.analysis?.avgPosition || 0, 1) }}</div>
        </div>

        <div class="bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center">
          <h3 class="text-sm text-gray-500 mb-1">{{ t('dashboard.components.competitorAnalysis.keywordsModal.stats.topKeywords') }}</h3>
          <div class="text-2xl font-bold text-gray-800">{{ formatLargeNumber(keywordsData.analysis?.topKeywords || 0) }}</div>
        </div>

        <div class="bg-white p-4 rounded-lg shadow-sm border border-gray-100 flex flex-col items-center">
          <h3 class="text-sm text-gray-500 mb-1">{{ t('dashboard.components.competitorAnalysis.keywordsModal.stats.topPositions') }}</h3>
          <div class="text-2xl font-bold text-gray-800">{{ formatLargeNumber(keywordsData.analysis?.topPositionKeywords || 0) }}</div>
        </div>
      </div>

      <!-- Keywords List -->
      <div class="flex-grow p-4 flex flex-col overflow-hidden">
        <!-- Search Bar -->
        <div class="mb-4 flex justify-between items-center">
          <div class="relative w-64">
            <span class="material-symbols-outlined absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400">search</span>
            <input type="text" v-model="searchTerm" :placeholder="t('dashboard.components.competitorAnalysis.keywordsModal.searchPlaceholder')" class="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"/>
          </div>
          <div class="flex gap-2">
            <select v-model="sortBy" class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent text-sm">
              <option value="position">{{ t('dashboard.components.competitorAnalysis.keywordsModal.sortOptions.position') }}</option>
              <option value="searchVolume">{{ t('dashboard.components.competitorAnalysis.keywordsModal.sortOptions.volume') }}</option>
              <option value="difficulty">{{ t('dashboard.components.competitorAnalysis.keywordsModal.sortOptions.difficulty') }}</option>
              <option value="trend">{{ t('dashboard.components.competitorAnalysis.keywordsModal.sortOptions.trend') }}</option>
            </select>
            <button @click="sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'" class="border border-gray-300 rounded-lg px-3 py-2 focus:outline-none hover:bg-gray-50">
              <span class="material-symbols-outlined" :class="{'rotate-180': sortDirection === 'desc'}">
                sort
              </span>
            </button>
          </div>
        </div>

        <!-- No Keywords -->
        <div v-if="!filteredKeywords.length" class="flex flex-col items-center justify-center py-16 text-center">
          <span class="material-symbols-outlined text-4xl text-gray-400 mb-2">search_off</span>
          <p class="text-gray-600 mb-1">{{ t('dashboard.components.competitorAnalysis.keywordsModal.noKeywords.title') }}</p>
          <p class="text-sm text-gray-500 max-w-md">{{ t('dashboard.components.competitorAnalysis.keywordsModal.noKeywords.description') }}</p>
        </div>

        <!-- Keywords List View -->
        <div v-else class="overflow-y-auto max-h-80 thin-scrollbar">
          <!-- Column headers -->
          <div class="grid grid-cols-12 gap-2 px-4 py-2 bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase sticky top-0 z-10">
            <div class="col-span-1">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.rank') }}</div>
            <div class="col-span-3">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.keyword') }}</div>
            <div class="col-span-1 text-center">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.position') }}</div>
            <div class="col-span-2 text-center">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.volume') }}</div>
            <div class="col-span-2 text-center">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.difficulty') }}</div>
            <div class="col-span-2 text-center">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.trend') }}</div>
            <div class="col-span-1 text-center">{{ t('dashboard.components.competitorAnalysis.keywordsModal.table.url') }}</div>
          </div>

          <!-- Keywords list -->
          <div class="divide-y divide-gray-200">
            <div v-for="keyword in filteredKeywords" :key="keyword.keyword" class="grid grid-cols-12 gap-2 px-4 py-3 hover:bg-gray-50 transition-colors items-center">
              <div class="col-span-1 text-sm text-gray-500">{{ keyword.rank }}</div>
              <div class="col-span-3 text-sm font-medium text-gray-900 truncate" :title="keyword.keyword">{{ keyword.keyword }}</div>
              <div class="col-span-1 flex justify-center">
                <div class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium" :class="getPositionClass(keyword.position)">
                  {{ keyword.position }}
                </div>
              </div>
              <div class="col-span-2 text-center text-sm text-gray-500">{{ formatLargeNumber(keyword.searchVolume) }}</div>
              <div class="col-span-2 flex items-center justify-center">
                <div class="w-16 bg-gray-200 rounded-full h-2">
                  <div class="h-2 rounded-full" :class="getDifficultyClass(keyword.difficulty)" :style="{ width: `${keyword.difficulty}%` }"></div>
                </div>
                <span class="ml-2 text-xs text-gray-500">{{ keyword.difficulty }}%</span>
              </div>
              <div class="col-span-2 text-center">
                <div class="inline-flex items-center justify-center text-sm" :class="getTrendClass(keyword.trend)">
                  <span v-if="keyword.trend > 0" class="material-symbols-outlined text-xs mr-1">trending_up</span> <span v-else-if="keyword.trend < 0" class="material-symbols-outlined text-xs mr-1">trending_down</span>
                  <span v-else class="material-symbols-outlined text-xs mr-1">trending_flat</span> {{ keyword.trend > 0 ? '+' : '' }}{{ keyword.trend }}%
                </div>
              </div>
              <div class="col-span-1 text-center">
                <a :href="keyword.url" target="_blank" rel="noopener" class="text-primary hover:underline flex items-center justify-center" :title="keyword.url">
                  <span class="material-symbols-outlined text-sm">link</span> </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div class="border-t border-gray-200 px-4 py-3 flex items-center justify-between">
        <div class="flex-1 flex justify-end">
          <div class="flex gap-2">
            <button @click="changePage(pagination.page - 1)" :disabled="pagination.page === 1" class="px-3 py-1 rounded-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
              {{ t('dashboard.components.competitorAnalysis.keywordsModal.pagination.previous') }}
            </button>
            <button @click="changePage(pagination.page + 1)" :disabled="pagination.page === pagination.pages" class="px-3 py-1 rounded-md border border-gray-300 text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed">
              {{ t('dashboard.components.competitorAnalysis.keywordsModal.pagination.next') }}
            </button>
          </div>
        </div>
      </div>
    </template>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { formatDecimal, formatLargeNumber } from '@/utils/common'

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  competitor: {
    type: Object,
    default: () => ({}),
  },
  assetId: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()
const assetStore = useAssetStore()

// State variables
const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})
const isLoading = ref(false)
const keywordsData = ref({
  keywords: [],
  analysis: {},
  pagination: {
    page: 1,
    limit: 20,
    total: 0,
    pages: 0,
  },
})
const pagination = computed(() => keywordsData.value.pagination || {
  page: 1,
  limit: 20,
  total: 0,
  pages: 0,
})
const searchTerm = ref('')
const sortBy = ref('position')
const sortDirection = ref('asc')

// Computed properties
const competitorDomain = computed(() => {
  return props.competitor?.domain ?
      `https://${props.competitor.domain.replace(/^https?:\/\//, '')}` :
      '#'
})

const filteredKeywords = computed(() => {
  // Filter by search term
  let filtered = keywordsData.value.keywords || []

  if (searchTerm.value) {
    const search = searchTerm.value.toLowerCase()
    filtered = filtered.filter(k =>
        k.keyword.toLowerCase().includes(search) ||
        k.url.toLowerCase().includes(search),
    )
  }

  // Sort by selected column
  filtered = [...filtered].sort((a, b) => {
    let valA = a[sortBy.value]
    let valB = b[sortBy.value]

    // Normalize values for comparison
    if (typeof valA === 'string') valA = valA.toLowerCase()
    if (typeof valB === 'string') valB = valB.toLowerCase()

    // Sort direction
    if (sortDirection.value === 'asc') {
      return valA > valB ? 1 : valA < valB ? -1 : 0
    } else {
      return valA < valB ? 1 : valA > valB ? -1 : 0
    }
  })

  return filtered
})

// Methods
const fetchKeywords = async (page = 1) => {
  if (!props.competitor?.id || !assetStore.assetId) return

  isLoading.value = true
  try {
    const response = await axios.get(dashboard.competitorKeywords, {
      params: {
        competitorId: props.competitor.id,
        assetId: assetStore.assetId,
        page,
        limit: 20,
      },
    })

    keywordsData.value = response.data.data

    // Set default pagination if not provided
    if (!keywordsData.value.pagination) {
      keywordsData.value.pagination = {
        page: 1,
        limit: 20,
        total: keywordsData.value.keywords?.length || 0,
        pages: 1,
      }
    }
  } catch (error) {
    responseHandlerService.handleError(
        t('dashboard.components.competitorAnalysis.keywordsModal.errors.fetchFailed'),
    )
    keywordsData.value = {
      keywords: [],
      analysis: {},
      pagination: {
        page: 1,
        limit: 20,
        total: 0,
        pages: 0,
      },
    }
  } finally {
    isLoading.value = false
  }
}

const changePage = (newPage) => {
  if (newPage < 1 || newPage > pagination.value.pages) return
  fetchKeywords(newPage)
}

const closeModal = () => {
  showModal.value = false
}

const handleModalClosed = () => {
  // Reset state when modal is closed
  searchTerm.value = ''
  sortBy.value = 'position'
  sortDirection.value = 'asc'
  keywordsData.value = {
    keywords: [],
    analysis: {},
    pagination: {
      page: 1,
      limit: 20,
      total: 0,
      pages: 0,
    },
  }
}

// Utility methods for styling
const getPositionClass = (position) => {
  if (position <= 3) return 'bg-green-100 text-green-800'
  if (position <= 10) return 'bg-blue-100 text-blue-800'
  if (position <= 20) return 'bg-yellow-100 text-yellow-800'
  return 'bg-gray-100 text-gray-800'
}

const getDifficultyClass = (difficulty) => {
  if (difficulty < 30) return 'bg-green-500'
  if (difficulty < 60) return 'bg-yellow-500'
  return 'bg-red-500'
}

const getTrendClass = (trend) => {
  if (trend > 0) return 'text-green-600'
  if (trend < 0) return 'text-red-600'
  return 'text-gray-500'
}

// Watch for changes to the modal visibility
watch(() => showModal.value, (newVal) => {
  if (newVal) {
    fetchKeywords()
  }
})

// Watch for changes to the competitor
watch(() => props.competitor, (newVal) => {
  if (showModal.value && newVal?.id) {
    fetchKeywords()
  }
}, { deep: true })
</script>