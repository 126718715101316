<template>
  <div class="relative">
    <!-- Loading Overlay Component -->
    <LoadingOverlay v-if="contentGeneration.isGenerating.value" :message="$t('socialMedia.components.createPost.loadingOverlay.generating')" :description="$t('socialMedia.components.createPost.loadingOverlay.description')" @cancel="contentGeneration.cancelGeneration()"/>

    <!-- Welcome Screen -->
    <div v-if="currentMode === 'welcome'">
      <WelcomeScreen @select-mode="handleModeSelection"/>
    </div>

    <!-- Trend Content List -->
    <div v-else-if="currentMode === 'trend'">
      <TrendContentSection :trends="trendsComposable.trendsList.value" :isLoading="trendsComposable.isLoadingTrends.value" :trendFilterItems="trendFilterItems" v-model:selectedTrendType="trendsComposable.selectedTrendType.value" @select-trend="handleTrendSelection" @trend-filter-change="fetchTrends" @select-mode="handleModeSelection"/>
    </div>

    <!-- Trend Detail Screen -->
    <div v-else-if="currentMode === 'trend-detail'">
      <TrendDetail :trend="trendsComposable.selectedTrend.value" @back-to-trends="currentMode = 'trend'" @select-mode="handleModeSelection" @generate-from-trend="generateFromTrend"/>
    </div>

    <!-- Manual Content Creation -->
    <div v-else-if="currentMode === 'manual' && !contentGeneration.generatedContent.value">
      <ManualContentForm :isGenerating="contentGeneration.isGenerating.value" @select-mode="handleModeSelection" @generate-content="handleGenerateContent"/>
    </div>

    <!-- Content Preview (when content is generated) -->
    <div v-else-if="currentMode === 'preview'" class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <!-- Left Side: Options -->
      <div class="lg:col-span-1">
        <ContentOptions :hasPreviousContent="!!contentGeneration.previousContent.value" :hasLink="!!contentGeneration.generatedContent.value?.link" :hasVideo="hasVideo" @new-content="startNewContent" @trend-content="currentMode = 'trend'" @regenerate="regenerateContent" @restore-previous="contentGeneration.restorePreviousContent()" @edit-text="handleEditContentText" @edit-hashtags="handleEditHashtags" @copy-all="handleCopyAllContent" @copy-link="handleCopyLink" @copy-video-url="handleCopyVideoUrl"/>
      </div>

      <!-- Right Side: Content Preview (wider) -->
      <div class="lg:col-span-2">
        <ContentPreview :generatedContent="contentGeneration.generatedContent.value" :selectedPlatforms="selectedPlatforms" :isGenerating="contentGeneration.isGenerating.value" :isSaving="contentManagement.isSaving.value" :isPublishing="contentManagement.isPublishing.value" :isSaved="contentManagement.isSaved.value" :isPublished="contentManagement.isPublished.value" :analytics="contentManagement.contentAnalytics.value" @edit-content-text="handleEditContentText" @edit-hashtags="handleEditHashtags" @save-content="handleSaveContent" @publish-content="handlePublishContent"/>
      </div>
    </div>

    <!-- Content Success Screen -->
    <div v-else-if="currentMode === 'content-result'">
      <ContentResult :isPublished="contentManagement.isPublished.value" :isSaved="contentManagement.isSaved.value" @new-content="startNewContent" @publish-content="handlePublishContent" @view-analytics="navigateToAnalytics"/>
    </div>

    <!-- Edit Content Text Modal -->
    <Modal v-model="showEditTextModal" :title="$t('socialMedia.components.createPost.modals.editText.title')">
      <div class="p-4">
        <textarea v-model="editedContentText" class="w-full min-h-[200px] p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary" :placeholder="$t('socialMedia.components.createPost.modals.editText.placeholder')"></textarea>

        <div class="flex justify-end mt-4 space-x-3">
          <button @click="showEditTextModal = false" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
            {{ $t('socialMedia.components.createPost.general.cancel') }}
          </button>
          <button @click="saveEditedText" class="px-4 py-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primary-dark">
            {{ $t('socialMedia.components.createPost.general.save') }}
          </button>
        </div>
      </div>
    </Modal>

    <!-- Edit Hashtags Modal -->
    <Modal v-model="showEditHashtagsModal" :title="$t('socialMedia.components.createPost.modals.editHashtags.title')">
      <div class="p-4">
        <div class="mb-4">
          <label class="block text-sm font-medium text-gray-700 mb-2"> {{ $t('socialMedia.components.createPost.modals.editHashtags.label') }} </label>
          <p class="text-xs text-gray-500 mb-3">
            {{ $t('socialMedia.components.createPost.modals.editHashtags.description') }} </p>

          <TagInput v-model="editedHashtags" :placeholder="$t('socialMedia.components.createPost.modals.editHashtags.placeholder')" :maxCount="20" :showSearchandFileUpload="false" color="primary" containerClass="bg-white rounded-md shadow-sm"/>
        </div>

        <div class="flex justify-end mt-4 space-x-3">
          <button @click="showEditHashtagsModal = false" class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50">
            {{ $t('socialMedia.components.createPost.general.cancel') }}
          </button>
          <button @click="saveEditedHashtags" class="px-4 py-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primary-dark">
            {{ $t('socialMedia.components.createPost.general.save') }}
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'

// Import Composables
import { useContentGeneration } from './composables/useContentGeneration/useContentGeneration'
import { useTrends } from './composables/useTrends'
import { useContentManagement } from './composables/useContentManagement'
import { useClipboard } from './composables/useClipboard'

// Import Components
import WelcomeScreen from './components/WelcomeScreen.vue'
import TrendContentSection from './components/TrendContentSection.vue'
import TrendDetail from './components/TrendDetail.vue'
import ManualContentForm from './components/ManualContentForm.vue'
import ContentPreview from './components/contentPreview/index.vue'
import ContentResult from './components/ContentResult.vue'
import ContentOptions from './components/ContentOptions.vue'
import LoadingOverlay from './components/LoadingOverlay.vue'
import Modal from '@/components/modals/VModal.vue'
import TagInput from '@/components/base/inputs/VTagInput.vue'

// i18n, store, and router
const { t } = useI18n()
const assetStore = useAssetStore()
const router = useRouter()

// App state
const currentMode = ref('welcome')
const selectedPlatforms = ref([])
const editedContentText = ref('')
const editedHashtags = ref([])
const showEditTextModal = ref(false)
const showEditHashtagsModal = ref(false)

// Initialize composables - Do NOT use toRefs here
const contentGeneration = useContentGeneration()
const trendsComposable = useTrends()
const contentManagement = useContentManagement()
const clipboard = useClipboard()

// Use the composables directly
const trendFilterItems = computed(() => trendsComposable.getTrendFilterItems(t))

// Computed to check if there's video content
const hasVideo = computed(() => {
  const content = contentGeneration.generatedContent.value
  if (!content) return false

  return (
      content.hasVideo === true ||
      content.videoUrl ||
      (content.link && isVideoLink(content.link)) ||
      (content.media && content.media.some(m => isVideo(m)))
  )
})

// Helper functions for video detection
const isVideoLink = (link) => {
  if (!link) return false

  const url = link.toLowerCase()

  // Check video extensions
  const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.wmv']
  const hasVideoExtension = videoExtensions.some(ext => url.endsWith(ext))

  // Check video hosting services
  const isVideoHosting = url.includes('youtube.com/watch') ||
      url.includes('youtu.be/') ||
      url.includes('vimeo.com/') ||
      url.includes('/video/') ||
      url.includes('/videos/') ||
      url.includes('tiktok.com')

  return hasVideoExtension || isVideoHosting
}

const isVideo = (media) => {
  if (!media) return false

  if (media.type && media.type.includes('video')) {
    return true
  }

  // Check URL extensions
  const url = media.url || media.preview || ''
  const videoExtensions = ['.mp4', '.mov', '.avi', '.webm', '.mkv', '.flv', '.wmv']
  return videoExtensions.some(ext => url.toLowerCase().endsWith(ext))
}

// Get the video URL from content
const getVideoUrl = () => {
  const content = contentGeneration.generatedContent.value
  if (!content) return null

  // Check for specific video URL
  if (content.videoUrl) return content.videoUrl

  // Check if the link is a video
  if (content.link && isVideoLink(content.link)) return content.link

  // Check for video in media
  if (content.media) {
    const videoMedia = content.media.find(m => isVideo(m))
    if (videoMedia) return videoMedia.url || videoMedia.preview
  }

  return null
}

// Event handlers
const handleModeSelection = (mode) => {
  currentMode.value = mode
  if (mode === 'trend') {
    fetchTrends()
  }
}

const handleTrendSelection = (trend) => {
  trendsComposable.selectTrend(trend)
  currentMode.value = 'trend-detail'
}

// Start new content - completely reset content
const startNewContent = () => {
  contentGeneration.resetContent()
  contentManagement.resetContentState()
  selectedPlatforms.value = []
  currentMode.value = 'welcome'
}

// Generate content from trend
const generateFromTrend = async (data) => {
  selectedPlatforms.value = data.selectedPlatforms
  const result = await contentGeneration.generateFromTrend(data, assetStore)

  if (result) {
    currentMode.value = 'preview'
  }
}

// Handle generate content from manual form
const handleGenerateContent = async (formData) => {
  selectedPlatforms.value = formData.platforms
  const result = await contentGeneration.generateManualContent(formData, assetStore)

  if (result) {
    currentMode.value = 'preview'
  }
}

// Regenerate content
const regenerateContent = async () => {
  await contentGeneration.regenerateContent(assetStore)
}

// Fetch trends
const fetchTrends = async () => {
  await trendsComposable.fetchTrends(assetStore.assetId, t('socialMedia.components.createPost.errors.fetchTrends'))
}

// Handle save content
const handleSaveContent = async () => {
  if (!contentGeneration.generatedContent.value) return

  // If content has a video URL but no link, set the link to the video URL for saving
  const content = contentGeneration.generatedContent.value
  if (content.videoUrl && !content.link) {
    content.link = content.videoUrl
  }

  const success = await contentManagement.saveContent(
      contentGeneration.generatedContent.value,
      assetStore.assetId,
      selectedPlatforms.value,
  )

  if (success) {
    currentMode.value = 'content-result'
  }
}

// Handle publish content
const handlePublishContent = async () => {
  const success = await contentManagement.publishContent(
      assetStore.assetId,
      selectedPlatforms.value,
  )

  if (success) {
    currentMode.value = 'content-result'
  }
}

// Handle copy all content
const handleCopyAllContent = () => {
  if (contentGeneration.generatedContent.value) {
    clipboard.copyAllContent(contentGeneration.generatedContent.value)
  }
}

// Handle copy link
const handleCopyLink = () => {
  if (contentGeneration.generatedContent.value && contentGeneration.generatedContent.value.link) {
    clipboard.copyLink(contentGeneration.generatedContent.value)
  }
}

// New method to handle copy video URL
const handleCopyVideoUrl = () => {
  const videoUrl = getVideoUrl()
  if (videoUrl) {
    clipboard.copy(videoUrl)
  }
}

// Handle edit content text
const handleEditContentText = () => {
  editedContentText.value = contentGeneration.generatedContent.value?.message || ''
  showEditTextModal.value = true
}

// Save edited text
const saveEditedText = () => {
  if (contentGeneration.generatedContent.value) {
    contentGeneration.generatedContent.value.message = editedContentText.value
    contentManagement.isSaved.value = false
  }
  showEditTextModal.value = false
}

// Handle edit hashtags
const handleEditHashtags = () => {
  // Convert hashtags to tag input format
  editedHashtags.value = contentGeneration.generatedContent.value?.hashtags?.map(tag => {
    return { name: tag.replace('#', '') }
  }) || []

  showEditHashtagsModal.value = true
}

// Save edited hashtags
const saveEditedHashtags = () => {
  if (contentGeneration.generatedContent.value) {
    // Convert tag input format to hashtags format
    contentGeneration.generatedContent.value.hashtags = editedHashtags.value.map(tag => {
      const tagText = typeof tag === 'string' ? tag : tag.name
      return tagText.startsWith('#') ? tagText : `#${tagText}`
    })
    contentManagement.isSaved.value = false
  }
  showEditHashtagsModal.value = false
}

// Navigate to analytics
const navigateToAnalytics = () => {
  router.push({ name: 'social-media-analytics', params: { id: contentManagement.contentId.value } })
}
</script>