import * as XLSX from 'xlsx'

/**
 * Excel dosyasını okur
 * @param {File} file - Excel dosyası
 * @returns {Promise<Object>} XLSX workbook objesi
 */
export async function readExcelFile(file) {
  try {
    const buffer = await file.arrayBuffer()
    return XLSX.read(buffer, {
      type: 'array',
      cellDates: true,
      cellStyles: true,
    })
  } catch (error) {
    throw new Error('Excel reading error: ' + error.message)
  }
}

/**
 * Belirli bir sayfayı JSON formatına dönüştürür
 * @param {Object} workbook - XLSX workbook objesi
 * @param {string} sheetName - Sayfa adı
 * @param {Object} options - Dönüştürme seçenekleri
 * @returns {Array} JSON verisi
 */
export function worksheetToJson(workbook, sheetName, options = { header: 1 }) {
  const worksheet = workbook.Sheets[sheetName]
  if (!worksheet) return []
  return XLSX.utils.sheet_to_json(worksheet, options)
}

/**
 * Excel dosyasındaki belirli bir sayfayı doğrular
 * @param {Object} workbook - XLSX workbook objesi
 * @param {string} sheetName - Sayfa adı
 * @returns {boolean} Sayfa geçerli mi?
 */
export function validateWorksheetExists(workbook, sheetName) {
  return workbook &&
      workbook.SheetNames &&
      workbook.SheetNames.includes(sheetName) &&
      workbook.Sheets[sheetName]
}

/**
 * Excel sayfasının boş olup olmadığını kontrol eder
 * @param {Array} rows - Sayfa satırları
 * @returns {boolean} Sayfa boş mu?
 */
export function isWorksheetEmpty(rows) {
  return !rows || rows.length <= 1
}

/**
 * Başlık satırı dışındaki içeriği döndürür
 * @param {Array} rows - Tüm satırlar
 * @returns {Array} Başlık satırı dışındaki satırlar
 */
export function getContentRowsWithoutHeader(rows) {
  if (!rows || rows.length <= 1) return []

  // Başlık satırını atla, geri kalan tüm satırlardan içeriği olan satırları filtreleyerek al
  return rows.slice(1).filter(row =>
      row && row[0] && row[0].toString().trim() !== '',
  )
}

/**
 * Categories sayfasından kategori verilerini çıkarır
 * @param {Object} workbook - XLSX workbook objesi
 * @returns {Object} Kategori ID'leri ve isimleri
 */
export function extractCategories(workbook) {
  if (!validateWorksheetExists(workbook, 'Categories')) {
    return {}
  }

  const rows = worksheetToJson(workbook, 'Categories')

  // İlk satır başlık satırı, onu atlıyoruz
  if (rows.length <= 1) {
    return {}
  }

  const categoryMap = {}

  // Başlık satırını atlayarak kategori bilgilerini işle
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i]
    if (row && row.length >= 2 && row[0]) {
      const categoryId = row[0].toString().trim()
      const categoryName = row[1]?.toString().trim() || ''

      if (categoryId) {
        categoryMap[categoryId] = categoryName
      }
    }
  }

  return categoryMap
}

/**
 * Excel satırındaki hücre değerini güvenli bir şekilde alır
 * @param {Array} row - Excel satırı
 * @param {number} index - Hücre indeksi
 * @returns {string} Hücre değeri
 */
export function getCellValue(row, index) {
  if (!row || !row[index]) return ''
  return row[index].toString().trim()
}

/**
 * Excel satırındaki hücre değerini sayıya dönüştürür
 * @param {Array} row - Excel satırı
 * @param {number} index - Hücre indeksi
 * @returns {number|null} Sayı değeri veya null
 */
export function getNumericCellValue(row, index) {
  const value = getCellValue(row, index)
  if (!value) return null

  const numValue = Number(value)
  return isNaN(numValue) ? null : numValue
}

/**
 * Geçerli bir Excel satırı olup olmadığını kontrol eder
 * @param {Array} row - Excel satırı
 * @param {number} minColumns - Minimum sütun sayısı
 * @returns {boolean} Geçerli satır mı?
 */
export function isValidRow(row, minColumns = 1) {
  return Array.isArray(row) &&
      row.length >= minColumns &&
      row.some(cell => cell !== null && cell !== undefined && cell.toString().trim() !== '')
}

/**
 * Categories sayfasından geçerli kategori ID'lerini çıkarır
 * @param {Object} workbook - XLSX workbook objesi
 * @returns {Set} Geçerli kategori ID'leri seti
 */
export function extractValidCategoryIds(workbook) {
  const validIds = new Set()

  if (!validateWorksheetExists(workbook, 'Categories')) {
    return validIds
  }

  const rows = worksheetToJson(workbook, 'Categories')
  if (rows.length <= 1) return validIds

  // Başlık satırını atla ve kategori ID'lerini işle
  for (let i = 1; i < rows.length; i++) {
    const row = rows[i]
    if (row && row[0]) {
      const categoryId = row[0].toString().trim()
      const numericId = Number(categoryId)

      if (!isNaN(numericId)) {
        validIds.add(categoryId)
      }
    }
  }

  return validIds
}

/**
 * Excel sayfasını belirtilen formatta JSON'a dönüştürür
 * @param {Object} worksheet - XLSX worksheet objesi
 * @param {Object} options - Dönüştürme seçenekleri
 * @returns {Array} Dönüştürülmüş veriler
 */
export function formatWorksheetToJson(worksheet, options = {}) {
  const defaultOptions = {
    header: 1,
    raw: false,
    dateNF: 'yyyy-mm-dd',
    blankrows: false,
    ...options,
  }

  return XLSX.utils.sheet_to_json(worksheet, defaultOptions)
}

/**
 * Sayfa adına göre worksheet objesi alır
 * @param {Object} workbook - XLSX workbook objesi
 * @param {string} sheetName - Sayfa adı
 * @returns {Object|null} Worksheet objesi veya null
 */
export function getWorksheet(workbook, sheetName) {
  if (!validateWorksheetExists(workbook, sheetName)) {
    return null
  }
  return workbook.Sheets[sheetName]
}

/**
 * Kategori ilişkilerini Excel formatına dönüştürür
 * @param {Array} categoryIds - Kategori ID'leri dizisi
 * @returns {string} Excel hücresi için formatlanmış string
 */
export function formatCategoryIdsForExcel(categoryIds = []) {
  if (!Array.isArray(categoryIds)) return ''

  return categoryIds
      .map(cat => cat?.categoryId || cat?.id || cat)
      .filter(id => id !== null && id !== undefined)
      .join(',')
}