<template>
  <div class="flex flex-col">
    <div class="custom-multi-select relative" ref="reference">
      <button type="button" @click="toggleDropdown" :class="[
          'flex items-center justify-between border bg-white rounded-md focus:outline-none w-full',
          sizeClasses,
          error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-gray-500 focus:ring-1 focus:ring-gray-500'
        ]">
        <span class="truncate">{{ displayText }}</span>
        <div class="flex items-center">
          <span v-if="clearable && activeSelectedCount > 0" class="material-symbols-outlined text-gray-400 hover:text-gray-600 mr-1 cursor-pointer" :class="[iconSizeClass]" @click.stop="clearSelection">
            close
          </span> <span :class="['material-symbols-outlined text-gray-400', iconSizeClass]">
            expand_more
          </span>
        </div>
      </button>

      <Teleport to="body">
        <div v-if="isOpen" ref="floating" :style="{
          position: strategy,
          top: y ? `${y}px` : '',
          left: x ? `${x}px` : '',
          width: referenceWidth + 'px',
          zIndex: 10000,
        }" class="bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto thin-scrollbar">
          <ul @click.stop>
            <li v-for="option in options" :key="option.value" @click.stop="toggleOption(option)" :class="[
                'hover:bg-gray-100 cursor-pointer',
                sizeClasses,
                'flex items-center'
              ]">
              <div class="flex items-center w-full">
                <input type="checkbox" :checked="isSelectedActive(option.value)" class="mr-2 rounded border-gray-300" :class="[checkboxSizeClass]" @click.stop="toggleOption(option)"/>
                <span class="truncate">{{ option.label }}</span>
              </div>
            </li>
            <li v-if="options.length === 0" :class="['text-gray-500 text-center py-2', sizeClasses]">
              {{ noOptionsMessage }}
            </li>
          </ul>
        </div>
      </Teleport>
    </div>

    <div v-if="showError" class="h-3">
      <div v-if="error" class="flex items-start gap-1">
        <span class="material-symbols-outlined text-red-500 !text-[14px] mt-0.5">error</span>
        <p class="text-xs text-red-500">{{ error }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
import { computePosition, flip, shift, offset } from '@floating-ui/dom'

const STATE = {
  EXISTING: 1,
  NEW: 2,
  REMOVED: 3,
}

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  options: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: 'Select options',
  },
  error: {
    type: String,
    default: '',
  },
  showError: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  noOptionsMessage: {
    type: String,
    default: 'No options available',
  },
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  selectedLabelFormat: {
    type: Function,
    default: (count) => `${count} selected`,
  },
})

const emit = defineEmits(['update:modelValue', 'clear'])

// UI State
const isOpen = ref(false)
const reference = ref(null)
const floating = ref(null)
const x = ref(0)
const y = ref(0)
const strategy = ref('absolute')
const referenceWidth = ref(0)

// Selection Logic
const selectedValues = computed(() => Array.isArray(props.modelValue) ? props.modelValue : [])

const activeSelectedValues = computed(() => {
  return selectedValues.value.filter(item => {
    if (typeof item === 'object' && item !== null) {
      const state = item.state !== undefined ? item.state : STATE.NEW
      return state === STATE.EXISTING || state === STATE.NEW
    }
    return true
  })
})

const activeSelectedCount = computed(() => activeSelectedValues.value.length)

const activeSelectedOptions = computed(() => {
  return props.options.filter(option => isSelectedActive(option.value))
})

const displayText = computed(() => {
  if (activeSelectedCount.value === 0) {
    return props.placeholder
  } else if (activeSelectedCount.value === 1) {
    return activeSelectedOptions.value[0]?.label || props.placeholder
  } else {
    return props.selectedLabelFormat(activeSelectedCount.value)
  }
})

// Style Classes
const sizeClasses = computed(() => {
  const sizes = {
    sm: 'text-xs py-1.5 px-2',
    md: 'text-sm py-2 px-3',
    lg: 'text-base py-2.5 px-3',
  }
  return sizes[props.size] || sizes.md
})

const checkboxSizeClass = computed(() => {
  const sizes = {
    sm: 'h-3 w-3',
    md: 'h-4 w-4',
    lg: 'h-5 w-5',
  }
  return sizes[props.size] || sizes.md
})

const iconSizeClass = computed(() => {
  const sizes = {
    sm: '!text-[16px]',
    md: '!text-[18px]',
    lg: '!text-[20px]',
  }
  return sizes[props.size] || sizes.md
})

// Selection Methods
const isSelectedActive = (value) => {
  return selectedValues.value.some(selectedValue => {
    if (typeof selectedValue === 'object' && selectedValue !== null) {
      const valueMatch = selectedValue.value === value
      const state = selectedValue.state !== undefined ? selectedValue.state : STATE.NEW
      return valueMatch && (state === STATE.EXISTING || state === STATE.NEW)
    }
    return selectedValue === value
  })
}

// Helper function to create a new option
const createNewOption = (optionValue) => ({
  id: null,
  value: optionValue,
  state: STATE.NEW,
})

// Helper function to get the next state for an existing option
const getNextState = (currentState) => {
  switch (currentState) {
    case STATE.EXISTING:
      return STATE.REMOVED
    case STATE.REMOVED:
      return STATE.EXISTING
    default:
      return null // Indicates item should be removed
  }
}

// Helper function to update existing option
const updateExistingOption = (currentValue, existingIndex) => {
  const existing = currentValue[existingIndex]

  if (typeof existing !== 'object') {
    currentValue.splice(existingIndex, 1)
    return currentValue
  }

  const nextState = getNextState(existing.state)
  if (nextState === null) {
    currentValue.splice(existingIndex, 1)
  } else {
    existing.state = nextState
  }

  return currentValue
}

// Main toggle function
const toggleOption = (option) => {
  const currentValue = [...selectedValues.value]
  const existingIndex = currentValue.findIndex(item =>
      (typeof item === 'object' ? item.value : item) === option.value,
  )

  if (existingIndex === -1) {
    currentValue.push(createNewOption(option.value))
  } else {
    updateExistingOption(currentValue, existingIndex)
  }

  emit('update:modelValue', currentValue)
}

const clearSelection = () => {
  const newValue = selectedValues.value.reduce((acc, item) => {
    if (typeof item === 'object' && item !== null) {
      if (item.state === STATE.EXISTING) {
        // EXISTING durumundaki öğeleri REMOVED'a çevir
        acc.push({ ...item, state: STATE.REMOVED })
      } else if (item.state === STATE.REMOVED) {
        // REMOVED durumundaki öğeleri aynen koru
        acc.push(item)
      }
      // STATE.NEW durumundaki öğeleri dahil etme
    }
    return acc
  }, [])

  emit('update:modelValue', newValue)
  emit('clear')
}

// Position Management
const updatePosition = async () => {
  if (!reference.value || !floating.value) return

  referenceWidth.value = reference.value.offsetWidth

  const { x: newX, y: newY, strategy: newStrategy } = await computePosition(
      reference.value,
      floating.value,
      {
        placement: 'bottom-start',
        middleware: [offset(4), flip(), shift()],
      },
  )

  x.value = newX
  y.value = newY
  strategy.value = newStrategy
}

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
  if (isOpen.value) {
    nextTick(() => {
      updatePosition()
    })
  }
}

// Event Handlers
const handleClickOutside = (event) => {
  if (!isOpen.value) return

  if (!reference.value || !floating.value) {
    isOpen.value = false
    return
  }

  const path = event.composedPath && event.composedPath()
  const clickedInside = path
      ? path.includes(reference.value) || path.includes(floating.value)
      : reference.value.contains(event.target) || floating.value.contains(event.target)

  if (!clickedInside) {
    isOpen.value = false
  }
}

const handleEscKey = (e) => {
  if (e.key === 'Escape' && isOpen.value) {
    isOpen.value = false
  }
}

// Lifecycle
onMounted(() => {
  document.addEventListener('click', handleClickOutside, true)
  document.addEventListener('scroll', updatePosition, true)
  document.addEventListener('keydown', handleEscKey)

  const resizeObserver = new ResizeObserver(() => {
    if (isOpen.value) updatePosition()
  })

  if (reference.value) resizeObserver.observe(reference.value)

  onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside, true)
    document.removeEventListener('scroll', updatePosition, true)
    document.removeEventListener('keydown', handleEscKey)
    resizeObserver.disconnect()
  })
})

// Watchers
watch(() => props.options, () => {
  if (isOpen.value) {
    nextTick(() => {
      updatePosition()
    })
  }
}, { deep: true })
</script>