import { KeywordUtils } from '@/utils/keywordUtils'

/**
 * Bir keyword'ün düzenlenip düzenlenmediğini kontrol eder
 * @param {Object} original - Orijinal keyword
 * @param {Object} edited - Düzenlenmiş keyword
 * @returns {boolean} Değişiklik var mı?
 */
export function hasKeywordChanged(original, edited) {
  // Name veya priority değişimi kontrolü
  const nameChanged = original.name !== edited.name
  const priorityChanged = original.priority !== edited.priority

  // Kategori değişimlerini detaylı kontrol et
  const originalCategories = original.categoryIds || []
  const editedCategories = edited.categoryIds || []

  // Her kategorinin state değişimini kontrol et
  const categoriesChanged = originalCategories.some(origCat => {
    // Aynı categoryId'ye sahip kategoriyi bul
    const editedCat = editedCategories.find(
        editCat => editCat.categoryId === origCat.categoryId,
    )

    // Eğer kategori bulunamadıysa değişiklik var demektir
    if (!editedCat) return true

    // State değişimini kontrol et
    return origCat.state !== editedCat.state
  })

  // Yeni eklenen kategorileri kontrol et
  const hasNewCategories = editedCategories.some(editCat =>
      !originalCategories.find(origCat => origCat.categoryId === editCat.categoryId),
  )

  return nameChanged || priorityChanged || categoriesChanged || hasNewCategories
}


/**
 * Düzenlenen keyword için benzer var mı kontrol eder
 * @param {Object} editedKeyword - Düzenlenen keyword
 * @param {Array} keywords - Mevcut keyword dizisi
 * @returns {Object|null} Bulunan benzer keyword veya null
 */
export function findSimilarKeyword(editedKeyword, keywords) {
  // Array olup olmadığını kontrol et
  if (!Array.isArray(keywords)) {
    return null
  }

  const filteredKeywords = keywords.filter(k =>
      k.state !== 3 &&
      ((k.id && k.id !== editedKeyword.id) ||
          (k.keywordId && k.keywordId !== editedKeyword.keywordId) ||
          (k.state === 2 && k.name !== editedKeyword.name)),
  )

  return KeywordUtils.findSimilarInArray(
      editedKeyword.name,
      filteredKeywords,
  )
}

/**
 * Değişen keyword için yeni state belirler
 * @param {Object} keyword - Orijinal keyword
 * @param {Object} editedKeyword - Düzenlenmiş keyword
 * @returns {number} Yeni state değeri
 */
export function determineNewState(keyword, editedKeyword) {
  const isChanged = hasKeywordChanged(keyword, editedKeyword)

  // State 1 olan keyword değiştiyse state 4'e çevrilmeli
  return keyword.state === 1 && isChanged ? 4 : keyword.state
}

/**
 * Keyword'ün düzenleme sonrası versiyonunu oluşturur
 * @param {Object} original - Orijinal keyword
 * @param {Object} edited - Düzenlenmiş keyword
 * @returns {Object} Güncellenmiş keyword
 */
export function createUpdatedKeyword(original, edited) {
  // State değişimini belirle
  const newState = determineNewState(original, edited)

  // Category ID'leri koru
  return {
    ...original,
    name: edited.name.trim(),
    priority: edited.priority,
    categoryIds: edited.categoryIds || [],
    state: newState,
  }
}

/**
 * Belirli bir keyword'ün düzenlenmesiyle oluşan yeni keyword dizisini oluşturur
 * @param {Array} keywords - Tüm keywordler
 * @param {Object} editedKeyword - Düzenlenen keyword
 * @returns {Array} Güncellenmiş keyword dizisi
 */
export function updateKeywordInList(keywords, editedKeyword) {
  // Array olup olmadığını kontrol et
  if (!Array.isArray(keywords)) {
    return Array.isArray(keywords) ? keywords : []
  }

  return keywords.map((keyword) => {
    const shouldUpdate = (
        (keyword.id && keyword.id === editedKeyword.id) ||
        (keyword.keywordId && keyword.keywordId === editedKeyword.keywordId) ||
        (keyword.state === 2 && keyword.name === editedKeyword.name)
    )

    if (shouldUpdate) {
      return createUpdatedKeyword(keyword, editedKeyword)
    }
    return keyword
  })
}