<template>
  <div class="border-t border-gray-200">
    <!-- Yorum Sekmesi Başlığı -->
    <div class="px-5 py-3 bg-white flex items-center border-b border-gray-200">
      <div class="flex items-center">
        <span class="material-symbols-outlined text-gray-400 !text-[20px] mr-2">comment</span>
        <h3 class="font-medium text-sm text-gray-800 flex items-center">
          {{ t('socialMedia.components.postInspector.comments') }} <span class="ml-1 text-gray-400">{{ commentCount }}</span>
        </h3>
      </div>
    </div>

    <!-- Yorumların Gösterildiği Alan -->
    <div class="p-5">
      <!-- Yükleniyor -->
      <div v-if="isLoading" class="flex items-center justify-center py-10">
        <div class="flex flex-col items-center gap-2">
          <div class="w-8 h-8 rounded-full border-2 border-gray-300 border-t-transparent animate-spin"></div>
          <span class="text-gray-500 text-sm">{{ t('socialMedia.components.postInspector.comment.loading') }}</span>
        </div>
      </div>

      <!-- Yorum Yok -->
      <div v-else-if="!comments.length" class="flex flex-col items-center justify-center py-12 text-center">
        <div class="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mb-3">
          <span class="material-symbols-outlined text-gray-400 !text-[24px]">comment</span>
        </div>
        <p class="text-gray-700 text-sm font-medium">{{ t('socialMedia.components.postInspector.comment.noComment') }}</p>
        <p class="text-gray-500 text-xs mt-2 max-w-xs">{{ t('socialMedia.components.postInspector.comment.noCommentHelp') }}</p>
      </div>

      <!-- Yorum Listesi -->
      <div v-else>
        <!-- Her Yorum -->
        <div v-for="comment in comments" :key="comment.commentId" class="mb-4">
          <!-- Her yorum ve yanıtlarını göster -->
          <div v-for="(message, index) in comment.messages" :key="index">
            <!-- Kullanıcı Yorumu -->
            <div v-if="message.writerType === 1" class="mb-3">
              <div class="flex gap-3 mb-2">
                <div class="w-8 h-8 bg-gray-100 rounded-full flex-shrink-0 flex items-center justify-center">
                  <img src="@/assets/navbar/avatar.png" alt="User" class="w-full h-full rounded-full object-cover"/>
                </div>
                <div>
                  <div class="font-medium text-sm text-gray-800">{{ t('socialMedia.components.postInspector.comment.user') }}</div>
                  <div class="text-xs text-gray-500">{{ formatCommentDate(message.createdDate) }}</div>
                </div>
              </div>
              <div class="ml-11 text-sm text-gray-700">
                {{ message.message }}
              </div>
            </div>

            <!-- Şirket/AI Yanıtı -->
            <div v-else-if="message.writerType === 2" class="pl-8 mb-3">
              <div class="flex gap-3 mb-2">
                <div class="w-7 h-7 bg-primary/10 rounded-full flex-shrink-0 flex items-center justify-center">
                  <img src="@/assets/logo/logoB.svg" alt="AI" class="w-4 h-4"/>
                </div>
                <div>
                  <div class="font-medium text-xs text-primary">{{ t('socialMedia.components.postInspector.comment.company') }}</div>
                  <div class="text-xs text-gray-500">{{ formatCommentDate(message.createdDate) }}</div>
                </div>
              </div>
              <div class="ml-10 text-sm text-gray-700">
                {{ message.message }}
              </div>
            </div>
          </div>

          <!-- Yorum ayırıcı -->
          <div class="border-b border-gray-100 my-4"></div>
        </div>

        <!-- Yanıt Formu -->
        <div class="mt-4">
          <div class="flex items-center gap-2">
            <div class="w-7 h-7 bg-primary/10 rounded-full flex-shrink-0 flex items-center justify-center">
              <img src="@/assets/logo/logoB.svg" alt="Company" class="w-4 h-4"/>
            </div>
            <div class="flex-grow relative">
              <input type="text" :placeholder="t('socialMedia.components.postInspector.comment.replyPlaceholder')" v-model="replyText" class="w-full py-2 px-3 pr-8 bg-white border border-gray-200 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-primary/20 focus:border-primary" @keyup.enter="submitReply"/>
              <button class="absolute right-2 top-1/2 transform -translate-y-1/2 w-6 h-6 flex items-center justify-center bg-primary text-white rounded-full" @click="submitReply">
                <span class="material-symbols-outlined !text-[14px]">send</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/tr'
import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'

dayjs.extend(relativeTime)

const { t } = useI18n()
const assetStore = useAssetStore()

const props = defineProps({
  postId: {
    type: [Number, String],
    required: true,
  },
})

const emit = defineEmits(['comments-loaded'])

const comments = ref([])
const isLoading = ref(true)
const replyText = ref('')

// Yorum sayısı
const commentCount = computed(() => {
  return comments.value.reduce((total, comment) => {
    return total + comment.messages.length
  }, 0)
})

// Yorumları getir
async function fetchComments() {
  isLoading.value = true
  try {
    const response = await axios.get(socialMedia.commentsGetAll, {
      params: {
        postId: props.postId,
        assetId: assetStore.assetId,
        platform: 1, // Üretimde 2 olacak
      },
    })
    comments.value = response.data.data || []
    emit('comments-loaded', commentCount.value)
  } catch (error) {
    console.error('Error fetching comments:', error)
    comments.value = []
    emit('comments-loaded', 0)
  } finally {
    isLoading.value = false
  }
}

// Yanıt gönder
function submitReply() {
  if (!replyText.value.trim()) return

  console.log('Sending reply:', replyText.value)
  // Burada yanıt gönderme API çağrısı yapılacak
  replyText.value = ''
}

// Yorum tarihi formatla
function formatCommentDate(dateString) {
  if (!dateString) return ''
  const locale = document.documentElement.lang || 'tr'
  return dayjs(dateString).locale(locale).fromNow()
}

onMounted(fetchComments)
</script>