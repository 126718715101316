import { responseHandlerService } from '@/plugins/responseHandlerService'

export function initializeResults() {
  return {
    new: 0,
    reactivated: 0,
    updated: 0,
    unchanged: 0,
    invalid: 0,
    priorityNormalized: 0,
    skipped: 0,
    details: {
      newKeywords: [],
      updatedKeywords: [],
      unchangedKeywords: [],
      skippedKeywords: [],
      reactivatedKeywords: [],
      priorityNormalizedKeywords: [],
      invalidKeywords: [],
    },
  }
}

export function updateResultsByType(results, result, rowInfo) {
  if (!result || !results) return

  switch (result.type) {
    case 'new':
      results.new++
      results.details.newKeywords.push({ ...result.keyword, rowInfo })
      break
    case 'updated':
      results.updated++
      results.details.updatedKeywords.push({ ...result.keyword, rowInfo })
      break
    case 'reactivated':
      results.reactivated++
      results.details.reactivatedKeywords.push({ ...result.keyword, rowInfo })
      break
    case 'unchanged':
      results.unchanged++
      results.details.unchangedKeywords.push({ ...result.keyword, rowInfo })
      break
    case 'invalid':
      results.invalid++
      results.details.invalidKeywords.push({ reason: result.reason, rowInfo })
      break
  }

  if (result.priorityChange?.wasNormalized) {
    results.priorityNormalized++
    results.details.priorityNormalizedKeywords.push({
      ...result.keyword,
      originalPriority: result.priorityChange.original,
      normalizedPriority: result.priorityChange.normalized,
      rowInfo,
    })
  }
}

export function addInvalidResult(results, reason, rowInfo, invalidIds = []) {
  results.invalid++
  results.details.invalidKeywords.push({
    reason,
    rowInfo,
    invalidIds: invalidIds.length > 0 ? invalidIds : undefined,
  })
}

export function addSkippedResult(results, keyword, reason) {
  results.skipped++
  results.details.skippedKeywords.push({
    ...keyword,
    reason,
  })
}

export function showImportResults(results, t) {
  let hasSuccessMessage = false

  // Öncelik normalizasyonu uyarıları
  if (results.priorityNormalized > 0) {
    const normalizedDetails = results.details.priorityNormalizedKeywords
        .map(item => ({
          row: item.rowInfo.row,
          original: item.originalPriority,
          normalized: item.normalizedPriority,
        }))

    responseHandlerService.warningHandler(
        t('settings.components.configuration.components.keywordsTable.priorityNormalized',
            {
              count: results.priorityNormalized,
              details: normalizedDetails.map(d =>
                  `Row ${d.row}: ${d.original} → ${d.normalized}`,
              ).join(', '),
            },
        ),
    )
  }

  // Geçersiz satır uyarıları
  if (results.invalid > 0) {
    // Genel uyarı
    responseHandlerService.warningHandler(
        t('settings.components.configuration.components.keywordsTable.invalidRows',
            { count: results.invalid },
        ),
    )

    // Geçersiz kategorileri grupla ve göster
    const invalidCategoryRows = results.details.invalidKeywords
        .filter(invalid => invalid.invalidIds?.length > 0)
        .map(invalid => ({
          row: invalid.rowInfo.row,
          categories: invalid.invalidIds,
        }))

    if (invalidCategoryRows.length > 0) {
      responseHandlerService.warningHandler(
          t('settings.components.configuration.components.keywordsTable.invalidCategories',
              {
                details: invalidCategoryRows.map(row =>
                    `Row ${row.row}: ${row.categories.join(', ')}`,
                ).join('; '),
              },
          ),
      )
    }

    // Diğer hata tipleri için detaylar
    const otherErrors = results.details.invalidKeywords
        .filter(invalid => !invalid.invalidIds)
        .map(invalid => ({
          row: invalid.rowInfo.row,
          reason: invalid.reason,
        }))

    if (otherErrors.length > 0) {
      responseHandlerService.warningHandler(
          t('settings.components.configuration.components.keywordsTable.otherErrors',
              {
                details: otherErrors.map(error =>
                    `Row ${error.row}: ${t(`errors.${error.reason}`)}`,
                ).join('; '),
              },
          ),
      )
    }
  }

  // Atlanan keywordler için uyarı
  if (results.skipped > 0) {
    const skippedDetails = results.details.skippedKeywords
        .map(item => ({
          row: item.rowInfo?.row,
          reason: item.reason,
        }))
        .filter(item => item.row)

    responseHandlerService.warningHandler(
        t('settings.components.configuration.components.keywordsTable.skippedKeywords',
            {
              count: results.skipped,
              details: skippedDetails.length > 0 ?
                  skippedDetails.map(d =>
                      `Row ${d.row}: ${t(`errors.${d.reason}`)}`,
                  ).join(', ') : '',
            },
        ),
    )
  }

  // Başarılı işlemler için bilgilendirme
  ['new', 'reactivated', 'updated'].forEach((type) => {
    if (results[type] > 0) {
      const details = results.details[`${type}Keywords`]
          .map(item => ({
            row: item.rowInfo.row,
            name: item.name || item.keyword,
          }))

      responseHandlerService.successHandler(
          t(
              `settings.components.configuration.components.keywordsTable.keywords${type.charAt(0).toUpperCase() + type.slice(1)}`,
              {
                count: results[type],
                details: details.map(d => `${d.name} (Row ${d.row})`).join(', '),
              },
          ),
      )
      hasSuccessMessage = true
    }
  })

  // Hiçbir değişiklik yoksa
  if (!hasSuccessMessage && results.invalid === 0 && results.skipped === 0) {
    responseHandlerService.warningHandler(
        t('settings.components.configuration.components.keywordsTable.noChanges'),
    )
  }
}