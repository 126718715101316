<template>
  <div class="h-full flex flex-col overflow-hidden">
    <!-- Content Wrapper -->
    <div class="flex flex-1 h-full min-h-[550px]" style="height: 100%;">
      <!-- Loading -->
      <div v-if="isLoading" class="w-full h-full flex justify-center items-center py-10">
        <div class="flex flex-col items-center gap-4">
          <div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
          <p class="text-gray-600">Kategoriler yükleniyor...</p>
        </div>
      </div>

      <!-- Content -->
      <template v-else>
        <!-- Left Panel - Categories Tree -->
        <div class="w-full md:w-1/3 border-r overflow-auto bg-gray-50 h-full flex flex-col min-h-[550px]" style="min-height: 550px;">
          <div class="p-4">
            <h3 class="text-lg font-medium text-gray-700 mb-2">Kategoriler</h3>
            <div class="relative">
              <span class="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
                <span class="material-symbols-outlined !text-[18px]">search</span>
              </span>
              <input type="text" v-model="searchQuery" placeholder="Kategori ara..." class="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-primary transition-colors"/>
            </div>
          </div>

          <div class="category-tree flex-1 overflow-auto p-4 pt-0">
            <template v-if="filteredCategories.length">
              <div v-for="category in filteredCategories" :key="category.id" class="category-item mb-2">
                <CategoryTreeItem :category="category" :selected-id="selectedCategory?.id" @select="selectCategory"/>
              </div>
            </template>
            <div v-else class="text-center py-6 text-gray-500 bg-white rounded-lg border border-gray-200 p-4">
              <span class="material-symbols-outlined !text-[36px] mb-2 text-gray-400">folder_off</span>
              <p class="text-sm">Gösterilecek kategori bulunamadı</p>
            </div>
          </div>
        </div>

        <!-- Right Panel - Prompt Editor -->
        <div class="w-full md:w-2/3 p-6 overflow-auto bg-white h-full flex flex-col min-h-[550px]" style="min-height: 550px;">
          <!-- No Category Selected -->
          <div v-if="!selectedCategory" class="flex-1 flex flex-col items-center justify-center text-gray-400 py-10">
            <span class="material-symbols-outlined text-6xl mb-4 text-primary/40">smart_toy</span>
            <p class="text-lg font-medium text-gray-500">Sol panelden bir kategori seçin</p>
            <p class="text-sm mt-2 text-gray-400">Her kategori için ayrı prompt ayarları yapabilirsiniz</p>
          </div>

          <!-- Category Selected -->
          <div v-else class="flex-1 flex flex-col overflow-y-auto">
            <div class="border-b pb-4 mb-6">
              <div class="flex items-center justify-between">
                <h3 class="text-xl font-semibold text-gray-800 flex items-center gap-2">
                  <span class="material-symbols-outlined text-primary">smart_toy</span> {{ selectedCategory.name }} </h3>
                <button v-if="windowWidth < 768" @click="selectedCategory = null" class="md:hidden text-gray-500 hover:text-gray-700 flex items-center gap-1 px-3 py-1 rounded-md hover:bg-gray-100" title="Kategorilere Dön">
                  <span class="material-symbols-outlined">arrow_back</span> <span class="text-sm">Geri</span>
                </button>
              </div>
              <p class="text-sm text-gray-500">Kategori seviyesi: {{ selectedCategory.level }}</p>
            </div>

            <div v-if="isPromptLoading" class="flex-1 flex justify-center items-center py-10">
              <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary"></div>
            </div>

            <div v-else class="space-y-6 flex-1 overflow-y-auto">
              <!-- Image Prompt -->
              <div class="mb-6 bg-gray-50 p-6 rounded-lg border border-gray-200">
                <label class="block text-sm font-medium text-gray-800 mb-2 flex items-center gap-2"> <span class="material-symbols-outlined text-primary">image</span> Görsel Prompt </label>
                <p class="text-sm text-gray-600 mb-3">
                  Bu kategori için oluşturulacak görsellerin nasıl olması gerektiğini tanımlayın </p>
                <textarea v-model="imagePrompt" class="w-full h-40 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-colors" placeholder="Bu kategori için görsel oluşturulurken AI'ın nasıl bir görsel oluşturması gerektiğini açıklayın..."></textarea>
              </div>

              <!-- Video Prompt -->
              <div class="mb-6 bg-gray-50 p-6 rounded-lg border border-gray-200">
                <label class="block text-sm font-medium text-gray-800 mb-2 flex items-center gap-2"> <span class="material-symbols-outlined text-primary">movie</span> Video Prompt </label>
                <p class="text-sm text-gray-600 mb-3">
                  Bu kategori için oluşturulacak videoların nasıl olması gerektiğini tanımlayın </p>
                <textarea v-model="videoPrompt" class="w-full h-40 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent transition-colors" placeholder="Bu kategori için video oluşturulurken AI'ın nasıl bir video oluşturması gerektiğini açıklayın..."></textarea>
              </div>

              <!-- Quick Templates -->
              <div class="mb-8 bg-blue-50 p-5 rounded-lg border border-blue-200">
                <h4 class="text-sm font-medium text-blue-700 mb-3 flex items-center gap-2">
                  <span class="material-symbols-outlined">bolt</span> Hızlı Şablonlar </h4>
                <div class="flex flex-wrap gap-2">
                  <button v-for="template in promptTemplates" :key="template.id" @click="applyTemplate(template)" class="px-3 py-1.5 bg-white border border-gray-300 text-sm rounded-full hover:bg-primary hover:text-white hover:border-primary transition-all">
                    {{ template.name }}
                  </button>
                </div>
              </div>

              <!-- Actions -->
              <div class="flex justify-end gap-3 mt-8 sticky bottom-0 bg-white py-4 border-t">
                <button @click="resetPrompts" class="px-4 py-2 text-gray-600 hover:text-gray-800 border border-gray-300 rounded-lg hover:bg-gray-50 transition-all flex items-center gap-2">
                  <span class="material-symbols-outlined !text-[18px]">refresh</span> Temizle
                </button>
                <button @click="savePrompt" class="px-6 py-2 bg-primary text-white rounded-lg hover:bg-primary-dark transition-all flex items-center gap-2" :disabled="isSaving || (!imagePrompt && !videoPrompt)" :class="{'opacity-50 cursor-not-allowed': isSaving || (!imagePrompt && !videoPrompt)}">
                  <span v-if="isSaving" class="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></span> <span class="material-symbols-outlined !text-[18px]">save</span> Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAssetStore } from '@/stores/asset'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CategoryTreeItem from './CategoryTreeItem.vue'

const { t } = useI18n()
const emit = defineEmits(['close'])

const assetStore = useAssetStore()
const windowWidth = ref(window.innerWidth)
const searchQuery = ref('')
const isLoading = ref(false)
const isPromptLoading = ref(false)
const isSaving = ref(false)
const categories = ref([])
const selectedCategory = ref(null)
const imagePrompt = ref('')
const videoPrompt = ref('')

// Örnek prompt şablonları
const promptTemplates = [
  { id: 1, name: 'Profesyonel Fotoğraf', imagePrompt: 'Profesyonel, yüksek çözünürlüklü, net aydınlatma, DSLR kamera ile çekilmiş, stüdyo kalitesinde' },
  { id: 2, name: 'Minimalist Stil', imagePrompt: 'Minimalist, sade, temiz arka plan, az renk, boş alan, modern, şık' },
  { id: 3, name: 'Eğitim Videosu', videoPrompt: 'Eğitim amaçlı, adım adım açıklamalı, anlaşılır, görsel örnekli' },
  { id: 4, name: 'Ürün Tanıtımı', videoPrompt: 'Ürün özellikleri, yakın çekim detaylar, kullanım örneği, beyaz arka fon' },
]

// Kategorileri filtrele
const filteredCategories = computed(() => {
  if (!searchQuery.value.trim()) return categories.value

  const query = searchQuery.value.toLowerCase()

  // Arama fonksiyonu (recursive)
  const searchInCategories = (list) => {
    return list.filter(category => {
      // Ad içinde arama
      const nameMatch = category.name?.toLowerCase().includes(query)

      // Alt kategorilerde arama
      let childrenMatch = false
      if (category.children && category.children.length) {
        const matches = searchInCategories(category.children)
        childrenMatch = matches.length > 0
        if (childrenMatch) {
          // Alt kategorilerdeki eşleşmeleri döndür
          category.children = matches
        }
      }

      return nameMatch || childrenMatch
    })
  }

  return searchInCategories([...categories.value])
})

// Kategorileri getir
const fetchCategories = async () => {
  isLoading.value = true
  try {
    // Kategorileri hiyerarşik yapıda getir
    const response = await axios.get(settings.categoriesHierarchy, {
      params: {
        assetId: assetStore.assetId,
        isActive: 1,
      },
    })

    if (response?.data?.data) {
      categories.value = response.data.data
    }
  } catch (error) {
    responseHandlerService.handleError('Kategoriler yüklenirken bir hata oluştu')
  } finally {
    isLoading.value = false
  }
}

// Kategori seç
const selectCategory = (category) => {
  selectedCategory.value = category
  fetchCategoryPrompt()

  // Mobil boyutta kategori seçildiğinde sol panelı gizle
  if (windowWidth.value < 768) {
    // Sol panel gizlenir, sağ panel gösterilir (CSS sınıfları ile)
  }
}

// Kategori prompt bilgilerini getir
const fetchCategoryPrompt = async () => {
  if (!selectedCategory.value) return

  isPromptLoading.value = true
  imagePrompt.value = ''
  videoPrompt.value = ''

  try {
    const response = await axios.get(settings.categoryPrompt, {
      params: {
        categoryId: selectedCategory.value.id,
        assetId: assetStore.assetId,
      },
    })

    if (response?.data?.data) {
      const promptData = response.data.data
      imagePrompt.value = promptData.image_prompt || ''
      videoPrompt.value = promptData.video_prompt || ''
    }
  } catch (error) {
    responseHandlerService.handleError('Prompt bilgileri alınırken bir hata oluştu')
  } finally {
    isPromptLoading.value = false
  }
}

// Prompt kaydet
const savePrompt = async () => {
  if (!selectedCategory.value) return
  if (!imagePrompt.value && !videoPrompt.value) return

  isSaving.value = true
  try {
    const payload = {
      categoryId: selectedCategory.value.id,
      assetId: assetStore.assetId,
      imagePrompt: imagePrompt.value,
      videoPrompt: videoPrompt.value,
    }

    await axios.post(settings.categoryPrompt, payload)
    responseHandlerService.handleSuccess('Prompt başarıyla kaydedildi')
  } catch (error) {
    responseHandlerService.handleError('Prompt kaydedilirken bir hata oluştu')
  } finally {
    isSaving.value = false
  }
}

// Prompt şablonu uygula
const applyTemplate = (template) => {
  if (template.imagePrompt) {
    imagePrompt.value = template.imagePrompt
  }
  if (template.videoPrompt) {
    videoPrompt.value = template.videoPrompt
  }
}

// Promptları sıfırla
const resetPrompts = () => {
  imagePrompt.value = ''
  videoPrompt.value = ''
}

// Modalı kapat
const closeModal = () => {
  emit('close')
}

// İlk yükleme
onMounted(() => {
  fetchCategories()

  // Resize event'ini dinle
  window.addEventListener('resize', handleResize)
})

// Component temizleme
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

// Pencere boyutu değiştiğinde
const handleResize = () => {
  windowWidth.value = window.innerWidth
}
</script>