import { ref } from 'vue'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import { exportKeywordsToBuffer, generateTemplateWorkbook } from '../utils/keywordExport/exportGenerators.js'
import { sortKeywordsByPriorityAndName } from '../utils/keywords/keywordSorters.js'

/**
 * Keyword dışa aktarma işlemlerini yöneten composable
 * @param {Object} keywordsTableData - Keyword tablosu verisi (computed)
 * @param {Function} t - Çeviri fonksiyonu
 * @returns {Object} Dışa aktarma işlemleri ve durum değişkenleri
 */
export function useKeywordExport(keywordsTableData, t) {
  const isExporting = ref(false)

  /**
   * Keyword dosyası şablonu indirir
   * @returns {Promise<Object>} İşlem sonucu
   */
  const downloadTemplate = async () => {
    try {
      // Şablon workbook'unu oluştur
      const { workbook, worksheet } = await generateTemplateWorkbook()

      // Workbook'u buffer'a çevir ve indir
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const fileName = `keywords_template.xlsx`
      saveAs(blob, fileName)

      return { success: true }
    } catch (error) {
      console.error('Template download failed:', error)
      return { success: false, error }
    }
  }

  /**
   * Mevcut keywordleri Excel dosyası olarak dışa aktarır
   * @returns {Promise<Object>} İşlem sonucu
   */
  const exportKeywords = async () => {
    if (isExporting.value) return

    isExporting.value = true
    try {
      // Aktif keywordleri filtrele - state 1 ve 2 olanları alıyoruz
      const keywords = keywordsTableData.value
          .filter((k) => k.state === 1 || k.state === 2)

      // Sıralanmış keywords dizisi
      const sortedKeywords = sortKeywordsByPriorityAndName(keywords)

      // Excel buffer'ını oluştur
      const buffer = await exportKeywordsToBuffer(sortedKeywords)

      // Buffer'ı blob'a dönüştür ve indir
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      const fileName = `keywords_${dayjs().format('YYYY-MM-DD')}.xlsx`
      saveAs(blob, fileName)

      return { success: true }
    } catch (error) {
      console.error('Export failed:', error)
      return { success: false, error }
    } finally {
      isExporting.value = false
    }
  }

  return {
    isExporting,
    exportKeywords,
    downloadTemplate,
  }
}