<template>
  <div class="component-layout">
    <div class="space-y-6 px-4 md:px-6">
      <!-- Header her zaman görünür -->
      <DashboardHeader :date-range="dashboardStore.dateRange" @dateChange="handleDateRangeChange" @navigateToAllEntities="navigateToAllEntities" :hasData="hasData"/>

      <!-- İlk yükleme kontrolü -->
      <template v-if="isLoading">
        <div class="flex items-center justify-center h-[50vh]">
          <loading :showLoadingText="true" :loading="true" :loadingText="t('assets.dashboard.dashboardLoading')" loading-text-class="font-semibold"/>
        </div>
      </template>

      <!-- Veri boş kontrolü -->
      <template v-else-if="!hasData">
        <div class="flex flex-col items-center justify-center h-[65vh] space-y-4">
          <img src="@/assets/logo/logoB.svg" alt="logo" class="w-32 h-32"/>
          <p class="text-lg text-gray-600">{{ t('assets.dashboard.noData') }}</p>
          <customButton type="button" :buttonText="t('assets.dashboard.goToEntity')" @click="navigateToAllEntities" size="md"/>
        </div>
      </template>

      <!-- Ana içerik -->
      <template v-else>
        <div v-if="!isMetricsLoading" class="w-full">
          <MetricsGrid :keyword-data="keywordData" :asset-data="assetData" @metrics-data="handleMetricsData"/>
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-12 gap-6">
          <div class="lg:col-span-12">
            <div v-if="!isChartLoading">
              <ChartSection :reportStatusLoad="reportStatusLoad" :chart-data="processedData" @selection-change="handleChartSelectionChange" @asset-data="handleAssetData"/>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onBeforeMount, onUnmounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useDashboardStore } from '@/stores/dashboardOfAssets'
import { useAssetStore } from '@/stores/asset'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import DashboardHeader from './components/DashboardHeader.vue'
import MetricsGrid from './components/metricsGrid/MetricsGrid.vue'
import ChartSection from './components/chartSection/ChartSection.vue'
import KeywordAnalysisTable from './components/keywordAnalysis/index.vue'
import loading from '@/components/loading/VLoading.vue'
import customButton from '@/components/base/buttons/VButton.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const router = useRouter()
const dashboardStore = useDashboardStore()
const assetStore = useAssetStore()

const keywordData = ref([])
const assetData = ref([])
const Assets = ref([])
const processedData = ref(null)
const isProcessing = ref(false)
const isLoading = ref(true)
const currentPage = ref(1)
const pageSize = ref(15)
const isActive = ref(2)
const reportStatusLoad = ref(true)

// Güvenli computed properties
const isMetricsLoading = computed(() => Boolean(dashboardStore.loading?.metrics))
const isChartLoading = computed(() => Boolean(dashboardStore.loading?.chart))
const isKeywordsLoading = computed(() => Boolean(dashboardStore.loading?.keywords))

// Veri varlığı kontrolü - Assets verilerine bağlı
const hasData = computed(() => {
  return Boolean(Assets.value?.length > 0)
})

// Önce Assets verilerini çek
const getAll = async () => {
  isLoading.value = true
  try {
    const result = await axios.get(assets.getAll, {
      params: {
        page: currentPage.value - 1,
        isActive: isActive.value,
        pageSize: pageSize.value,
        sort: 'id DESC',
      },
    })

    Assets.value = result.data.data.detail
    assetStore.setAssets(result.data.data.detail)

    // Assets varsa dashboard verilerini yükle
    if (Assets.value?.length > 0) {
      await initializeDashboard()
    }
  } catch (err) {
    responseHandlerService.handleError(err, t('assets.error.general'))
    if (Assets.value?.length > 0) {
      await initializeDashboard()
    }
  } finally {
    isLoading.value = false
  }
}

const initializeDashboard = async () => {
  dashboardStore.$patch({
    loading: {
      metrics: false,
      chart: false,
      keywords: false,
    },
    chart: {
      selectedAssets: [],
      data: null,
    },
  })

  await refreshData()
}

const processChartData = () => {
  reportStatusLoad.value = true // Yüklenme durumunu başlat

  if (!keywordData.value?.length || !dashboardStore.metricsData) {
    reportStatusLoad.value = false // Eğer veri yoksa yüklenme durumunu kapat
    return null
  }
  const topByVolume = [...(keywordData.value || [])]
      .filter(item => typeof item.volume === 'number')
      .sort((a, b) => (b.volume || 0) - (a.volume || 0))
      .slice(0, 20)

  const itemsWithPositions = [...(keywordData.value || [])]
      .map(item => {
        const positions = item.positions || []

        const enginePositions = {
          google: positions.filter(p => p.engine === 2).map(p => parseInt(p.position)),
          yandex: positions.filter(p => p.engine === 1).map(p => parseInt(p.position)),
          bing: positions.filter(p => p.engine === 3).map(p => parseInt(p.position)),
        }

        const validPositions = [
          ...enginePositions.google,
          ...enginePositions.yandex,
          ...enginePositions.bing,
        ].filter(pos => pos > 0)

        const bestPosition = validPositions.length ? Math.min(...validPositions) : Infinity

        return {
          ...item,
          keywordName: item.keywordName,
          position: bestPosition,
          enginePositions,
        }
      })
      .filter(item => item.position !== Infinity)
      .sort((a, b) => a.position - b.position)
      .slice(0, 20)
  processedData.value = {
    metricsData: dashboardStore.metricsData,
    keywordData: {
      topByVolume,
      topByPosition: itemsWithPositions,
    },
  }
  reportStatusLoad.value = false
  return processedData.value
}


const handleDateRangeChange = (newRange) => {
  if (isProcessing.value) return
  dashboardStore.setDateRange?.(newRange)
  refreshData()
}

const handleMetricsData = async (data) => {
  if (!data || isProcessing.value) return

  dashboardStore.setLoading?.('metrics', true)
  try {
    await dashboardStore.setMetricsData?.(data)
    dashboardStore.setRequirementStatus?.('metrics', true)
    const chartData = processChartData()
    if (chartData && dashboardStore.chart?.selectedAssets?.length) {
      dashboardStore.setRequirementStatus?.('assets', true)
    }
  } catch (error) {
    dashboardStore.setRequirementStatus?.('metrics', false)
    console.error('Metrics data error:', error)
  } finally {
    dashboardStore.setLoading?.('metrics', false)
  }
}

const handleKeywordData = (data) => {
  if (!data?.length || isProcessing.value) return
  keywordData.value = data
  dashboardStore.setRequirementStatus?.('keywords', true)
  processChartData()
}

const handleChartSelectionChange = async (selectedAssets) => {
  if (isProcessing.value) return
  isProcessing.value = true

  dashboardStore.setFetchable?.(false)
  dashboardStore.resetReportStatus?.()
  if (dashboardStore.chart) {
    dashboardStore.chart.selectedAssets = selectedAssets
  }
  dashboardStore.setRequirementStatus?.('assets', selectedAssets?.length > 0)
  dashboardStore.setLoading?.('chart', true)

  if (selectedAssets?.length) {
    dashboardStore.setFetchable?.(true)
  }
  isProcessing.value = false
}

const handleAssetData = (data) => {
  if (!data || isProcessing.value) return
  assetData.value = data
  if (dashboardStore.chart) {
    dashboardStore.chart.data = data
  }
  dashboardStore.setRequirementStatus?.('assets', !!data?.length)
  processChartData()
}

const navigateToAllEntities = () => {
  router.push({
    query: {
      ...router.currentRoute.value.query,
      tab: 'entities',
    },
  })
}

const refreshData = async () => {
  if (isProcessing.value) return
  isProcessing.value = true

  dashboardStore.setLoading?.('metrics', true)
  dashboardStore.setLoading?.('chart', true)
  dashboardStore.setLoading?.('keywords', true)
  dashboardStore.resetReportStatus?.()

  try {
    if (keywordData.value?.length || assetData.value?.length) {
      await handleMetricsData({
        ...dashboardStore.metricsData,
        totalContent: assetData.value.reduce((sum, asset) => sum + (asset.contentCount || 0), 0),
        totalVolume: keywordData.value.reduce((sum, keyword) => sum + (keyword.volume || 0), 0),
      })
    }

    if (dashboardStore.chart?.selectedAssets?.length) {
      await handleChartSelectionChange(dashboardStore.chart.selectedAssets)
    }

    if (dashboardStore.isFetchable && dashboardStore.chart?.selectedAssets?.length) {
      handleKeywordData(keywordData.value)
    }
  } catch (error) {
    console.error('Refresh data error:', error)
  } finally {
    dashboardStore.setLoading?.('metrics', false)
    dashboardStore.setLoading?.('chart', false)
    dashboardStore.setLoading?.('keywords', false)
    isProcessing.value = false
  }
}

const resetState = () => {
  keywordData.value = []
  assetData.value = []
  Assets.value = []
  processedData.value = null
  isProcessing.value = false
  isLoading.value = true
  dashboardStore.resetDashboard?.()
  dashboardStore.resetRequirements?.()
  dashboardStore.setFetchable?.(false)
  dashboardStore.setLoading?.('metrics', false)
  dashboardStore.setLoading?.('chart', false)
  dashboardStore.setLoading?.('keywords', false)
}

// Watchers
watch(
    [
      () => dashboardStore.chart?.selectedAssets,
      () => dashboardStore.metricsData,
      () => keywordData.value,
    ],
    ([newAssets, newMetrics, newKeywords]) => {
      if (!isProcessing.value && newAssets?.length && newMetrics && newKeywords?.length) {
        processChartData()
      }
    },
    { deep: true },
)

watch(
    () => router.currentRoute.value.path,
    () => resetState(),
    { immediate: true },
)

// Lifecycle hooks
onBeforeMount(async () => {
  await getAll()
})

onUnmounted(() => {
  resetState()
})
</script>