import { createI18n } from 'vue-i18n'
import en from './locales/en.json'
import tr from './locales/tr.json'
import ru from './locales/ru.json'

// Export a function that takes pinia as a parameter
export function setupI18n(pinia) {
  // Burada store'u import edip kullanıyoruz
  const { useUIStore } = require('@/stores/ui')
  const uiStore = useUIStore(pinia)

  // Desteklenen diller
  const supportedLocales = ['en', 'tr','ru']

  // Dil kodunu normalize etme fonksiyonu
  const normalizeLocale = (locale) => {
    const [language] = locale.split('-')
    return supportedLocales.includes(language) ? language : null
  }

  // Kullanıcının dilini tespit etme fonksiyonu
  const detectUserLocale = () => {
    // Önce store'daki dili kontrol et
    const storedLocale = uiStore.language
    if (storedLocale && supportedLocales.includes(storedLocale)) {
      return storedLocale
    }

    // navigator.languages'i kontrol et (çoklu dil desteği için)
    for (const locale of navigator.languages || []) {
      const normalizedLocale = normalizeLocale(locale)
      if (normalizedLocale) return normalizedLocale
    }

    // Eğer navigator.languages'den bir sonuç bulunamazsa, navigator.language'i kontrol et
    const browserLocale = normalizeLocale(navigator.language || 'en')
    if (browserLocale) return browserLocale

    // Hiçbir eşleşme bulunamazsa varsayılan dili döndür
    return 'tr'
  }

  // Kullanıcının diline göre varsayılan dili ayarla
  const locale = detectUserLocale()

  const messages = {
    en,
    tr,
    ru
  }

  return createI18n({
    legacy: false, // Vue 3 Composition API için
    locale, // Tespit edilen dil
    fallbackLocale: 'en', // Bulunmayan bir çeviri için yedek dil
    messages,
    globalInjection: true,
  })
}
