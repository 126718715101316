<template>
  <div class="relative">
    <VMultiSelect v-model="internalValue" :options="categories" :loading="loading" :placeholder="computedPlaceholder" :search-placeholder="t('common.searchCategories')" :error="error" :show-error="!!error" :clearable="clearable" :no-options-message="t('common.noCategories')" :size="size" :selected-label-format="(count) => `${count} ${t('common.categoriesSelected')}`" @clear="handleClear"/>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import VMultiSelect from '@/components/base/inputs/MultiSelect/VMultiSelect.vue'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { useAssetStore } from '@/stores/asset'

const { t } = useI18n()
const assetStore = useAssetStore()

const STATE = {
  EXISTING: 1,
  NEW: 2,
  REMOVED: 3,
}

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: null,
  },
  clearable: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'sm',
  },
})

const emit = defineEmits(['update:modelValue', 'error'])

// State
const categories = ref([])
const loading = ref(false)
const error = ref('')

// Transform incoming value to internal format
const internalValue = computed({
  get: () => {
    return props.modelValue.map(item => {
      if (typeof item === 'object' && item !== null) {
        return {
          id: item.id || null,
          value: item.categoryId || item.id,
          state: item.state || STATE.NEW,
        }
      }
      return {
        id: null,
        value: item,
        state: STATE.NEW,
      }
    })
  },
  set: (newValue) => {
    // Transform back to expected format when emitting
    const transformedValue = newValue.map(item => ({
      id: item.id || null,
      categoryId: item.value,
      state: item.state || STATE.NEW,
    }))
    emit('update:modelValue', transformedValue)
  },
})

// Computed
const computedPlaceholder = computed(() => {
  if (loading.value) return t('common.loading')
  if (!loading.value && categories.value.length === 0) return t('common.addCustomCategory')
  return props.placeholder || t('common.selectCategories')
})

// Methods
const fetchCategories = async () => {
  loading.value = true
  error.value = ''

  try {
    const { data } = await axios.get(settings.getKeywordCategories, {
      params: {
        assetId: assetStore.assetId,
      },
    })

    categories.value = data.data.map((category) => ({
      value: category.id,
      label: category.name,
      ...category,
    }))
  } catch (err) {
    const errorMessage = t('errors.fetchCategories')
    error.value = errorMessage
    emit('error', errorMessage)
    console.error('Error fetching categories:', err)
  } finally {
    loading.value = false
  }
}

const handleClear = () => {
  const newValue = internalValue.value.map(item => {
    if (item.state === STATE.EXISTING) {
      return { ...item, state: STATE.REMOVED }
    }
    return item
  }).filter(item => item.state !== STATE.NEW)

  emit('update:modelValue', newValue)
}

// Lifecycle
onMounted(() => {
  fetchCategories()
})

// Expose fetch method for manual refresh
defineExpose({
  fetchCategories,
})
</script>