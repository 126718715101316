<template>
  <div class="border-t border-gray-200">
    <div class="flex justify-between items-center py-3 px-5">
      <!-- Beğeniler -->
      <div class="flex flex-col items-center w-1/3">
        <button class="flex items-center justify-center mb-1 text-gray-400 hover:text-red-500 transition-colors">
          <span class="material-symbols-outlined !text-[22px]">favorite</span>
        </button>
        <div class="text-base font-semibold text-center text-gray-800">{{ formatStat(likes) }}</div>
        <div class="text-xs text-gray-500">{{ t('socialMedia.components.postInspector.likes') }}</div>
      </div>

      <!-- Yorumlar -->
      <div class="flex flex-col items-center w-1/3 border-l border-r border-gray-200">
        <button class="flex items-center justify-center mb-1 text-gray-400 hover:text-blue-500 transition-colors">
          <span class="material-symbols-outlined !text-[22px]">comment</span>
        </button>
        <div class="text-base font-semibold text-center text-gray-800">{{ formatStat(comments) }}</div>
        <div class="text-xs text-gray-500">{{ t('socialMedia.components.postInspector.comments') }}</div>
      </div>

      <!-- Paylaşımlar -->
      <div class="flex flex-col items-center w-1/3">
        <button class="flex items-center justify-center mb-1 text-gray-400 hover:text-green-500 transition-colors">
          <span class="material-symbols-outlined !text-[22px]">share</span>
        </button>
        <div class="text-base font-semibold text-center text-gray-800">{{ formatStat(shares) }}</div>
        <div class="text-xs text-gray-500">{{ t('socialMedia.components.postInspector.shares') }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  likes: {
    type: [Number, String],
    default: 0,
  },
  comments: {
    type: [Number, String],
    default: 0,
  },
  shares: {
    type: [Number, String],
    default: 0,
  },
})

function formatStat(value) {
  if (!value) return 0
  const num = Number(value)
  if (isNaN(num)) return 0
  if (num >= 1000000) return (num / 1000000).toFixed(1) + 'M'
  if (num >= 1000) return (num / 1000).toFixed(1) + 'K'
  return num
}
</script>