/**
 * Bir keyword'ün seçili olup olmadığını kontrol eder
 * @param {Object} keyword - Kontrol edilecek keyword
 * @param {Array} selectedKeywords - Seçili keyword listesi
 * @returns {boolean} Keyword seçili mi?
 */
export function isKeywordSelected(keyword, selectedKeywords) {
  return selectedKeywords.some(
      selected =>
          selected === keyword.id ||
          selected === keyword.keywordId ||
          selected.name === keyword.name,
  )
}

/**
 * Keyword'ün state değerine göre işlem yapar
 * @param {Object} keyword - İşlenecek keyword
 * @returns {Object|null} İşlenmiş keyword veya null
 */
export function processKeywordByState(keyword) {
  // Yeni eklenmiş keywordleri tamamen kaldır
  if (keyword.state === 2) {
    return null
  }

  // State 1 olan kategorileri state 3'e çevir, diğerlerini koru
  const updatedCategoryIds = updateCategoryStates(keyword.categoryIds)

  // Keyword state'ini güncelle
  return {
    ...keyword,
    state: 3,
    priority: null,
    categoryIds: updatedCategoryIds,
  }
}

/**
 * Kategori dizisindeki state değerlerini günceller
 * @param {Array} categoryIds - Kategori dizisi
 * @returns {Array} Güncellenmiş kategori dizisi
 */
export function updateCategoryStates(categoryIds = []) {
  return categoryIds.map(category => {
    if (category.state === 1) {
      // Mevcut kategorileri silme olarak işaretle
      return {
        ...category,
        state: 3,
      }
    }
    // state 2 (yeni) olanları null yap, diğerlerini olduğu gibi bırak
    return category.state === 2 ? null : category
  }).filter(Boolean) // null olanları filtrele
}

/**
 * Seçili keywordleri işleyerek yeni bir keywords dizisi oluşturur
 * @param {Array} keywords - Orijinal keyword dizisi
 * @param {Array} selectedKeywords - Seçili keyword dizisi
 * @returns {Array} İşlenmiş keyword dizisi
 */
export function processSelectedKeywords(keywords, selectedKeywords) {
  return keywords
      .map(keyword => {
        if (isKeywordSelected(keyword, selectedKeywords)) {
          return processKeywordByState(keyword)
        }
        return keyword
      })
      .filter(keyword => keyword !== null)
}