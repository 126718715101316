/**
 * İçerik üretimi için yardımcı fonksiyonlar
 */
import { responseHandlerService } from '@/plugins/responseHandlerService'
import axios from '@/plugins/axiosInstance'
import {
  generateContent,
  createTrendRequestData,
  createManualRequestData,
} from './contentHelpers'
import {
  prepareGeneration,
  finalizeGeneration,
} from './contentState'

/**
 * İçeriği işle ve state'e kaydet
 */
export function processAndSaveResult(result, state) {
  if (result) {
    state.generatedContent.value = result
  }
  return result
}

/**
 * İçerik oluşturma isteği yap
 */
export async function makeContentRequest(requestData, mediaData, dependencies) {
  const { state, cancelToken, featureStore, t } = dependencies

  try {
    // false değerini geçerek hata mesajı göstermeyi devre dışı bırakıyoruz
    // Böylece hata mesajı sadece bir kere (burada) gösterilecek
    const result = await generateContent(
        requestData,
        mediaData || [],
        cancelToken,
        state,
        featureStore,
        t,
        false, // showErrorMessage parametresi
    )

    return processAndSaveResult(result, state)
  } catch (error) {
    console.error('Content generation error:', error)

    if (!axios.isCancel(error)) {
      responseHandlerService.handleError(error, t('socialMedia.components.createPost.errors.generateContent'))
    }

    return null
  } finally {
    finalizeGeneration(state)
  }
}

/**
 * Yeniden oluşturma parametrelerini ayarla
 */
export function setRegenerationParams(state, type, data) {
  state.lastGenerationParams.value = {
    type,
    data: { ...data },
  }
}

/**
 * Önceki içeriği yedekle
 */
export function backupCurrentContent(state) {
  if (state.generatedContent.value) {
    state.previousContent.value = JSON.parse(JSON.stringify(state.generatedContent.value))
  }
}

/**
 * Trend verisinden içerik oluşturma işlemini başlat
 */
export async function generateTrendContent(data, assetStore, dependencies) {
  const { state, featureStore, t } = dependencies

  if (!prepareGeneration(state)) return null

  // Yeniden oluşturma parametrelerini ayarla
  setRegenerationParams(state, 'trend', data)

  // İçerik isteği yap
  const requestData = createTrendRequestData(data, assetStore)
  return await makeContentRequest(
      requestData,
      data.media,
      {
        state,
        cancelToken: state.cancelTokenSource.value.token,
        featureStore,
        t,
      },
  )
}

/**
 * Manuel form verisinden içerik oluşturma işlemini başlat
 */
export async function generateManualContentHelper(formData, assetStore, dependencies) {
  const { state, featureStore, t } = dependencies

  if (!prepareGeneration(state)) return null

  // Yeniden oluşturma parametrelerini ayarla
  setRegenerationParams(state, 'manual', formData)

  // İçerik isteği yap
  const requestData = createManualRequestData(formData, assetStore)
  return await makeContentRequest(
      requestData,
      formData.media,
      {
        state,
        cancelToken: state.cancelTokenSource.value.token,
        featureStore,
        t,
      },
  )
}

/**
 * İçeriği yeniden oluştur
 */
export async function regenerateContentHelper(state, assetStore, generateFuncs, t) {
  if (!state.lastGenerationParams.value) {
    responseHandlerService.handleWarning(t('socialMedia.components.createPost.warnings.noParameters'))
    return null
  }

  try {
    // Önceki içeriği yedekle
    backupCurrentContent(state)

    const { type, data } = state.lastGenerationParams.value
    return type === 'trend'
        ? await generateFuncs.fromTrend(data, assetStore)
        : await generateFuncs.manual(data, assetStore)
  } catch (error) {
    console.error('Error in regenerateContent:', error)
    state.isGenerating.value = false
    return null
  }
}