import { useKeywordState } from './useKeywordState'
import { useKeywordSelection } from './useKeywordSelection'
import { useKeywordEdit } from './useKeywordEdit'
import { useKeywordAdd } from './useKeywordAdd'
import { useKeywordRemove } from './useKeywordRemove'

/**
 * Keyword yönetimi için ana composable
 * Her bir alt-composable'ı birleştirir ve ortak arayüz sağlar
 *
 * @param {Object} props - Component prop'ları
 * @param {Function} emit - Olayları emit etme fonksiyonu
 * @param {Function} t - Çeviri fonksiyonu
 * @returns {Object} Keyword yönetimi için gerekli state ve fonksiyonlar
 */
export function useKeywords(props, emit, t) {
  // 1. Temel durum yönetimi
  const state = useKeywordState(props)

  // 2. Seçim işlemleri
  const selection = useKeywordSelection(props, emit, t, {
    selectedKeywords: state.selectedKeywords,
  })

  // 3. Düzenleme işlemleri
  const edit = useKeywordEdit(props, emit, t, {
    selectedKeyword: state.selectedKeyword,
    showEditModal: state.showEditModal,
  })

  // 4. Ekleme işlemleri
  const add = useKeywordAdd(props, emit, t, {
    activeKeywordsCount: state.activeKeywordsCount,
  })

  // 5. Silme işlemleri
  const remove = useKeywordRemove(props, emit, t)

  // Tüm fonksiyonları ve state'leri birleştir
  return {
    // Durum değişkenleri
    selectedKeywords: state.selectedKeywords,
    selectedKeyword: state.selectedKeyword,
    showEditModal: state.showEditModal,
    keywordsTableData: state.keywordsTableData,
    activeKeywordsCount: state.activeKeywordsCount,

    // Durum yönetim fonksiyonları
    resetSelection: state.resetSelection,

    // Seçim işlevleri
    handleSelectionChange: selection.handleSelectionChange,
    handleBulkDelete: selection.handleBulkDelete,

    // Düzenleme işlevleri
    handleEdit: edit.handleEdit,
    handleEditKeyword: edit.handleEditKeyword,

    // Ekleme işlevleri
    handleAddKeywords: add.handleAddKeywords,

    // Silme işlevleri
    removeKeyword: remove.removeKeyword,
  }
}