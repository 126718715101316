<template>
  <div class="space-y-6">
    <!-- Back to Welcome Button -->
    <button @click="$emit('select-mode', 'welcome')" class="flex items-center gap-1 text-sm text-primary mb-4">
      <span class="material-symbols-outlined !text-[18px]">arrow_back</span> {{ t('entity.components.newContent.components.newContentModal.backToWelcome') }}
    </button>

    <form class="space-y-6">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-6">
        <!-- Left Side -->
        <div class="space-y-4 lg:space-y-5">
          <LanguageSelector :model-value="formData.languageId" @update:model-value="updateFormField('languageId', $event)" :label="t('entity.components.newContent.components.newContentModal.languageSelectionLabel')" :description="t('entity.components.newContent.components.newContentModal.languageSelectionDescription')" :error="validationErrors.languageId" :show-search="false"/>

          <CustomInput :model-value="formData.assistiveDescription" @update:model-value="updateFormField('assistiveDescription', $event)" type="textarea" :label="t('entity.components.newContent.components.newContentModal.contentNoteLabel')" :description="t('entity.components.newContent.components.newContentModal.contentNoteDescription')" containerClass="w-full" additionalInputClass="resize-none min-h-[120px]" :error="validationErrors.assistiveDescription" :show-error="false"/>

          <TagInput :label="t('entity.components.newContent.components.newContentModal.keywordsLabel')" :model-value="formData.keywords" @update:model-value="updateFormField('keywords', $event)" :secondary="true" containerClass="w-full" :description="t('entity.components.newContent.components.newContentModal.keywordsDescription')" :isStateMode="false" :maxCount="52" :error="validationErrors.keywords"/>
        </div>

        <!-- Right Side - Media -->
        <div class="space-y-4 lg:space-y-5">
          <div class="h-full flex flex-col">
            <label class="text-sm font-medium text-gray-700 flex items-center justify-between mb-1"> <span>{{ t('common.imageUpload') }}</span>
              <span class="text-xs text-gray-500">{{ formData.images.length }}/2 {{ t('common.images') }}</span> </label>

            <div v-if="formData.images.length < 2" class="border-2 border-dashed border-gray-200 rounded-xl p-4 md:p-6 hover:border-primary/40 transition-colors cursor-pointer bg-gray-50/50 flex-grow" :class="{ 'border-red-300': validationErrors.images }" @click="$emit('trigger-image-upload')" @dragover.prevent @drop.prevent="$emit('image-drop', $event)">
              <div class="flex flex-col items-center justify-center h-full space-y-3">
                <div class="p-3 bg-primary/10 rounded-full">
                  <span class="material-symbols-outlined !text-[28px] text-primary">cloud_upload</span>
                </div>
                <div class="text-center">
                  <p class="text-sm text-gray-600">{{ t('common.dragAndDrop') }}</p>
                  <p class="text-xs text-gray-500 mt-1">PNG, JPG up to 10MB</p>
                </div>
                <button type="button" class="text-sm text-primary hover:text-primary/80 font-medium">
                  {{ t('common.browse') }}
                </button>
              </div>
              <input type="file" class="hidden" accept="image/jpeg,image/png" @change="$emit('image-select', $event)" multiple>
            </div>

            <!-- Image Preview Grid -->
            <div v-if="formData.images.length > 0" class="flex-grow grid grid-cols-2 gap-3">
              <div v-for="(image, index) in formData.images" :key="index" class="relative rounded-lg overflow-hidden group aspect-video">
                <img :src="image.preview" class="w-full h-full object-cover" alt="Preview"/>
                <div class="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                  <button type="button" @click="$emit('remove-image', index)" class="p-2 bg-white/90 rounded-full shadow-lg hover:bg-white transform transition-transform hover:scale-105 flex items-center">
                    <span class="material-symbols-outlined !text-[18px] text-gray-600">delete</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- Image Description -->
            <CustomInput v-if="formData.images.length === 0" :model-value="formData.assistiveImageDescription" @update:model-value="updateFormField('assistiveImageDescription', $event)" type="textarea" :label="t('entity.components.newContent.components.newContentModal.imageNoteLabel')" :description="t('entity.components.newContent.components.newContentModal.imageNoteDescription')" containerClass="w-full mt-4" additionalInputClass="resize-none flex-grow" :error="validationErrors.assistiveImageDescription" :show-error="false"/>
          </div>
        </div>
      </div>

      <!-- Content Length Selector  -->
      <div>
        <VSelect :model-value="formData.contentLength" @update:model-value="updateFormField('contentLength', $event)" :options="contentLengthOptions" :label="t('entity.components.newContent.components.newContentModal.contentLengthLabel')" :description="t('entity.components.newContent.components.newContentModal.contentLengthDescription')" :error="validationErrors.contentLength" :placeholder="t('entity.components.newContent.components.newContentModal.selectContentLength')" size="sm"/>
      </div>
    </form>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomInput from '@/components/base/inputs/VInput.vue'
import TagInput from '@/components/base/inputs/VTagInput.vue'
import LanguageSelector from '@/components/base/inputs/VLanguageSelector.vue'
import VSelect from '@/components/base/inputs/VSelect.vue'

// Content Length enum
const CONTENT_LENGTH = {
  short: 1,
  medium: 2,
  long: 3,
}

const props = defineProps({
  formData: {
    type: Object,
    required: true,
  },
  validationErrors: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits([
  'update:form-data',
  'trigger-image-upload',
  'image-select',
  'image-drop',
  'remove-image',
  'select-mode',
])

const { t } = useI18n()

// Content length options
const contentLengthOptions = computed(() => [
  { value: CONTENT_LENGTH.short, label: t('entity.components.newContent.components.newContentModal.contentLength.short') },
  { value: CONTENT_LENGTH.medium, label: t('entity.components.newContent.components.newContentModal.contentLength.medium') },
  { value: CONTENT_LENGTH.long, label: t('entity.components.newContent.components.newContentModal.contentLength.long') },
])

const updateFormField = (field, value) => {
  const updatedFormData = { ...props.formData }
  updatedFormData[field] = value
  emit('update:form-data', updatedFormData)
}
</script>