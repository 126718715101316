<template>
  <div class="flex items-center justify-between my-4 px-2" v-if="totalPages > 1">
    <div class="flex items-center space-x-1">
      <button
        @click="goToPage(1)"
        :disabled="currentPage === 1"
        class="pagination-button"
        :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
        aria-label="İlk sayfa"
      >
        <span class="material-symbols-outlined">first_page</span>
      </button>
      
      <button
        @click="goToPage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="pagination-button"
        :class="{ 'opacity-50 cursor-not-allowed': currentPage === 1 }"
        aria-label="Önceki sayfa"
      >
        <span class="material-symbols-outlined">chevron_left</span>
      </button>

      <div class="flex items-center space-x-1">
        <template v-for="page in visiblePages" :key="page">
          <button
            v-if="page === '...'"
            class="pagination-button opacity-50 cursor-default"
            disabled
          >
            ...
          </button>
          <button
            v-else
            @click="goToPage(page)"
            class="pagination-button"
            :class="{
              'bg-primary text-white hover:bg-primary-dark': page === currentPage,
              'hover:bg-gray-100': page !== currentPage
            }"
          >
            {{ page }}
          </button>
        </template>
      </div>

      <button
        @click="goToPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="pagination-button"
        :class="{ 'opacity-50 cursor-not-allowed': currentPage === totalPages }"
        aria-label="Sonraki sayfa"
      >
        <span class="material-symbols-outlined">chevron_right</span>
      </button>
      
      <button
        @click="goToPage(totalPages)"
        :disabled="currentPage === totalPages"
        class="pagination-button"
        :class="{ 'opacity-50 cursor-not-allowed': currentPage === totalPages }"
        aria-label="Son sayfa"
      >
        <span class="material-symbols-outlined">last_page</span>
      </button>
    </div>

    <div class="text-sm text-gray-600">
      <span>{{ startItem }}-{{ endItem }} / {{ totalItems }} öğe</span>
      <select
        v-model="localPageSize"
        @change="updatePageSize"
        class="ml-2 border border-gray-300 rounded-md p-1 text-sm"
      >
        <option v-for="size in pageSizeOptions" :key="size" :value="size">
          {{ size }} / sayfa
        </option>
      </select>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
  totalItems: {
    type: Number,
    required: true,
  },
  pageSizeOptions: {
    type: Array,
    default: () => [10, 25, 50, 100],
  },
});

const emit = defineEmits(['update:pagination']);

const localPageSize = ref(props.pageSize);

const totalPages = computed(() => Math.max(1, Math.ceil(props.totalItems / props.pageSize)));

const startItem = computed(() => {
  if (props.totalItems === 0) return 0;
  return (props.currentPage - 1) * props.pageSize + 1;
});

const endItem = computed(() => {
  return Math.min(props.currentPage * props.pageSize, props.totalItems);
});

// Görünür sayfa numaralarını hesapla (sayfa sayısı çok fazlaysa ellipsis kullan)
const visiblePages = computed(() => {
  if (totalPages.value <= 7) {
    // 7 veya daha az sayfa varsa hepsini göster
    return Array.from({ length: totalPages.value }, (_, i) => i + 1);
  }

  // Ellipsis (...) kullanarak gösterilecek sayfaları belirle
  const pages = [];
  
  // Her zaman ilk sayfayı göster
  pages.push(1);
  
  // Mevcut sayfa 4'ten büyükse ellipsis ekle
  if (props.currentPage > 4) {
    pages.push('...');
  }
  
  // Mevcut sayfanın 2 altı ve 2 üstünde olan sayfaları göster (mümkünse)
  const start = Math.max(2, props.currentPage - 2);
  const end = Math.min(totalPages.value - 1, props.currentPage + 2);
  
  for (let i = start; i <= end; i++) {
    pages.push(i);
  }
  
  // Son sayfa ile mevcut aralık arasında boşluk varsa ellipsis ekle
  if (props.currentPage < totalPages.value - 3) {
    pages.push('...');
  }
  
  // Her zaman son sayfayı göster
  if (totalPages.value > 1) {
    pages.push(totalPages.value);
  }
  
  return pages;
});

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    emit('update:pagination', {
      currentPage: page,
      pageSize: props.pageSize,
      total: props.totalItems,
    });
  }
};

const updatePageSize = () => {
  // Sayfa boyutu değiştiğinde sayfa numarasını sıfırla
  emit('update:pagination', {
    currentPage: 1,
    pageSize: localPageSize.value,
    total: props.totalItems,
  });
};

// Props değiştiğinde lokal değeri güncelle
watch(() => props.pageSize, (newVal) => {
  localPageSize.value = newVal;
});
</script>

<style scoped>
.pagination-button {
  @apply w-8 h-8 flex items-center justify-center rounded-md;
  @apply text-sm font-medium text-gray-700;
  @apply transition-colors duration-200;
}
</style>
