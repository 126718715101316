<template>
  <VModal v-if="modelValue" v-model="modalVisible" @update:modelValue="closeModal" :click-to-close="!isLoading" :esc-to-close="!isLoading" @opened="handleModalOpened" @closed="handleModalClosed" size="full" :id="'category-modal'" :z-index="9999" :hideHeader="false">

    <template #icon>
      <span class="material-symbols-outlined text-primary text-2xl">category</span>
    </template>

    <template #header-content>
      <h2 class="text-xl font-bold text-gray-800">
        {{ t(`settings.components.configuration.components.categoryModal.${currentView === 'list' ? 'list.title' : 'creator.title'}`) }} </h2>
    </template>

    <div class="h-full flex flex-col">
      <!-- Main Content -->
      <div class="flex-grow flex flex-col overflow-hidden">
        <!-- List View -->
        <CategoryList v-if="currentView === 'list'" :categories="categories" :sub-categories="subCategories" :is-loading="isLoading" :expanded-categories="expandedCategories" :loading-sub-categories="loadingSubCategories" @toggle-category="handleToggleCategory" @category-deleted="handleCategoryDeleted" @add-new="currentView = 'create'" @open-prompt-manager="openPromptModal"/>

        <!-- Create View -->
        <CategoryCreator v-else :is-submitting="isSubmitting" @save="handleSaveCategories" @cancel="currentView = 'list'"/>
      </div>
    </div>
  </VModal>

  <!-- Prompt Manager Modal -->
  <PromptManagerModal v-model="showPromptModal" @close="handlePromptModalClose"/>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { useAssetStore } from '@/stores/asset'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import VModal from '@/components/modals/VModal.vue'
import CategoryList from './categoryList.vue'
import CategoryCreator from './categoryCreator.vue'
import PromptManagerModal from './promptManagement/PromptManagerModal.vue'

const { t } = useI18n()
const assetStore = useAssetStore()

// Props & Emits
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue', 'update:show'])

// States
const modalVisible = ref(false)
const currentView = ref('list')
const isLoading = ref(false)
const isSubmitting = ref(false)
const loadingSubCategories = ref(null)
const showPromptModal = ref(false)

const categories = ref([])
const subCategories = ref({})
const expandedCategories = ref([])

// Ana kategori verilerini getir
const fetchCategories = async () => {
  isLoading.value = true
  try {
    const response = await axios.get(settings.keywordCategories, {
      params: {
        asset_id: assetStore.assetId,
        level: 1,
        is_active: 1,
        isCustom: true,
      },
    })

    if (response?.data?.data?.detail) {
      categories.value = response.data.data.detail
    }
  } catch (error) {
    responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.fetchCategories'))
    categories.value = []
  } finally {
    isLoading.value = false
  }
}

// Alt kategorileri getir
const fetchSubCategories = async ({ parentId }) => {
  if (subCategories.value[parentId]) return

  loadingSubCategories.value = parentId
  try {
    const response = await axios.get(settings.subcategories, {
      params: {
        parentId,
        asset_id: assetStore.assetId,
        isCustom: true,
      },
    })

    if (response?.data?.data?.detail) {
      subCategories.value[parentId] = response.data.data.detail
    }
  } catch (error) {
    responseHandlerService.handleError(t('settings.components.configuration.components.categoryModal.common.error.fetchSubcategories'))
    subCategories.value[parentId] = []
  } finally {
    loadingSubCategories.value = null
  }
}

// Kategori genişlet/daralt
const handleToggleCategory = async ({ id, isLevel1 }) => {
  if (isLevel1) {
    const otherLevel1Expanded = expandedCategories.value.filter(categoryId => {
      const category = categories.value.find(c => c.id === categoryId)
      return category?.level === 1 && categoryId !== id
    })

    otherLevel1Expanded.forEach(categoryId => {
      delete subCategories.value[categoryId]
    })

    expandedCategories.value = expandedCategories.value.filter(categoryId => {
      const category = categories.value.find(c => c.id === categoryId)
      return category?.level !== 1 || categoryId === id
    })
  }

  const index = expandedCategories.value.indexOf(id)
  if (index === -1) {
    expandedCategories.value.push(id)
    await fetchSubCategories({ parentId: id })
  } else {
    expandedCategories.value.splice(index, 1)
    delete subCategories.value[id]
  }
}

// Kategori silindi
const handleCategoryDeleted = async (categoryInfo) => {
  delete subCategories.value[categoryInfo.id]

  if (categoryInfo.parentId) {
    delete subCategories.value[categoryInfo.parentId]

    if (expandedCategories.value.includes(categoryInfo.parentId)) {
      await fetchSubCategories({
        parentId: categoryInfo.parentId,
      })
    }
  }

  expandedCategories.value = expandedCategories.value.filter(
      id => id !== categoryInfo.id,
  )

  await fetchCategories()
}

// Yeni kategorileri kaydet
const handleSaveCategories = async (data) => {
  isSubmitting.value = true
  try {
    const payload = {
      assetId: assetStore.assetId,
      categories: data.categories,
    }

    await axios.post(settings.customCategories, payload)
    responseHandlerService.handleSuccess(t('settings.components.configuration.components.categoryModal.common.success.created'))

    currentView.value = 'list'
    await fetchCategories()

    // Yeni kategoriler oluşturuldu, prompt modalı göster
    openPromptModal()
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isSubmitting.value = false
  }
}

// Prompt Modalını Aç
const openPromptModal = async () => {
  // Modalı aç
  showPromptModal.value = true

  // DOM güncellemesini bekle
  await nextTick()
}

// Prompt modalı kapandığında
const handlePromptModalClose = () => {
  // Yerel durumu güncelle
  showPromptModal.value = false
}

const closeModal = () => {
  emit('update:modelValue', false)
  emit('update:show', false)
}

// Lifecycle metodları
const handleModalOpened = () => {
  fetchCategories()
}

const handleModalClosed = () => {
  currentView.value = 'list'
  categories.value = []
  subCategories.value = {}
  expandedCategories.value = []

  // Prompt modalı kapat
  showPromptModal.value = false
}

// Watchers
watch(() => assetStore.assetId, () => {
  fetchCategories()
})

// modelValue ile modalVisible senkronizasyonu
watch(() => props.modelValue, (newValue) => {
  modalVisible.value = newValue
}, { immediate: true })
</script>