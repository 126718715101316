import { ref } from 'vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { dateHelper } from '@/utils/dateHelper'

// Trend keyword types
const TREND_KEYWORD_TYPES = {
  COMPANY_RELATED: 1,
  UNRELATED: 2,
}

/**
 * Format timestamp to relative time display
 * @param {number} timestamp - Unix timestamp
 * @returns {string} - Formatted time display
 */
export const formatTimeDisplay = (timestamp) => {
  if (!timestamp) return '-'

  const start = dateHelper.parse(timestamp * 1000)
  const now = dateHelper.now()
  const diffInHours = dateHelper.diff(now, start, 'hour')

  if (diffInHours < 1) {
    const diffInMinutes = dateHelper.diff(now, start, 'minute')
    return `${diffInMinutes} dakika önce`
  }
  if (diffInHours < 24) {
    return `${diffInHours} saat önce`
  }

  const diffInDays = Math.floor(diffInHours / 24)
  return `${diffInDays} gün önce`
}

/**
 * Format volume number to display format
 * @param {number} volume - Volume number
 * @returns {string} - Formatted volume display
 */
export const formatVolumeDisplay = (volume) => {
  if (!volume) return '-'
  if (volume >= 1000000) return `${(volume / 1000000).toFixed(1)}M+`
  if (volume >= 1000) return `${(volume / 1000).toFixed(1)}K+`
  if (volume >= 500) return `500+`
  return `${volume}+`
}

/**
 * Create trend filter items
 * @param {Function} t - Translation function
 * @returns {Array} - Array of filter items
 */
export const getTrendFilterItems = (t) => [
  { id: '', name: t('socialMedia.components.createPost.trends.filter.all') },
  {
    id: TREND_KEYWORD_TYPES.COMPANY_RELATED.toString(),
    name: t('socialMedia.components.createPost.trends.filter.related'),
  },
]

export function useTrends() {
  const isLoadingTrends = ref(false)
  const trendsList = ref([])
  const selectedTrend = ref(null)
  const selectedTrendType = ref('')

  /**
   * Fetch trends based on selected type
   * @param {string} assetId - Asset ID
   * @param {string} errorMessage - Error message to display if fetch fails
   */
  const fetchTrends = async (assetId, errorMessage = 'An error occurred. Please try again.') => {
    isLoadingTrends.value = true

    try {
      const response = await axios.get(dashboard.getTrendKeywords, {
        params: {
          assetId: assetId,
          trendKeywordType: selectedTrendType.value
              ? parseInt(selectedTrendType.value)
              : TREND_KEYWORD_TYPES.UNRELATED,
        },
      })

      trendsList.value = response.data.data || []
    } catch (error) {
      console.error('Error fetching trend keywords:', error)
      responseHandlerService.handleError(errorMessage)
      trendsList.value = []
    } finally {
      isLoadingTrends.value = false
    }
  }

  /**
   * Set selected trend
   * @param {Object} trend - Trend object
   */
  const selectTrend = (trend) => {
    selectedTrend.value = trend
  }

  return {
    isLoadingTrends,
    trendsList,
    selectedTrend,
    selectedTrendType,
    TREND_KEYWORD_TYPES,

    fetchTrends,
    selectTrend,
    getTrendFilterItems,
    formatTimeDisplay,
    formatVolumeDisplay,
  }
}