<template>
  <div class="px-5 py-3 flex items-center justify-between bg-white border-b border-gray-200">
    <div class="flex flex-col">
      <h2 class="font-semibold text-gray-800 text-sm">{{ platformName }}</h2>
      <p class="text-xs text-gray-500">{{ formattedDate }}</p>
    </div>

    <!-- Taslak/Yayınlandı Etiketi -->
    <span :class="[
      'px-2 py-1 text-xs font-medium rounded-full flex items-center gap-1',
      isPublished ? 'bg-green-100 text-green-800' : 'bg-amber-100 text-amber-800',
    ]">
      <span class="w-1.5 h-1.5 rounded-full" :class="isPublished ? 'bg-green-500' : 'bg-amber-500'"></span>
      {{ isPublished ? t('socialMedia.components.postInspector.published') : t('socialMedia.components.postInspector.draft') }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/tr'

dayjs.extend(relativeTime)

const { t } = useI18n()

const props = defineProps({
  platformName: {
    type: String,
    default: ''
  },
  date: {
    type: [String, Date],
    default: null
  },
  isPublished: {
    type: Boolean,
    default: false
  }
})

const formattedDate = computed(() => {
  if (!props.date) return ''
  const locale = document.documentElement.lang || 'tr'
  return dayjs(props.date).locale(locale).fromNow()
})
</script>