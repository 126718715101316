/**
 * Keywordleri öncelikle priority sonra isme göre sıralar
 * @param {Array} keywords - Sıralanacak keyword dizisi
 * @returns {Array} Sıralanmış keyword dizisi
 */
export function sortKeywordsByPriorityAndName(keywords) {
  return [...keywords].sort((a, b) => {
    // Öncelik farklıysa, önceliğe göre sırala
    if (a.priority !== b.priority) {
      return (b.priority || 0) - (a.priority || 0)
    }

    // Öncelik aynıysa, isme göre sırala
    const nameA = a.name || ''
    const nameB = b.name || ''
    return nameA.localeCompare(nameB)
  })
}